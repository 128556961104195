import { useCallback, useContext, RefObject, useMemo, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { isEmptyField } from 'builder/modules/resumeEditor/resumeScoreRules/sectionRule'
import { isEmptySection } from 'builder/modules/resumeEditor/aiProfileRules'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  SectionNames,
  selectors,
  actions,
  AI_PROFILE_SUMMARY_ANALYTICS_LABEL,
} from 'builder/modules/resumeEditor'
import { useI18n } from 'builder/hooks/useI18n'
import { selectors as userSelectors } from 'builder/modules/user'
import { AIProfileGenerateType } from 'builder/modules/constants'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { RichTextAreaContext } from 'builder/context/RichTextAreaContext'
import { useRemainingAiProfileAttempts } from 'builder/hooks/useRemainingAiProfileAttempts'
import { suggestionScopeToSectionName } from 'builder/components/RichTextArea'
import { useClickOutsideExcept } from 'builder/hooks/useClickOutsideExcept'
import { AiKeywordsTogglerForPopups } from 'builder/components/AiFeatures/Keywords'
import { AddPreWrittenPhrasesButton, Divider } from '../styles'
import { Props } from '../types'
import { DIALOG_ANALYTICS_LABEL } from '../const'

const useAiProfileSummary = (props: Props, container: RefObject<HTMLElement>) => {
  const {
    suggestionConfig: { scope, mode, keywordsConfig },
    phrasesOpenerRef,
    editorContainerRef,
  } = props
  const { toggleAiProfileSummary } = useContext(RichTextAreaContext)
  const resume = useTypedSelector(selectors.resume)
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
  const remainingAIGenerationAttempts = useRemainingAiProfileAttempts()
  const isHelpWithWritingOpen = useTypedSelector(selectors.isHelpWithWritingOpen)
  const isWorkExperienceEmpty =
    resume && isEmptySection(SectionNames.workExperiences, resume[SectionNames.workExperiences])

  const isProfileEmpty = resume && isEmptyField(SectionNames.profile, resume[SectionNames.profile])

  const infoText = useMemo(() => {
    if (isWorkExperienceEmpty) {
      return i18n.t('builder.ai_profile_summary.desc.no_work_experience')
    } else {
      if (!isWorkExperienceEmpty && isProfileEmpty) {
        return i18n.t('builder.ai_profile_summary.desc.no_profile_summary')
      } else if (!isProfileEmpty) {
        return i18n.t('builder.ai_profile_summary.desc.profile_summary_present')
      }
    }
  }, [i18n, isProfileEmpty, isWorkExperienceEmpty])

  const isImproveActive = useMemo(() => {
    if (isWorkExperienceEmpty) {
      return false
    } else {
      if (!isWorkExperienceEmpty && isProfileEmpty) {
        return false
      } else if (!isProfileEmpty) {
        return true
      }
    }
  }, [isProfileEmpty, isWorkExperienceEmpty])

  const badgeText = useMemo(() => {
    if (remainingAIGenerationAttempts === Infinity) {
      return i18n.t('builder.ai_profile_summary.assistance_modal.unlimited')
    } else {
      return `${remainingAIGenerationAttempts} ${i18n.t(
        'builder.ai_profile_summary.assistance_modal.left',
      )}`
    }
  }, [i18n, remainingAIGenerationAttempts])

  const badgeTooltipText = useMemo(() => {
    if (isPremiumUser) {
      return i18n.t('builder.ai_profile_summary.tooltip.premium_user')
    } else {
      if (remainingAIGenerationAttempts === 0) {
        return i18n.t('builder.ai_profile_summary.tooltip.no_attempts_left')
      } else {
        return i18n.t('builder.ai_profile_summary.tooltip.free_user', {
          attempts_left: remainingAIGenerationAttempts,
        })
      }
    }
  }, [i18n, isPremiumUser, remainingAIGenerationAttempts])

  useClickOutsideExcept(container, [phrasesOpenerRef, editorContainerRef], () => {
    if (isHelpWithWritingOpen) {
      toggleAiProfileSummary({ isOpen: false })
    }
  })

  useEscapeHandler(() => dispatch(actions.setIsHelpWithWritingOpen({ status: false })))

  const handleGenerate = useCallback(() => {
    trackInternalEvent('click_generate', {
      section: suggestionScopeToSectionName(scope),
      label: DIALOG_ANALYTICS_LABEL,
      profile_empty: isProfileEmpty,
      experience_empty: isWorkExperienceEmpty,
    })

    toggleAiProfileSummary({ isOpen: false })

    if (!remainingAIGenerationAttempts) {
      dispatch(actions.toggleAIProfileSummaryPaywall())
      return
    }

    if (isWorkExperienceEmpty) {
      dispatch(
        actions.highlightEmptyWorkExperience({
          title: i18n.t('builder.ai_profile_summary.work_experience.title'),
          content: i18n.t('builder.ai_profile_summary.work_experience.description'),
          buttonLabel: i18n.t('builder.ai_profile_summary.work_experience.button_text'),
        }),
      )
      return
    }

    dispatch(actions.setAIProfileActionType(AIProfileGenerateType.generate))
    dispatch(actions.generateResumeAiSummary())

    trackInternalEvent('start_generating', {
      section: suggestionScopeToSectionName(scope),
      label: AI_PROFILE_SUMMARY_ANALYTICS_LABEL,
      profile_empty: isProfileEmpty,
      mode: AIProfileGenerateType.generate,
    })
  }, [
    dispatch,
    remainingAIGenerationAttempts,
    toggleAiProfileSummary,
    i18n,
    isWorkExperienceEmpty,
    scope,
    isProfileEmpty,
  ])

  const handleImprove = useCallback(() => {
    if (remainingAIGenerationAttempts > 0) {
      toggleAiProfileSummary({ isOpen: false })
      dispatch(actions.setAIProfileActionType(AIProfileGenerateType.improve))
      dispatch(actions.generateResumeAiSummary())

      trackInternalEvent('start_generating', {
        section: suggestionScopeToSectionName(scope),
        label: AI_PROFILE_SUMMARY_ANALYTICS_LABEL,
        profile_empty: isProfileEmpty,
        mode: AIProfileGenerateType.improve,
      })
    } else {
      dispatch(actions.toggleAIProfileSummaryPaywall())
    }

    trackInternalEvent('click_improve', {
      section: suggestionScopeToSectionName(scope),
      label: DIALOG_ANALYTICS_LABEL,
      profile_empty: isProfileEmpty,
      experience_empty: isWorkExperienceEmpty,
    })
  }, [
    dispatch,
    remainingAIGenerationAttempts,
    toggleAiProfileSummary,
    isProfileEmpty,
    scope,
    isWorkExperienceEmpty,
  ])

  const renderPrewrittenAction = () => {
    if (mode === 'phrases') {
      return (
        <RichTextAreaContext.Consumer>
          {({ toggleSuggestions }) => (
            <Fragment>
              <AddPreWrittenPhrasesButton
                onClick={() => {
                  toggleSuggestions({ trigger: 'click' })
                  trackInternalEvent('click_add_phrases', {
                    section: suggestionScopeToSectionName(scope),
                    label: DIALOG_ANALYTICS_LABEL,
                    profile_empty: isProfileEmpty,
                    experience_empty: isWorkExperienceEmpty,
                  })
                }}
              >
                {i18n.t('builder.ai_profile_summary.option.add_prewritten_phrases')}
              </AddPreWrittenPhrasesButton>
            </Fragment>
          )}
        </RichTextAreaContext.Consumer>
      )
    }
    return null
  }

  const renderKeywordsAction = () => {
    if (!keywordsConfig) return null

    const { section, buttonText } = keywordsConfig

    return (
      <>
        <Divider />

        <AiKeywordsTogglerForPopups
          section={section}
          text={buttonText}
          onClick={() => toggleAiProfileSummary({ trigger: 'click' })}
        />
      </>
    )
  }

  return {
    badgeText,
    badgeTooltipText,
    infoText,
    isImproveActive,
    useClickOutside,
    handleGenerate,
    handleImprove,
    renderPrewrittenAction,
    isProfileEmpty,
    isWorkExperienceEmpty,
    remainingAIGenerationAttempts,
    renderKeywordsAction,
  }
}

export default useAiProfileSummary
