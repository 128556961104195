import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'

import { useI18n } from 'builder/hooks/useI18n'
import { actions, selectors, ColumnName } from 'builder/modules/jobTracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { AutoApplyStatusLabel } from 'builder/components/JobTracking/AutoApplyStatusLabel'
import Icon24 from 'builder/components/Icon'
import { useFuzzyJobTitles } from 'builder/components/JobTracking/InterviewMock/hooks/useFuzzyJobTitles'
import { useQuestionTemplate } from 'builder/views/Interview/hooks/useQuestionTemplate'
import { QuestionTemplate } from 'builder/modules/interview/types'
import { useVisibilityObserver } from 'builder/hooks/useVisibilityObserver'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import { Logo } from '../JobItem/styles'
import { Job } from '../JobSearchResult/types'

import * as Meta from '../JobItem/Meta'
import { Accordion } from '../Accordion/Accordion'
import { useJobSearch } from '../useJobSearch'
import ActionButtons from './ActionButton/ActionButtons'

import {
  Container,
  Header,
  Company,
  Position,
  Metas,
  MetasAutoApply,
  HeaderContainer,
  Title,
  AccordionTitleContainer,
  AccordionTitleButton,
  BackButton,
  LeftArrowIcon,
  ActionButtonBottomWrapper,
} from './styles'
import { JobSummary } from './JobSummary/JobSummary'
import { RequiredSkillsSection } from './RequiredSkillsSection/RequiredSkills'

type JobDetailsProps = {
  job: Job
  setApplyDialog: (showDialog: boolean) => void
  goBack: () => void
}

const JobDetails: React.FC<JobDetailsProps> = ({ job, setApplyDialog, goBack }) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const actionButtonHeaderRef = useRef<HTMLDivElement>(null)
  const { isUserOnAutoApplyPlan } = useAutoApply()
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  const { selectedTabId } = useJobSearch()
  const isInViewActionButtonHeader = useVisibilityObserver<HTMLDivElement | null>(
    actionButtonHeaderRef,
  )

  // load job tracking jobs
  const jobTrackerStatus = useTypedSelector(selectors.selectFetchStatus)
  useEffect(() => {
    if (jobTrackerStatus === FetchStatuses.notAsked) dispatch(actions.fetchJobsRequest())
  }, [dispatch, jobTrackerStatus])

  const cards = useTypedSelector(selectors.selectJobs)
  const jobTrackingCard = cards.find(
    card =>
      card.company === job.company &&
      card.title === job.job_title &&
      card.externalSlugId === job.external_slug_id &&
      card.status !== ColumnName.recommended,
  )
  const isAutoAppliedJob =
    jobTrackingCard?.status === ColumnName.auto_apply || jobTrackingCard?.autoApplyStatus

  const { data: matchedJobTitles } = useFuzzyJobTitles(job.job_title, 1)
  const jobTitleId = matchedJobTitles?.length && matchedJobTitles[0].id
  const { data: questions } = useQuestionTemplate({
    jobTitleId: jobTitleId,
    limit: 3,
    refetchIntervalPredicate: (data?: QuestionTemplate[]) => !!(data && data.length >= 3),
  })

  const accordionQuestions = useMemo(() => {
    if (Array.isArray(questions) && questions.length >= 3) {
      return questions.slice(0, 3).map(question => ({ title: question.content }))
    }
    return []
  }, [questions])

  useEffect(() => {
    const externalSlugId = job?.external_slug_id as string
    dispatch(actions.fetchAlreadyAppliedJobRequest(externalSlugId))
  }, [dispatch, job])

  return (
    <Container>
      <BackButton onClick={goBack}>
        <LeftArrowIcon />
        {i18n.t(`builder.job_search.job_search_results.back`)}
      </BackButton>
      <HeaderContainer>
        <Logo src={job.company_logo} />
        <Header>
          <Company>{job.company}</Company>
          <Position>{job.job_title}</Position>
        </Header>
      </HeaderContainer>
      <Metas>
        <Meta.Location job={job} />
        <Meta.Salary job={job} />
        <Meta.Remote job={job} />
        <Meta.CreatedAt job={job} />
      </Metas>
      {isUserOnAutoApplyPlan && isAutoAppliedJob && !autoApplyBubbles.running && (
        <MetasAutoApply>
          <Meta.AutoApplyStatus />
          {jobTrackingCard.autoApplyStatus && (
            <AutoApplyStatusLabel autoApplyStatus={jobTrackingCard.autoApplyStatus} />
          )}
        </MetasAutoApply>
      )}
      <ActionButtons
        job={job}
        mobileVertical
        setApplyDialog={setApplyDialog}
        ref={actionButtonHeaderRef}
      />

      <RequiredSkillsSection
        title={<Title>{i18n.t('builder.job_search.job_search_results.required_skills')}</Title>}
      />

      <JobSummary
        title={<Title>{i18n.t('builder.job_search.job_search_results.summary')}</Title>}
        job={job}
      />

      {accordionQuestions?.length > 0 && (
        <AccordionTitleContainer>
          <Title>{i18n.t('builder.job_search.job_search_results.accordion_title')}</Title>
          <AccordionTitleButton
            to={`/interview-preparation/questions-library?jobTitleId=${jobTitleId}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackInternalEvent('click_explore_interview_questions', {
                label: 'job_search',
                parameter: selectedTabId,
              })
            }}
          >
            {i18n.t('builder.job_search.job_search_results.accordion_title_button')}
            <Icon24.ChevronRight />
          </AccordionTitleButton>
        </AccordionTitleContainer>
      )}

      <Accordion items={accordionQuestions} />
      {!isInViewActionButtonHeader && (
        <ActionButtonBottomWrapper>
          <ActionButtons job={job} setApplyDialog={setApplyDialog} />
        </ActionButtonBottomWrapper>
      )}
    </Container>
  )
}

export default JobDetails
