import { queryClient } from 'builder/components/Providers/Providers'
import { Interview } from 'builder/modules/interview/types'

export const delay = (timer: number) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(null)
    }, timer)
  })

export class AudioPlayerUtils {
  bufferSource: AudioBufferSourceNode
  startedAudio = false
  stopSound() {
    if (this.startedAudio) {
      this.bufferSource.stop()
      this.startedAudio = false
    }
  }

  private async playAudioFromAudioBuffer(audioCtx: AudioContext, audioData: AudioBuffer) {
    return new Promise(resolve => {
      this.bufferSource = audioCtx.createBufferSource()
      this.bufferSource.buffer = audioData
      const gainNode = audioCtx.createGain()
      this.bufferSource.connect(gainNode)
      gainNode.connect(audioCtx.destination)
      this.bufferSource.start()
      this.startedAudio = true
      this.bufferSource.onended = resolve
    })
  }

  async playAudioFromBlob(blob: Blob) {
    if (blob.type !== 'audio/mpeg') {
      return
    }
    const audioCtx = new AudioContext()
    return new Promise<void>(resolve => {
      const reader = new FileReader()

      reader.readAsArrayBuffer(blob)
      reader.onload = async event => {
        const arrayBuffer = event.target?.result
        if (arrayBuffer instanceof ArrayBuffer) {
          const audioData = await audioCtx.decodeAudioData(arrayBuffer)
          await this.playAudioFromAudioBuffer(audioCtx, audioData)
        }
        resolve()
      }
    })
  }
}

export function getNumberOfQuestionOfInterview(interview: Interview) {
  switch (interview.kind) {
    case 'custom':
      return 8
    case 'one_question':
    case 'test_interview':
      return 1
    default:
      throw new Error(
        `Interview must have kind. interviewId:${interview.id} kind:${interview.kind}`,
      )
  }
}

export const invalidateCacheForInterviews = () => {
  return Promise.all([
    queryClient.invalidateQueries(['useInterviews']),
    queryClient.invalidateQueries(['useInterview']),
    queryClient.invalidateQueries(['useDailySuggestions']),
    queryClient.invalidateQueries(['useDownloadS3Asset']),
  ])
}

export function getMediaRecorder(stream: MediaStream, media: 'audio' | 'video'): MediaRecorder {
  const supportedMimeTypeForVideo = ['video/webm', 'video/mp4']
  const supportedMimeTypeForAudio = ['video/webm', 'audio/mp4']
  const supportedMineType =
    media === 'video' ? supportedMimeTypeForVideo : supportedMimeTypeForAudio
  const recorder = new MediaRecorder(stream, {
    mimeType: supportedMineType.find(MediaRecorder.isTypeSupported),
  })

  return recorder
}

export function getMimeType(stream: MediaStream, media: 'audio' | 'video'): Promise<string> {
  return new Promise(resolve => {
    const mediaRecorder = getMediaRecorder(stream, media)
    mediaRecorder.ondataavailable = event => resolve(event.data.type)
    mediaRecorder.start()
    setTimeout(() => mediaRecorder.stop(), 200)
  })
}
