import { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { TunerEditorProvider } from 'builder/components/Tuner'
import { connect } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { SectionNames, actions } from 'builder/modules/resumeEditor'
import PersonalDetailsSection from 'builder/components/PersonalDetailsSection'
import SectionsList from 'builder/components/SectionsList'
import EditorTitle from 'builder/components/EditorTitle'
import LanguageSelect from 'builder/components/LanguageSelect'
import ResumeScore from 'builder/components/ResumeScore'
import Anchor from 'builder/components/Anchor'
import loaderImage from 'images/builder/resume-editor/editor-loader.svg'
import { withConfig } from 'builder/components/Config'
import queryString from 'query-string'
import {
  EditorPanel,
  EditorScroll,
  EditorContent,
  EditorSubtitle,
  LoaderContainer,
  Loader,
  UserNavigation,
  JobSearch,
  JobSearchContainer,
  VerticalDivider,
} from './styles'
import {
  FetchStatuses,
  ResumeUploadStatus,
  ResumeValidationStatus,
} from 'builder/modules/constants'
import FillResumeModal from '../FillResumeModal'
import { ATS_TEMPLATES } from '../Helper/constants'
import CreateResumeWithAI from 'builder/components/FillResumeModal/CreateResumeWithAI'
import ATSPersonalDetailsSection from './ATSResumeEditor/PersonalDetailsSection'
import UseExample from '../FillResumeModal/UseExample'
import { withI18n } from 'builder/hocs/withI18n'
import JobSearchButton from '../FloatingCIOHelperButton/JobSearchButton/JobSearchButton'
import { ResumeSideMenu } from './ResumeSideMenu/ResumeSideMenu'
import { ElementHighlight } from './ElementHighlight'
import TailorResumeBanner from '../ImproveResumePanel/components/TailorResumeBanner/TailorResumeBanner'
import { BuilderType } from './ResumeSideMenu/types'

class ResumeEditor extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    resumeState: PropTypes.object.isRequired,
    updateSimpleField: PropTypes.func.isRequired,
    updateCard: PropTypes.func.isRequired,
    addCard: PropTypes.func.isRequired,
    addCustomSection: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    moveCard: PropTypes.func.isRequired,
    moveSection: PropTypes.func.isRequired,
    renameSection: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
    isOnline: PropTypes.bool,
    isResumeOptimizerBannerOpen: PropTypes.bool,
    isOptimizerModalOpen: PropTypes.bool,
    isLinkedToJobPosting: PropTypes.bool,
    isJobPostingDataFetched: PropTypes.bool,
    config: PropTypes.object.isRequired,
    showPrefillResumeModalProp: PropTypes.bool,
    setResumeValidationStatus: PropTypes.func,
    setResumeUploadStatus: PropTypes.func,
    aiResume: PropTypes.object,
    resetAIResumeData: PropTypes.func.isRequired,
    useExample: PropTypes.object,
    i18n: PropTypes.object,
    isPremiumUser: PropTypes.bool,
  }

  componentDidMount() {
    const { isLinkedToJobPosting, isResumeOptimizerBannerOpen, resetAIResumeData } = this.props
    const urlParams = queryString.parse(window.location.search)
    const preserveAIResumeDraft = urlParams && urlParams.showAIResumeDraft
    if (!preserveAIResumeDraft) resetAIResumeData()
    this.showResumeOptimizerBanner = !isLinkedToJobPosting && isResumeOptimizerBannerOpen
  }

  componentDidUpdate() {
    const {
      resumeState: { resume },
      isOptimizerModalOpen,
    } = this.props
    /** If the user has selected Tailor to Job Posting flow from dashboard
     * and if the prefill modal has been set to open, call the handler function
     */
    if (isOptimizerModalOpen && resume && resume.showPrefillResumeModal) {
      this.handlePrefillModalClose()
    }
  }

  handleSimpleFieldUpdate = (name, value, debounce) => {
    this.props.updateSimpleField(name, value, debounce)

    switch (name) {
      case 'name':
        trackInternalEvent('rename_resume')
        break
      case 'color':
        trackInternalEvent('change_resume_color')
        break
      case 'locale':
        trackInternalEvent('change_resume_locale', { locale: value })
        break
      default:
        break
    }
  }

  handleResumeNameChange = value => {
    this.props.updateSimpleField('name', value, true)
  }

  handleLocaleSelect = id => {
    this.props.updateSimpleField('locale', id, true)
  }

  handlePrefillModalClose = () => {
    const { setResumeValidationStatus, setResumeUploadStatus, updateSimpleField } = this.props
    setResumeValidationStatus(ResumeValidationStatus.notStarted)
    setResumeUploadStatus(ResumeUploadStatus.notStarted)
    updateSimpleField('showPrefillResumeModal', false, false)
  }

  render() {
    const {
      updateCard,
      editorState,
      resumeState,
      addCard,
      addCustomSection,
      deleteCard,
      moveCard,
      moveSection,
      renameSection,
      deleteSection,
      isOnline,
      isLinkedToJobPosting,
      isJobPostingDataFetched,
      isResumeOptimizerBannerOpen,
      isOptimizerModalOpen,
      showPrefillResumeModalProp,
      config,
      aiResume,
      useExample,
      i18n: I18n,
      isPremiumUser,
    } = this.props

    const { resume, isImproveResumePanelOpen } = resumeState
    const { resumeTemplates, levels, locales } = editorState

    const isResumeOptimizerFeatureOn = config?.features.resumeOptimizer
    const isPrefillResumeFeatureOn = config?.features.newStartScreen !== 'no_screen'
    const { aiResumeDraft, startWithExamples, uploadResume, superApp, international } =
      config?.features

    const hostname = window.location.hostname

    const isCareerIo = hostname === 'career.io' || hostname === 'staging.career.io'

    const showResumeOptimizerBanner =
      isResumeOptimizerFeatureOn &&
      isJobPostingDataFetched &&
      !isLinkedToJobPosting &&
      isResumeOptimizerBannerOpen

    const showPrefillResumeModal =
      !isOptimizerModalOpen &&
      showPrefillResumeModalProp &&
      (aiResumeDraft || startWithExamples || uploadResume) &&
      isPrefillResumeFeatureOn

    const { showAIResumeModal } = aiResume

    const { showUseExampleModal } = useExample

    if (!resume || resumeTemplates.length === 0) {
      return (
        <EditorPanel>
          <LoaderContainer>
            <Loader src={loaderImage} />
          </LoaderContainer>
        </EditorPanel>
      )
    }

    const isATSTemplate = ATS_TEMPLATES.includes(resume.template)

    return (
      <>
        {(superApp || international) && (
          <ResumeSideMenu isOnline={isOnline} builderType={BuilderType.resumeBuilder} />
        )}
        <JobSearchContainer>
          <JobSearch hideDivider showBubbles id="job-search-solution" />
          <VerticalDivider />
        </JobSearchContainer>
        <EditorPanel data-testid="editor-panel" onClick={this.handleToggleSheet}>
          {!(superApp || international) && isOnline && <UserNavigation hasFeatures />}
          <EditorScroll>
            <EditorContent>
              <EditorTitle
                onChange={this.handleResumeNameChange}
                value={resume.name ? resume.name : I18n.t('builder.resume_editor.untitled')}
              />

              <EditorSubtitle>
                <LanguageSelect
                  selected={resume.locale}
                  onSelect={this.handleLocaleSelect}
                  options={locales}
                />
              </EditorSubtitle>

              <ResumeScore />

              {showResumeOptimizerBanner && !isImproveResumePanelOpen && (
                <TailorResumeBanner isInsidePanel />
              )}

              <TunerEditorProvider resume={resume}>
                <Anchor id={SectionNames.details} scrollOffsetTop={70}>
                  {isATSTemplate ? (
                    <ATSPersonalDetailsSection resume={resume} />
                  ) : (
                    <PersonalDetailsSection resume={resume} />
                  )}
                </Anchor>

                <SectionsList
                  resume={resume}
                  updateCard={updateCard}
                  addCard={addCard}
                  addCustomSection={addCustomSection}
                  deleteCard={deleteCard}
                  moveCard={moveCard}
                  moveSection={moveSection}
                  levels={levels}
                  updateSimpleField={this.handleSimpleFieldUpdate}
                  renameSection={renameSection}
                  deleteSection={deleteSection}
                />
              </TunerEditorProvider>
            </EditorContent>
          </EditorScroll>
          {showPrefillResumeModal && <FillResumeModal onClose={this.handlePrefillModalClose} />}
          {showAIResumeModal && <CreateResumeWithAI />}
          {showUseExampleModal && <UseExample />}
          {isPremiumUser && isCareerIo && <JobSearchButton resumeEditor />}
          <ElementHighlight />
        </EditorPanel>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isOnline: state.application.isOnline,
  isResumeOptimizerBannerOpen: state.resumeEditor.isResumeOptimizerBannerOpen,
  isOptimizerModalOpen: state.resumeEditor.isOptimizerModalOpen,
  isLinkedToJobPosting: !!state.resumeEditor.optimizerSection.jobPostingAPIData.jobPostingId,
  showPrefillResumeModalProp: state.resumeEditor.resume?.showPrefillResumeModal,
  aiResume: state.resumeEditor.aiResume,
  isJobPostingDataFetched:
    state.resumeEditor.optimizerSection.fetchJobPostingDataStatus === FetchStatuses.loaded,
  isResumeOptimizerFeatureOn: state.application.configs.app.features.resumeOptimizer,
  useExample: state.resumeEditor.useExample,
  isPremiumUser: state.user.data?.hasPremiumFeatures ?? false,
})
const mapDispatchToProps = dispatch => {
  return {
    updateCard: (sectionId, cardId, values, debounce, isCustom) =>
      dispatch(actions.updateCard({ sectionId, cardId, values, debounce, isCustom })),
    addCard: (cardId, sectionName, options) =>
      dispatch(actions.addCard({ sectionName, cardId, options })),
    deleteCard: (cardId, sectionName, isCustom = false) =>
      dispatch(actions.deleteCard({ sectionName, cardId, isCustom })),
    addCustomSection: (externalId, options) =>
      dispatch(actions.addCustomSection({ externalId, options })),
    moveCard: payload => dispatch(actions.moveCard(payload)),
    moveSection: payload => dispatch(actions.moveSection(payload)),
    deleteSection: (id, options) => dispatch(actions.deleteSection({ id, options })),
    renameSection: payload => dispatch(actions.renameSection({ ...payload, debounce: true })),
    updateSimpleField: (name, value, debounce) =>
      dispatch(actions.updateSimpleField({ name, value, debounce })),
    setResumeValidationStatus: payload => dispatch(actions.setResumeValidationStatus(payload)),
    setResumeUploadStatus: payload => dispatch(actions.setResumeUploadStatus(payload)),
    resetAIResumeData: () => dispatch(actions.resetAIResumeData()),
  }
}

export default compose(
  withConfig,
  withI18n,
  connect(mapStateToProps, mapDispatchToProps),
)(ResumeEditor)
