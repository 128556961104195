import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions as renderingActions } from 'builder/modules/rendering'
import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { navigate } from 'builder/modules/navigate'
import { useGetCareerProfile } from 'builder/views/AutoApply/hooks/useGetCareerProfile'
import { StartCompleteScreenRoute } from '../constants'

const AUTOAPPLY_3_CREDIT = 3

export const useAutoApply = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)
  const { data: careerProfileData, isLoading: isCareerProfileDataLoading } = useGetCareerProfile()
  const { features } = useFeaturesConfig()

  const isAutoApplyFeatureEnabled = features?.autoApply
  const isUserOnAutoApplyPlan = isAutoApplyFeatureEnabled && !!user?.scopes?.autoApply
  const hasUserCompletedQuestionnaire = careerProfileData?.autoApplyDataCompleted

  const getAutoApplyCredit = useCallback(() => {
    if (typeof user?.scopes?.autoApply === 'object') {
      return user?.scopes?.autoApply
    }
    return { creditCount: 0, creditCountUsed: 0, creditCountMax: 0 }
  }, [user])

  const isAutoApplyLimitReached = useCallback(
    (count = 1) => {
      if (autoApplyBubbles.running) return false
      const { creditCount, creditCountUsed, creditCountMax } = getAutoApplyCredit()
      const usedCredits = creditCountUsed + count

      if (creditCount && usedCredits > creditCount) {
        if (creditCount === AUTOAPPLY_3_CREDIT && creditCountMax === AUTOAPPLY_3_CREDIT) {
          dispatch(renderingActions.showRDUpsellModal())
          return true
        } else {
          dispatch(renderingActions.showRDNotEnoughCreditsModal())
          return true
        }
      }
      return false
    },
    [autoApplyBubbles.running, dispatch, getAutoApplyCredit],
  )

  const redirectToBillingPlans = useCallback(() => {
    if (
      !autoApplyBubbles.running &&
      isAutoApplyFeatureEnabled &&
      !isUserOnAutoApplyPlan &&
      user?.billingInfo.userType === 'free'
    ) {
      goToBillingPlanPage({ referrerPage: 'auto-apply', onSuccessRedirect: window.location.href })
      return true
    }
  }, [autoApplyBubbles, isAutoApplyFeatureEnabled, user, isUserOnAutoApplyPlan])

  const redirectToAutoApplyQuestionnaire = useCallback(() => {
    if (!hasUserCompletedQuestionnaire) {
      navigate(`/auto-apply/${StartCompleteScreenRoute.START_SCREEN}`)
      return true
    }
    return false
  }, [hasUserCompletedQuestionnaire])

  return {
    getAutoApplyCredit,
    isAutoApplyLimitReached,
    redirectToBillingPlans,
    redirectToAutoApplyQuestionnaire,
    isAutoApplyFeatureEnabled,
    isUserOnAutoApplyPlan,
    hasUserCompletedQuestionnaire,
    isCareerProfileDataLoading,
  }
}
