import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, Candidate, selectors } from 'builder/modules/resumeDistribution'
import { FetchStatuses } from 'builder/modules/constants'

import { useGetRecruitersList } from '../useGetRecruitersList'
import { RecruiterList } from './RecruitersList/RecruitersList'
import * as Styles from './styles'
import { Header } from './Header/Header'
import { SnackBar } from './SnackBar/SnackBar'

interface IProps {
  candidate: Candidate
}

export const Widget = ({ candidate }: IProps) => {
  const user = useUser()
  const dispatch = useDispatch()
  const { data, isLoading } = useGetRecruitersList()

  const distributionDates = useTypedSelector(selectors.distributionDates)
  const distributionDatesFetchStatus = useTypedSelector(selectors.distributionDatesFetchStatus)
  const isDistributionDatesLoading = distributionDatesFetchStatus === FetchStatuses.loading
  const distributedRecruiterCount = data?.pagination.totalRecords || 0

  useEffect(() => {
    dispatch(actions.fetchDistributionDates())
  }, [dispatch])

  return (
    <Styles.Container>
      {!isDistributionDatesLoading && (
        <SnackBar distributedRecruiterCount={distributedRecruiterCount} />
      )}

      <Styles.HeaderContainer>
        <Header
          candidate={candidate}
          distributionDates={distributionDates}
          distributedRecruiterCount={distributedRecruiterCount}
          isDistributionDatesLoading={isDistributionDatesLoading}
        />
      </Styles.HeaderContainer>

      <Styles.ListContainer>
        {!user?.hasPremiumFeatures && <Styles.PremiumContainerBlur />}
        <RecruiterList
          candidate={candidate}
          recruiters={data?.data || []}
          isRecruitersLoading={isLoading}
        />
      </Styles.ListContainer>
    </Styles.Container>
  )
}
