import { size } from 'lodash'
import { Store } from 'builder/modules/store'
import { Columns } from './types'
import { columnize } from './utils'

export const selectJobs = (state: Store) => {
  return state.jobTracking.jobs
}

export const selectAutoApplyWidgetJobs = (state: Store) => {
  return state.jobTracking.autoApplyWidget
}

export const savedJobsCount = (state: Store) => {
  const savedJobs = state.jobTracking.jobs.filter(({ status }) => status !== 'recommended')
  return size(savedJobs)
}

export const selectWishlistCount = (state: Store) => {
  const wishlistJobs = state.jobTracking.jobs.filter(({ status }) => status === 'wishlist')
  return size(wishlistJobs)
}

export const selectAppliedMaxLimit = (state: Store) => {
  const appliedJobs = state.jobTracking.jobs.filter(({ status }) => status === 'applied')
  return size(appliedJobs) > 2
}

export const selectAppliedCount = (state: Store) => {
  const appliedJobs = state.jobTracking.jobs.filter(({ status }) => status === 'applied')
  return size(appliedJobs)
}

export const selectColumns = (state: Store): Columns => {
  return columnize(state.jobTracking.jobs)
}

export const selectFetchStatus = (state: Store) => {
  return state.jobTracking.fetchStatus
}

export const selectLastUpdatedCard = (state: Store) => {
  return state.jobTracking.lastUpdatedCard
}

export const selectJobsOrder = (state: Store) => {
  return state.jobTracking.jobs.map(job => ({
    id: job.id,
    position_number: job.positionNumber,
    status: job.status,
  }))
}

export const selectAlreadyAppliedJob = (state: Store) => {
  return state.jobTracking.alreadyApplied
}

export const selectApplyTrackingDialog = (state: Store) => {
  return state.jobTracking.applyTrackingDialog
}

export const selectResumeList = (state: Store) => {
  return state.jobTracking.resumeList
}

export const resumeSelection = (state: Store) => {
  return state.jobTracking.resumeSelection
}

export const selectFetchUpdateResumeStatus = (state: Store) => {
  return state.jobTracking.fetchUpdateResumeStatus
}

export const jtShowBanner = (state: Store) => {
  return state.jobTracking.jtShowBanner
}

export const hasInstalledExtension = (state: Store) => {
  return state.jobTracking.hasInstalledExtension
}

export const canInstallExtension = (state: Store) => {
  return state.jobTracking.canInstallExtension
}
