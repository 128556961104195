import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { useLocation } from 'react-router-dom'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors, actions } from 'builder/modules/panel'
import { selectors as uiSelectors } from 'builder/modules/ui'
import {
  actions as critiqueActions,
  selectors as critiqueSelectors,
  ResumeCritiqueStatuses,
} from 'builder/modules/resumeCritiqueReview'
import {
  selectors as enterpriseModalSelectors,
  actions as enterpriseActions,
} from 'builder/modules/enterpriseModal'
import { selectors as userSelectors, UserBillingStatus, UserType } from 'builder/modules/user'
import { ResumeIDStatuses } from 'builder/modules/constants'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useConfig } from 'builder/hooks/useConfig'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import JobStatusModal from 'builder/components/JobStatusModal'
import JobStatusProgressBar from 'builder/components/JobStatusProgressBar'
import JobStatusConfirmationModal from 'builder/components/JobStatusConfirmationModal'
import JobStatusHiredModal from 'builder/components/JobStatusHiredModal'
import ActionBanner from 'builder/components/CIO-Dasboard-2.0/ActionBanner'
import { BookCoachBanner } from 'builder/components/CIO-Dasboard-2.0/Components/BookCoachBanner/BookCoachBanner'
import CioRioInfoModal from 'builder/components/CioRioInfoModal'
import CreateYourResumeModal from 'builder/components/CreateYourResumeModal'
import PremiumServicesModal from 'builder/components/PremiumServicesModal'
import WelcomeVideoOverlay from 'builder/components/CIO-Dasboard-2.0/Components/WelcomeModal/WelcomeVideoOverlay'
import JobSearchButton from 'builder/components/FloatingCIOHelperButton/JobSearchButton/JobSearchButton'
import PremiumRemindModal from 'builder/components/PremiumRemindModal'
import { calculateDaysDifference } from 'builder/components/Navigation/components/PremiumTracker/utils'
import ExploreAndTrackJobsPaywall from 'builder/components/ExploreAndTrackJobsPaywall'
import { CareerProfileFloatingHelper } from 'builder/components/CareerProfile'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'
import { BannersContainer } from 'builder/features/dashboard/components/BannersContainer'
import { TabsSwitcherMobile } from 'builder/features/navigation/components/TabsSwitcherMobile'
import { TabsSwitcherTablet } from 'builder/features/navigation/components/TabsSwitcherTablet'
import { Stepper } from 'builder/features/dashboard/components/Stepper/Stepper'
import { DashboardWidget } from 'builder/features/dashboard/components/DashboardWidget/DashboardWidget'
import { JobSearchSolution } from 'builder/views/AutoApply/components/JobSearchSolution/JobSearchSolution'
import { CareerGoal } from '../CareerGoal'
import DocumentsSection from '../DocumentsSection'
import { PanelContainer, Container, BannerContainer } from '../styles'
import { PanelProps } from '../types'
import CareerFoundationBanner from '../CareerFoundationBanner'
import CritiqueReviewMainBanner from '../CIOCritiqueReview/CritiqueReviewMainBanner'

export const FeaturesPanel = (props: PanelProps) => {
  const user = useUser()
  const { i18n } = useI18n()
  const config = useConfig()
  const features = useFeaturesConfig()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const [params, setParams] = useState<number | string | null>(null)
  const [critiqueParams, setCritiqueParams] = useState<number | string | null>(null)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('tr_promo_banner')
  const tunerResumeId = useTypedSelector(panelSelectors.tunerResumeId)
  const isEnterpriseUser = useTypedSelector(enterpriseModalSelectors.isEnterpriseUser)
  const isSuperApp = config?.features.superApp
  const isRioApp = config?.features.international
  const isDashboardV3 =
    isSuperApp || isRioApp || (config?.features.dashboardV3 && !config?.features.dashboardV2)
  const isCareerProfileAvailable = config?.features.careerProfile
  const isDataLayerPushed = localStorage.getItem('DATA_LAYER_PUSH')
  const fetchApiTimeout = 3000
  const selectedIndex = useTypedSelector(panelSelectors.activeTabId)
  const isModalOpen = useTypedSelector(uiSelectors.isCreateResumeOpen)
  const premiumServiceModalData = useTypedSelector(uiSelectors.showPremiumServiceModal)
  const welcomeModelopenStatus = useTypedSelector(panelSelectors.isWelcomeModelopen)
  const myStoryVisit = localStorage.getItem('myStoryVisitFlag')
  const isTopResumeUser = useTypedSelector(critiqueSelectors.isTopResumeUser)
  const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)

  useEffect(() => {
    dispatch(enterpriseActions.fetchJobStatus())
  }, [dispatch])

  useEffect(() => {
    if (user?.socialAccount && !(isDataLayerPushed === 'true')) {
      localStorage.setItem('DATA_LAYER_PUSH', 'true')
      trackMarketingEvent('Sign up', 'Complete Registration')
    }
  }, [user, isDataLayerPushed])

  useEffect(() => {
    localStorage.setItem('promoBanner', key == null ? '' : key)
    setParams(key)
  }, [key, location])

  const checksum = localStorage.getItem('IS_CRITIQUE_CHECKSUM')

  useEffect(() => {
    if (critiqueParams === 'critique' && tunerResumeId) {
      dispatch(
        critiqueActions.fetchCioCritiques({
          resumeId: tunerResumeId,
          checkSum: checksum,
        }),
      )
    }
  }, [checksum, critiqueParams, dispatch, tunerResumeId])

  const CritiqueTriggeredStatus = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)

  const isCritiqueFetchRequired =
    tunerResumeId &&
    CritiqueTriggeredStatus?.status === ResumeCritiqueStatuses.pending &&
    myStoryVisit &&
    isTopResumeUser

  useEffect(() => {
    let critiqueInterval: NodeJS.Timeout
    critiqueInterval = setInterval(() => {
      if (isCritiqueFetchRequired) {
        dispatch(critiqueActions.fetchCritiqueReviewStatus({ resumeId: tunerResumeId }))
      } else if (CritiqueTriggeredStatus.status === ResumeCritiqueStatuses.failed) {
        clearInterval(critiqueInterval)
      } else {
        clearInterval(critiqueInterval)
      }
    }, fetchApiTimeout)
    return () => clearInterval(critiqueInterval)
  }, [CritiqueTriggeredStatus.status, dispatch, isCritiqueFetchRequired, tunerResumeId])

  useEffect(() => {
    const critiqueQueryParams = new URLSearchParams(location.search)
    const critiqueKey = critiqueQueryParams.get('critique_review')
    setCritiqueParams(critiqueKey)
  }, [location])

  const resumeInterval = useRef<NodeJS.Timeout | null>(null)
  const resumeStatus = useTypedSelector(panelSelectors.resumeStatus)

  useEffect(() => {
    if (resumeStatus === ResumeIDStatuses.done) {
      clearInterval(resumeInterval.current as NodeJS.Timeout)
      trackInternalEvent('see_tr_banner_ready', {
        label: 'tr_leads_to_cio',
      })
      trackInternalEvent('complete_tr_prefill', {
        label: 'tr_leads_to_cio',
      })
    } else if (resumeStatus === ResumeIDStatuses.failed) {
      clearInterval(resumeInterval.current as NodeJS.Timeout)
    }
  }, [resumeStatus])

  useEffect(() => {
    if (resumeStatus === ResumeIDStatuses.skipped) {
      let timerRun = 0
      resumeInterval.current = setInterval(() => {
        timerRun += 1
        if (timerRun === 60) {
          dispatch(actions.setResumeStatus(ResumeIDStatuses.failed))
          clearInterval(resumeInterval.current as NodeJS.Timeout)
        } else {
          if (key === '1') {
            dispatch(actions.fetchResumeStatus({ resumeId: tunerResumeId }))
          }
          if (key) {
            trackInternalEvent('see_tr_banner_loading', {
              label: 'tr_leads_to_cio',
            })
          }
        }
      }, 1000)
    }
    return () => clearInterval(resumeInterval.current as NodeJS.Timeout)
  }, [tunerResumeId, dispatch, resumeStatus, key])

  const daysLeftPremium = useMemo(
    () =>
      user?.billingInfo?.premiumEndsAt
        ? calculateDaysDifference(user?.billingInfo?.premiumEndsAt) + 1
        : 0,
    [user?.billingInfo.premiumEndsAt],
  )

  const careerInsightsStatusModalType = useTypedSelector(
    careerProfileSelectors.careerInsightsStatusModalType,
  )

  const showPremiumReminderModal = useMemo(() => {
    return (
      daysLeftPremium <= 7 &&
      user?.billingStatus !== UserBillingStatus.subscription &&
      user?.billingInfo.userType &&
      [UserType.premium, UserType.postPremium].includes(user?.billingInfo.userType) &&
      !careerInsightsStatusModalType
    )
  }, [daysLeftPremium, user, careerInsightsStatusModalType])

  /* Your Job Tab Component */
  const renderFindJobUI = () => {
    return (
      <>
        {isDashboardV3 ? (
          <>
            <DashboardWidget {...props} />
            <BannersContainer />
          </>
        ) : (
          <>
            <DocumentsSection
              title={i18n.t('builder.panel.documents.title')}
              type={props.documentType}
              onTypeChange={props.onDocumentTypeChange}
              fromDashboard
            />
            {/* Career plan slider */}
            <CareerGoal />

            {/* Book a coach banner */}
            {features?.features.careerCoaching && <BookCoachBanner />}
          </>
        )}
      </>
    )
  }
  /* Excel at Your Job and Change Career Tab Component */
  const renderYourJobAndCareerUI = () => {
    return (
      <>
        {/* Career plan slider */}
        <CareerGoal />
        {/* Book a coach banner */}
        {features?.features.careerCoaching && <BookCoachBanner />}
        {/* resumes, tr-documents and cover-letters list */}
        <DocumentsSection
          title={i18n.t('builder.panel.documents.title')}
          type={props.documentType}
          onTypeChange={props.onDocumentTypeChange}
          fromDashboard
        />
      </>
    )
  }

  /* Close Welcome Video popup  */
  const handleWelcomeVideoClose = () => {
    dispatch(actions.setOpenWelcomeModal(false))
  }

  return (
    <>
      {!isDashboardV3 && <ExploreAndTrackJobsPaywall />}
      {isDashboardV3 && isPhone && (
        <Container>
          <JobSearchSolution hideDivider id="job-search-solution" />
        </Container>
      )}
      {isDashboardV3 && features.features.dashboardNavigationTabs && <TabsSwitcherMobile />}
      {isDashboardV3 && features.features.dashboardNavigationTabs && <TabsSwitcherTablet />}
      {isModalOpen && <CreateYourResumeModal />}
      {isEnterpriseUser && <JobStatusModal />}
      {isEnterpriseUser && <JobStatusProgressBar />}
      {isEnterpriseUser && <JobStatusConfirmationModal />}
      {isEnterpriseUser && <JobStatusHiredModal />}
      {premiumServiceModalData.isOpen && (
        <PremiumServicesModal source={premiumServiceModalData?.serviceType} />
      )}
      <BannerContainer>
        <Container>
          {params === '1' && <CareerFoundationBanner />}
          {critiqueParams === 'critique' && <CritiqueReviewMainBanner />}
        </Container>
      </BannerContainer>

      <PanelContainer>
        {isDashboardV3 && (
          <>
            <Stepper showStepper={selectedIndex === 0} />
            {selectedIndex === 0 && renderFindJobUI()}
          </>
        )}

        <Container>
          {features?.features.welcomeModal && (
            <WelcomeVideoOverlay
              isShowVideo={welcomeModelopenStatus}
              setIsShowVideo={handleWelcomeVideoClose}
            />
          )}

          {!isDashboardV3 && <ActionBanner />}

          {/* Render Your Job Tab Component */}
          {selectedIndex === 0 && !isDashboardV3 && renderFindJobUI()}
          {/* Render Excel at Your Job and Change Career Tab Component */}
          {(selectedIndex === 1 || selectedIndex === 2) && renderYourJobAndCareerUI()}

          {/* RIO to CIO Info modal */}
          <CioRioInfoModal />
        </Container>
      </PanelContainer>
      {/* CIO Helper Modal Trigger will appear after career assessment taken.
      Before it we show Career Profile Helper Modal */}
      {(isCareerProfileAvailable && <CareerProfileFloatingHelper />) ||
        (isSuperApp && isPremiumUser && <JobSearchButton />)}

      {/* Premium Days Left Reminder */}
      {showPremiumReminderModal && (
        <PremiumRemindModal daysLeftPremium={daysLeftPremium < 0 ? 0 : daysLeftPremium} />
      )}
    </>
  )
}
