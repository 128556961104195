import { Component } from 'react'
import PropTypes from 'prop-types'
import mapObject from 'lodash/map'
import getByPath from 'lodash/get'
import { i18n as I18n } from 'builder/utils/i18n'
import { TextField } from 'builder/components/TextField'
import SubscriptionSection from './SubscriptionSection'
import SocialItem from './SocialItem'
import UpdateAccountButton from './UpdateAccountButton'
import Notification from './Notification'
import AccountDeletionModal from './Modals/AccountDeletionModal'

import {
  Container,
  Content,
  Title,
  SectionTitle,
  Section,
  SectionInner,
  SectionContentCentered,
  SectionText,
  SectionBottom,
  RedButton,
  Row,
  Field,
  EmptyField,
  EmptyFieldText,
  ContactLink,
  SupportText,
} from './styles'

class AccountSettings extends Component {
  static propTypes = {
    account: PropTypes.object,
    error: PropTypes.string,
    config: PropTypes.object,
    isUpdating: PropTypes.bool,
    socialUpdating: PropTypes.object,
    onUpdate: PropTypes.func,
    onDisconnectSocial: PropTypes.func,
    onEmailNotificationToggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
  }

  static defaultProps = {
    account: {},
    config: {},
    isUpdating: false,
    onUpdate: () => {},
    onDisconnectSocial: () => {},
    onDelete: () => {},
  }

  state = {
    firstName: this.props.account.firstName,
    lastName: this.props.account.lastName,
    email: this.props.account.email,
    changed: false,
    isDeleteModalOpen: false,
    firstNameError: null,
    lastNameError: null,
    emailError: null,
  }

  handleInputChange = event => {
    const { name, value } = event.target
    let regex =
      // eslint-disable-next-line max-len
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    if (value.search(regex) === -1) {
      if (name === 'firstName') this.setState({ firstNameError: null })
      if (name === 'lastName') this.setState({ lastNameError: null })
      if (name === 'email') this.setState({ emailError: null })
      this.setState({
        [name]: value,
        changed: value && this.props.account[name] !== value,
      })
    } else {
      const emojiInputError = I18n.t('builder.sign_up.emoji_error')
      if (name === 'firstName') this.setState({ firstNameError: emojiInputError })
      if (name === 'lastName') this.setState({ lastNameError: emojiInputError })
      if (name === 'email') this.setState({ emailError: emojiInputError })
    }
  }

  handleUpdateClick = event => {
    const { firstName, lastName, email } = this.state

    this.props.onUpdate({
      firstName: firstName,
      lastName: lastName,
      email: email,
    })
    this.setState({ changed: false })
  }

  openDeleteModal = () => {
    this.setState({ isDeleteModalOpen: true })
  }

  closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false })
  }

  handleDeleteModalConfirm = () => {
    this.props.onDelete()
  }

  render() {
    const { account, onDisconnectSocial, config, isUpdating, socialUpdating, error } = this.props
    const { firstName, lastName, email, changed, firstNameError, lastNameError, emailError } =
      this.state
    const hostname = window.location.hostname
    const contactLinkUrl =
      hostname === 'career.io' ? 'https://career.io/contact' : getByPath(config, 'links.contact')
    const alreadyGotStayInTouchDiscount = account.billingInfo.alreadyGotStayInTouchDiscount

    return (
      <Container>
        <Content>
          <Title>{I18n.t('builder.account.account_settings')}</Title>

          <SubscriptionSection
            billingInfo={account.billingInfo}
            billingStatus={account.billingStatus}
            account={account}
            hasPremiumPlusFeatures={account.hasPremiumPlusFeatures}
            hasPremiumFeatures={account.hasPremiumFeatures}
            alreadyGotStayInTouchDiscount={alreadyGotStayInTouchDiscount}
          />

          <Section>
            <SectionTitle>{I18n.t('builder.account.account')}</SectionTitle>
            <SectionInner>
              <Row>
                <Field>
                  <TextField
                    name="firstName"
                    label={I18n.t('builder.account.first_name')}
                    value={firstName}
                    onChange={this.handleInputChange}
                    disabled={isUpdating}
                    error={firstNameError}
                  />
                </Field>

                <Field>
                  <TextField
                    name="lastName"
                    label={I18n.t('builder.account.last_name')}
                    value={lastName}
                    onChange={this.handleInputChange}
                    disabled={isUpdating}
                    error={lastNameError}
                  />
                </Field>
              </Row>

              <Row>
                <Field>
                  <TextField
                    name="email"
                    label={I18n.t('builder.account.email')}
                    value={email}
                    onChange={this.handleInputChange}
                    disabled={isUpdating}
                    error={emailError}
                  />
                </Field>

                <EmptyField>
                  {error && <EmptyFieldText error>{error}</EmptyFieldText>}
                  {!error && (
                    <EmptyFieldText>
                      {account.unconfirmedEmail
                        ? I18n.t('builder.account.email_confirmation_text', {
                            email: account.unconfirmedEmail,
                          })
                        : I18n.t('builder.account.email_text')}
                    </EmptyFieldText>
                  )}
                </EmptyField>
              </Row>

              <SectionBottom>
                <UpdateAccountButton
                  onClick={this.handleUpdateClick}
                  disabled={!changed}
                  isUpdating={isUpdating}
                />
              </SectionBottom>
            </SectionInner>
          </Section>

          <Section>
            <SectionTitle>{I18n.t('builder.account.social_profile')}</SectionTitle>
            <SectionInner>
              {account.socialProfiles.map((item, i) => (
                <SocialItem
                  item={item}
                  key={i}
                  onDisconnect={onDisconnectSocial}
                  socialUpdating={socialUpdating}
                />
              ))}
            </SectionInner>
          </Section>

          <Section>
            <SectionTitle>
              {I18n.t('builder.account.email_notifications.section_title')}
            </SectionTitle>
            <SectionInner>
              {mapObject(account.emailNotifications, (enabled, name) => (
                <Notification
                  key={name}
                  name={name}
                  enabled={enabled}
                  onChange={this.props.onEmailNotificationToggle}
                />
              ))}
            </SectionInner>
          </Section>

          <Section>
            <SectionTitle>{I18n.t('builder.account.danger_zone')}</SectionTitle>
            <SectionInner>
              <SectionContentCentered>
                <SectionText>{I18n.t('builder.account.delete_account_warning')}</SectionText>

                <RedButton onClick={this.openDeleteModal}>
                  {I18n.t('builder.account.delete_account')}
                </RedButton>
              </SectionContentCentered>
            </SectionInner>

            <SupportText>
              {I18n.t('builder.account.support_text')}
              <ContactLink href={contactLinkUrl}>
                {I18n.t('builder.account.support_link')}
              </ContactLink>
            </SupportText>
          </Section>
        </Content>
        <AccountDeletionModal
          account={account}
          deleteConfirmHandler={this.handleDeleteModalConfirm}
          isModalOpen={this.state.isDeleteModalOpen}
          closeModalHandler={this.closeDeleteModal}
        />
      </Container>
    )
  }
}

export default AccountSettings
