import type { Optional, ProfilePicture } from '@rio/types'
import { FetchStatuses } from 'builder/modules/constants'
import { UIStatesConfigStore } from '../panel'

export enum UserBillingStatus {
  /** no premium features */
  free = 'free',
  /** an active subscription is present */
  subscription = 'subscription',
  /** subscription has been cancelled, but still on premium until it expires */
  cancelled = 'cancelled',
  /** premium features will expire on `UserBillingInfo['premiumEndsAt']` */
  premium = 'premium',
}

export enum UserType {
  /** no premium features */
  free = 'free',
  /** an user earlier was a premium and now a free user */
  postPremium = 'post_premium',
  /** premium features will expire on `UserBillingInfo['premiumEndsAt']` */
  premium = 'premium',
}

export interface UserBillingInfo {
  subscriptionProvider: string
  upgradeUrl: string
  onTrial: boolean
  cancelledAt: Optional<string>
  premiumEndsAt: Optional<string>
  nextPaymentAt: Optional<string>
  userType: UserType
  alreadyGotDiscountDuringSubCancellation: boolean
  alreadyGotStayInTouchDiscount: boolean
  alreadyGotFreePausePeriodDuringCancellation: boolean
  recurringAmount?: string // "USD24.95"
}

export type UserSocialProfileProvider = 'facebook' | 'linkedin' | 'google_oauth2'

export interface UserSocialProfile {
  uid: Optional<string>
  provider: UserSocialProfileProvider
  connected: boolean
  connectionUrl: string
  profileUrl: Optional<string>
  handle: Optional<string>
  pictureUrl: Optional<string>
}

export interface UserData {
  id: number
  careerId: null | string
  email: string
  unconfirmedEmail: Optional<string>
  emailNotifications: Record<string, boolean>
  // personal info
  firstName: string
  lastName: string
  fullName: string
  profilePicture: ProfilePicture
  registeredAt: string
  socialProfiles: UserSocialProfile[]
  socialAccount: boolean
  linkedinUrl: string
  phone: Optional<string>
  // billing
  hasPremiumFeatures: boolean
  hasPremiumPlusFeatures: boolean
  billingStatus: UserBillingStatus
  billingInfo: UserBillingInfo
  // flags
  availableFreePeriod: null | 'week' | 'month'
  availableTopResumeReview: boolean
  visitedPlans: boolean
  /** @deprecated */
  announceEnabled: boolean
  isRegistered: boolean
  // redirection rio-to-cio
  isRioUser?: boolean
  showCioPromoModal: boolean
  smartBannerOrder?: ISmartBannerOrder[]
  uiStates?: UIStatesConfigStore
  currentJobTitleId?: string
  autoApplyPlan?: string
  showAbandonmentFlows: boolean
  aiProfileAttempts: number | null
  aiProfileFreeAttempts: number | null
  threePromoSubCancellation: boolean
  scopes: {
    premium: boolean
    autoApply: boolean | { creditCount: number; creditCountUsed: number; creditCountMax: number }
    resumeDistribution: boolean
  }
}

export interface Account {
  jwt: string | null
  jwtExpiresAt: string | null
  user: Optional<UserData>
}

export interface AccountApiSuccessResponse extends Account {
  success: true
}

export interface AccountApiErrorResponse {
  success: false
  errorMessage: string
}

export interface PremiumServiceModalData {
  isOpen: boolean
  serviceType: string | undefined
}

export type AccountApiResponse = AccountApiSuccessResponse | AccountApiErrorResponse

export interface ISmartBannerOrder {
  tab: string
  idleBannerName: string
  idleTimeStart: Date
}

export interface UserStore {
  fetchStatus: FetchStatuses
  isUpdating: boolean
  discountStatus: FetchStatuses
  discountErrorText: string | null
  cancellationStatus: FetchStatuses
  cancellationError: Optional<string>
  socialUpdating: Record<UserSocialProfileProvider, boolean>
  data: UserData | null
  error: string | null
  jwt: string | null
  jwtExpiresAt: string | null
  superAppLoginStatus: FetchStatuses
  pauseSubscriptionStatus: FetchStatuses
  submitFeedbackStatus: FetchStatuses
  isUserLoggingFirstTime: boolean
  isResumeDownloaded: boolean
}
