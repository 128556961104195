import { useState } from 'react'
import { useConfig } from 'builder/hooks/useConfig'
import SubscriptionCancellationViewOld from '../SubscriptionCancellationViewOld'
import { SubscriptionCancellationViewStepOne } from './StepOne'
import { SubscriptionCancellationViewStepThree } from './StepThree'
import { SubscriptionCancellationViewSocialProof } from './SocialProof'

export const SubscriptionCancellationView = () => {
  const [step, setStep] = useState(1)
  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features.subCancellationV4 || false
  const socialProofSubCancellation = config?.features.socialProofSubCancellation || false
  const { host } = window.location
  const isHostRioCio =
    host === 'staging.resume.io' ||
    host === 'resume.io' ||
    host === 'career.io' ||
    host === 'staging.career.io' ||
    host.includes('localhost')

  if (!isHostRioCio && !isSubscriptionV4Enabled) {
    return <SubscriptionCancellationViewOld />
  }

  return (
    <>
      {isSubscriptionV4Enabled &&
        step === 1 &&
        (socialProofSubCancellation ? (
          <SubscriptionCancellationViewSocialProof onContinueCancellation={() => setStep(2)} />
        ) : (
          <SubscriptionCancellationViewStepOne onContinueCancellation={() => setStep(3)} />
        ))}
      {isSubscriptionV4Enabled && step === 2 && (
        <SubscriptionCancellationViewStepOne onContinueCancellation={() => setStep(3)} />
      )}
      {(!isSubscriptionV4Enabled || step === 3) && <SubscriptionCancellationViewStepThree />}
    </>
  )
}
