import { useMemo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useConfig } from 'builder/hooks/useConfig'

import { BillingForm } from '../Billing/BillingForm'
import { calculateDaysDifference } from '../Navigation/components/PremiumTracker/utils'
import dashedClockIcon from './assets/dashedClockIcon.svg'
import interviewIcon from './assets/interviewIcon.svg'
import usersIcon from './assets/usersIcon.svg'
import {
  Close,
  CloseButtonContainer,
  CloseIcon,
  ModalSection,
  Overlay,
  RootModalContainer,
  UpsellFeature,
  UpsellFeatures,
  UpsellFeaturesContainer,
  UpsellHeaderContainer,
  UpsellTitle,
} from './styles'
import { useResumeDistributionUpsellModal } from './useResumeDistributionUpsellModal'

export const ResumeDistributionUpsellModal = () => {
  const { i18n } = useI18n()
  const { isOpen, handleModalClose } = useResumeDistributionUpsellModal()
  const user = useUser()
  const config = useConfig()

  const daysLeftPremium = useMemo(() => {
    return user?.billingInfo?.premiumEndsAt
      ? calculateDaysDifference(user?.billingInfo?.premiumEndsAt) + 1
      : 0
  }, [user?.billingInfo.premiumEndsAt])

  const isPaidUser = () => {
    if (
      !user?.billingInfo?.cancelledAt &&
      !user?.billingInfo?.nextPaymentAt &&
      (user?.billingInfo?.recurringAmount === 'USD0.0' || !user?.billingInfo?.recurringAmount)
    ) {
      return false
    }

    return !config?.promoted?.billingItems?.length
  }

  const getPrice = () => {
    return isPaidUser() ? '$10.00' : '$34.95'
  }

  if (!isOpen) return null

  return (
    <Overlay>
      <RootModalContainer>
        <ModalSection>
          <CloseButtonContainer>
            <Close onClick={handleModalClose}>
              <CloseIcon />
            </Close>
          </CloseButtonContainer>

          <UpsellHeaderContainer>
            <UpsellTitle>{i18n.t('builder.resume_distribution.upsell_modal.title')}</UpsellTitle>
          </UpsellHeaderContainer>

          <UpsellFeaturesContainer>
            <UpsellFeatures>
              <UpsellFeature iconUrl="/assets/media/Featured405bea8f5181edad4e9d.svg">
                {i18n.t('builder.resume_distribution.upsell_modal.features.job_applications')}
              </UpsellFeature>

              <UpsellFeature iconUrl="/assets/media/jobc49a418ea0ce514a2fd8.svg">
                {i18n.t('builder.resume_distribution.upsell_modal.features.weekly_jobs_shortlist')}
              </UpsellFeature>

              <UpsellFeature iconUrl={usersIcon}>
                {i18n.t('builder.resume_distribution.upsell_modal.features.send_resume')}
              </UpsellFeature>

              <UpsellFeature iconUrl={interviewIcon}>
                {i18n.t('builder.resume_distribution.upsell_modal.features.interview_preparation')}
              </UpsellFeature>

              {daysLeftPremium > 0 && (
                <UpsellFeature iconUrl={dashedClockIcon}>
                  {i18n.t('builder.resume_distribution.upsell_modal.features.do_not_lose_access', {
                    count: daysLeftPremium,
                  })}
                </UpsellFeature>
              )}
            </UpsellFeatures>
          </UpsellFeaturesContainer>

          <BillingForm
            plans={[
              {
                planPrice: getPrice(),
                planName: i18n.t('builder.resume_distribution.upsell_modal.plan.title'),
                planPeriod: i18n.t('builder.resume_distribution.upsell_modal.plan.period'),
              },
            ]}
            topBadgeText={i18n.t('builder.resume_distribution.upsell_modal.top_green_badge')}
            shouldShowTopBadge={isPaidUser()}
            isPaidUser={isPaidUser()}
            ctaLabel={i18n.t('builder.resume_distribution.upsell_modal.cta_label')}
            disclaimer={i18n.t('builder.resume_distribution.upsell_modal.disclaimer')}
          />
        </ModalSection>
      </RootModalContainer>
    </Overlay>
  )
}
