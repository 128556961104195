import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/interview/interviewModule'
import { store } from 'builder/modules/store'
import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'
import ErrorLogger from 'builder/services/ErrorLogger'
import { MIN_BLOB_SIZE, RECORD_DATA_READY_INTERVAL } from '../constants'
import { getMediaRecorder, invalidateCacheForInterviews } from '../utils'
import { useCompleteInterviewVideo } from './useCompleteInterviewVideo'
import { useInterviewPresignedUrl } from './useInterviewPresignedUrl'
import { useUploadInterviewVideo } from './useUploadInterviewVideo'
import { useMutationDeleteInterviewAnswerQuestion } from './useMutationDeleteInterviewAnswerQuestion'

export const useStartRecord = (
  userMedia: 'video' | 'audio',
  isValidVideoStream: boolean,
  questionId?: number,
  answerId?: number,
) => {
  const dispatch = useDispatch()
  const { mutateAsync: getPresignedUrl } = useInterviewPresignedUrl()
  const { mutateAsync: uploadChunk } = useUploadInterviewVideo()
  const { mutateAsync: completeFile } = useCompleteInterviewVideo()
  const { mutateAsync: deleteAnswer } = useMutationDeleteInterviewAnswerQuestion()

  const [recorder, setRecorder] = useState<MediaRecorder>()
  const duration = useRef(0)
  const dataCounter = useRef(1)

  const interruptedRef = useRef(false)
  const [state, setState] = useState('inactive')

  const handlePresignedUrl = async (questionId: number, answerId: number) => {
    const presignedKey = `presignedUrl_request-${userMedia}-${questionId}`
    PerformanceLogger.operation(presignedKey)
    PerformanceLogger.operationFull({
      key: presignedKey,
      op: 'presignedUrl_request',
      tags: {
        userMedia,
        questionId,
        answerId,
        partNumber: dataCounter.current,
      },
    })
    const presignedUrlPromise = getPresignedUrl({
      questionId,
      answerId,
      partNumber: dataCounter.current++,
    })

    dispatch(
      actions.setPresignedUrl({
        answerId,
        promise: presignedUrlPromise,
      }),
    )

    await presignedUrlPromise

    PerformanceLogger.operationEnd(presignedKey)

    return presignedUrlPromise
  }

  const startRecord = useCallback(async (stream: MediaStream) => {
    try {
      const recorder = getMediaRecorder(stream, userMedia)
      setRecorder(recorder)
      recorder.start(RECORD_DATA_READY_INTERVAL)
      return recorder
    } catch (error) {
      ErrorLogger.log(error, { tag: 'interview_player_media_recorder' })
    }
  }, [])

  async function sendChunkDataToS3(mergedBlobs: Blob, partSize: number) {
    if (!questionId || !answerId || !recorder) {
      return
    }

    const presignedUrl = await handlePresignedUrl(questionId, answerId)

    const metricKey = `chunk_request-${userMedia}-${questionId}-${partSize}`
    PerformanceLogger.operationFull({
      key: metricKey,
      op: `chunk_request-${userMedia}`,
      tags: {
        userMedia,
        questionId,
        answerId,
        size: mergedBlobs.size,
        partNumber: dataCounter.current,
      },
    })
    const uploadPromise = uploadChunk({
      url: userMedia === 'video' ? presignedUrl.video_upload_url : presignedUrl.audio_upload_url,
      blob: mergedBlobs,
    })

    dispatch(
      actions.setPutCall({
        answerId,
        promise: uploadPromise,
      }),
    )

    await uploadPromise

    PerformanceLogger.operationEnd(metricKey)

    return uploadPromise
  }

  async function waitingAnswerBeCompleted(totalBytes: number) {
    if (!questionId || !answerId || !recorder) {
      return
    }

    dispatch(
      actions.setAnswerComplete({
        answerId,
        userMedia,
      }),
    )

    const presignedCalls = store.getState().interview.presignedUrlCalls[answerId]
    if (!presignedCalls) return
    await Promise.all(presignedCalls)
    let answerCompleteStatus = store.getState().interview.answerComplete

    const bothAudioVideoRecording =
      answerCompleteStatus[answerId].audio && answerCompleteStatus[answerId].video

    const onlyAudioRecording = answerCompleteStatus[answerId].audio && !isValidVideoStream

    if (onlyAudioRecording || bothAudioVideoRecording) {
      const putCalls = store.getState().interview.putCalls
      const responses = await Promise.all(putCalls[answerId])
      const videoEtags = responses
        .filter(r => r.url.indexOf('video') > -1)
        .map((r, i) => ({ part_number: i + 1, etag: r.headers.get('Etag') || '' }))

      const audioEtags = responses
        .filter(r => r.url.indexOf('audio') > -1)
        .map((r, i) => ({ part_number: i + 1, etag: r.headers.get('Etag') || '' }))

      answerCompleteStatus = store.getState().interview.answerComplete
      if (!answerCompleteStatus[answerId].completedPromise) {
        try {
          const metricKey = `completeFile_request-${questionId}`
          PerformanceLogger.operationFull({
            key: metricKey,
            op: 'completeFile_request',
            tags: {
              userMedia,
              questionId,
              answerId,
              totalBytes,
            },
          })

          const completedPromise = completeFile({
            answerId,
            questionId,
            answerDuration: duration.current,
            totalBytes,
            body: {
              audio: audioEtags,
              video: videoEtags,
            },
          }).then(results => {
            PerformanceLogger.operationEnd(metricKey)
            return results
          })
          dispatch(
            actions.setAnswerCompletePromise({
              answerId,
              promise: completedPromise,
            }),
          )
        } catch (error) {
          ErrorLogger.log(error)
        }
      }
    }
  }

  useEffect(() => {
    let partSize = 0
    let blobParts: Blob[] = []
    let startDurationTimestamp = 0
    let endDurationTimestamp = 0
    let totalBytes = 0

    if (!recorder) {
      return
    }

    const updateRecorderState = () => {
      setState(recorder?.state)
    }
    recorder.onstart = () => {
      updateRecorderState()
      startDurationTimestamp = Date.now()
      dataCounter.current = 1
    }

    recorder.onerror = updateRecorderState
    recorder.onresume = updateRecorderState
    recorder.onpause = updateRecorderState

    recorder.onstop = async () => {
      updateRecorderState()
      if (interruptedRef.current && questionId) {
        if (userMedia === 'audio') {
          dispatch(actions.resetAnswer(questionId))
          deleteAnswer(questionId)
          await waitingAnswerBeCompleted(totalBytes)
          await invalidateCacheForInterviews()
        }
        return
      }

      endDurationTimestamp = Date.now()
      const answerDuration = (endDurationTimestamp - startDurationTimestamp) / 1000
      duration.current = answerDuration

      if (partSize > 0) {
        const mergedBlobs = new Blob(blobParts, { type: blobParts[0].type })
        await sendChunkDataToS3(mergedBlobs, partSize)
      }
      await waitingAnswerBeCompleted(totalBytes)
    }

    recorder.ondataavailable = async (event: BlobEvent) => {
      const blob = event.data
      partSize += blob.size
      totalBytes += blob.size
      blobParts.push(blob)
      if (partSize >= MIN_BLOB_SIZE) {
        const mergedBlobs = new Blob(blobParts, { type: blobParts[0].type })
        partSize = 0
        blobParts = []
        await sendChunkDataToS3(mergedBlobs, partSize)
      }
    }
  }, [recorder, answerId])

  const stop = async (interrupted: boolean) => {
    interruptedRef.current = interrupted
    recorder?.stop()
    if (!recorder && questionId) {
      await deleteAnswer(questionId)
      await waitingAnswerBeCompleted(0)
      await invalidateCacheForInterviews()
    }
  }

  return { startRecord, stop, state }
}
