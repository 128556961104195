import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'

import { selectors as profileSelectors } from 'builder/modules/careerProfile'

import {
  ActionButton,
  Container,
  Header,
  HeaderContainer,
  ImageContainer,
  SubHeader,
} from './styles'
import CoverImg from './assets/banner-cover.png'

const TRANSLATION = 'builder.dashboard.job_search_help.auto_apply.banner'

export const Banner = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { getHost } = useWebsiteHost()
  const { features } = useFeaturesConfig()
  const isAutoApplyComplete = useTypedSelector(profileSelectors.isAutoApplyComplete)

  const navigateTo = () => {
    trackInternalEvent('job_search_help_banner_click', {
      website_host: getHost,
      label: 'auto_apply',
    })
    navigate(
      isAutoApplyComplete
        ? `/job-search${features.jobBoardRename ? '' : '?view=recommendation'}`
        : '/auto-apply/start',
    )
  }

  return (
    <Container>
      <HeaderContainer>
        <Header>{i18n.t(`${TRANSLATION}.header`)}</Header>
        <SubHeader>{i18n.t(`${TRANSLATION}.sub_header`)}</SubHeader>

        <ActionButton onClick={navigateTo}>{i18n.t(`${TRANSLATION}.action`)}</ActionButton>
      </HeaderContainer>
      <ImageContainer src={CoverImg} />
    </Container>
  )
}
