import styled from 'styled-components'

import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

import Icon24 from 'builder/components/Icon'
import Button from 'builder/components/Button'
import Spinner from 'builder/components/Spinner'

export const Container = styled.div`
  margin: 56px auto;
  display: flex;
  gap: 128px;

  ${Media.Tablet`
    flex-direction: column;
    gap: 32px;
    height: calc(100vh - 140px);
    margin: auto;
  `}

  ${Media.Phone`
    flex-direction: column;
    gap: 16px;   
    height: calc(100vh - 190px);
    margin: auto;
    padding: 0 20px;
  `}
`

export const InfoBox = styled.div`
  width: 488px;

  ${Media.Phone`
    width: 100%;
  `}
`

export const NewInfoBox = styled.div`
  width: 488px;
  display: flex;
  gap: 32px;
  flex-direction: column;

  ${Media.Tablet`
    gap: 64px;
    margin-bottom: 32px;
  `}

  ${Media.Phone`
    gap: 32px;
    width: 100%;
    margin-bottom: 16px;
  `};
`

export const InfoIcon = styled.img`
  width: 87px;
  height: 82px;

  ${Media.Phone`
    width: 50px;
    height: 47px;
  `};
`

export const CompleteButton = styled(Button)`
  width: fit-content;
`

export const InfoHeadContiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const InfoHeader = styled.div`
  color: ${Colors.Neutral90};

  ${Typography.M}
  ${FontWeights.Medium}
`

export const InfoSubHeader = styled.div`
  color: ${Colors.Neutral50};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const InfoItems = styled.div`
  margin: 32px 0;
  display: flex;
  gap: 12px;
  flex-direction: column;
`

export const InfoItem = styled.div`
  display: flex;
  gap: 4px;
`

export const InfoItemText = styled.div`
  color: ${Colors.Neutral80};

  ${Typography.Body}
  ${FontWeights.Regular}
`

export const IconContainer = styled.div``
export const InfoItemTickIcon = styled(Icon24.Tick)`
  color: ${Colors.Blue50};
  margin-top: -5px;
`
export const InfoItemSpinner = styled(Spinner)`
  width: 15px;
  height: 23px;
  margin-right: 4px;
`

export const ActionButton = styled(Button)`
  width: 100%;

  ${Media.Tablet`
    margin-top: auto;
  `}

  ${Media.Phone`    
    margin-top: auto;
  `}
`

export const AnimationBox = styled.div`
  width: 544px;
  height: 544px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  ${Media.Tablet`
    width: 488px;
    height: 300px;
  `}

  ${Media.Phone`
    width: 100%;
    height: 209px;
  `}
`

export const Video = styled.video`
  position: absolute;
  height: inherit;
  width: inherit;

  ${Media.Tablet`
    top: -95px;
    height: unset;
  `}

  ${Media.Phone`
    top: -90px;
    width: 100%;
    height: unset;
  `}
`
