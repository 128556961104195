import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { Icon16, Icon20, Icon24 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/jobTracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import {
  selectors as jobSearchSelectors,
  actions as jobSearchActions,
} from 'builder/modules/jobSearch'
import { useAutoApply } from 'builder/views/AutoApply/hooks/useAutoApply'
import {
  AutoApplyButton,
  AutoApplyButtonContainer,
  AutoApplyGuide,
  AutoApplyGuideBubble,
  HiddenOnTablet,
  Bubble,
  Container,
  CreditValue,
  SavedJobs,
  SavedJobsCount,
  SettingIconContainer,
} from './styles'

export const PageActionButtons = () => {
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const user = useUser()
  const dispatch = useDispatch()

  const { isUserOnAutoApplyPlan, getAutoApplyCredit } = useAutoApply()
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  const savedJobsCount = useTypedSelector(selectors.savedJobsCount)

  const { creditCount, creditCountUsed } = getAutoApplyCredit()
  const handleSavedJobs = () => {
    trackInternalEvent('click_saved_jobs', {
      label: 'search',
    })
    navigate(`/job-tracking`)
  }

  const handleAutoApply = () => {
    trackInternalEvent('click_auto_apply', { label: 'job_search' })
    navigate(`/job-tracking`)
  }

  const handleSettingClick = () => {
    navigate(`/auto-apply/personal-info`)
  }

  return (
    <Container>
      {isUserOnAutoApplyPlan && (
        <>
          {autoApplyBubbles.selectedJob && autoApplyBubbles.showAutoApplyGuideButton && (
            <AutoApplyGuide
              onClick={() => {
                dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: false }))
              }}
            >
              <HiddenOnTablet>
                {i18n.t(`builder.job_tracking.auto_apply_guide_button`)}
              </HiddenOnTablet>
              <AutoApplyGuideBubble>
                <Icon20.QuestionIcon />
              </AutoApplyGuideBubble>
            </AutoApplyGuide>
          )}
          <AutoApplyButtonContainer>
            <AutoApplyButton onClick={handleAutoApply}>
              <Icon24.FeaturedIcon />
              <HiddenOnTablet>{i18n.t(`builder.job_tracking.auto_apply_button`)}</HiddenOnTablet>
            </AutoApplyButton>
            <Bubble>
              <CreditValue>
                {typeof user?.scopes?.autoApply === 'object'
                  ? `${creditCountUsed}/${creditCount}`
                  : `∞`}
              </CreditValue>
            </Bubble>
            <Bubble>
              <SettingIconContainer onClick={handleSettingClick}>
                <Icon16.SettingIcon />
              </SettingIconContainer>
            </Bubble>
          </AutoApplyButtonContainer>
        </>
      )}

      <SavedJobs onClick={handleSavedJobs}>
        <Icon24.Heart />
        <span>Saved Jobs</span>
        <SavedJobsCount>{savedJobsCount}</SavedJobsCount>
      </SavedJobs>
    </Container>
  )
}
