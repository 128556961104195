import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { actions as uiActions, SnackbarTypes } from 'builder/modules/ui'
import { Colors, toneNames } from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import { Logo } from 'builder/components/Logo'
import { Button } from 'builder/components/Button'
import * as Grid from 'builder/components/Grid'
import { TextField, TextAreaField } from 'builder/components/TextField'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { CompanyAutosuggest } from 'builder/components/CompanyAutosuggest'
import Tooltip from 'builder/components/Tooltip'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'
import DateRangePicker from 'builder/components/DateRangePicker'
import ColorPicker from 'builder/components/ColorPicker'
import { Tabs, Tab } from 'builder/components/Tabs'
import { Container as BaseContainer } from 'builder/components/Container'
import { SimpleEmailForm } from 'builder/components/SimpleEmailForm'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemCollapse,
  ListItemColumn,
  ListItemHeader,
  ListItemIcon,
  ListItemSubText,
  ListItemText,
} from '../List'
import { LevelSelectSection } from './components/LevelSelectSection'
import { CheckboxSection } from './components/CheckboxSection'
import { ToggleSection } from './components/ToggleSection'
import { SelectSection } from './components/SelectSection'
import { RadioGroupSection } from './components/RadioGroupSection'
import { ColorsSection } from './components/ColorsSection'
import { IconsSection } from './components/IconsSection'
import { ShadowsSection } from './components/ShadowsSection'
import { ButtonsSection } from './components/ButtonsSection'
import { RichTextEditorSection } from './components/RichTextEditorSection'
import { Section } from './Section'

import { fetchFakeSuggestions, fetchFakeCompanySuggestions, fakeDelay } from './utils'

import { Container, Label, Group, ComponentPreview, GridItem, BadgeStyled } from './styles'
import { SnackBarSectionV2 } from './components/SnackBarSectionV2'

export const UiKit = () => {
  const dispatch = useDispatch()
  const [color, setColor] = useState(Colors.Blue50)
  const [tabIndex, setTabIndex] = useState(0)
  const [suggestQuery, setSuggestQuery] = useState('')
  const [companyQuery, setCompanyQuery] = useState('')
  const [isEmailFormSubmitting, toggleEmailFormSubmitting] = useState(false)

  return (
    <Container>
      <BaseContainer>
        <ColorsSection />
        <ShadowsSection />
        <IconsSection />
        <ButtonsSection />

        <Section title="Components">
          <Group>
            <Label>Tooltip</Label>
            <ComponentPreview>
              <Tooltip value="Use professional field-tested resume templates" multiline>
                <Button size="small" style={{ width: '100%' }}>
                  Hover me
                </Button>
              </Tooltip>
            </ComponentPreview>
          </Group>

          <Group>
            <Label>Tooltip - TooltipQuestion</Label>
            <ComponentPreview>
              <TooltipQuestion textAlign="center" multiline>
                Use professional field-tested resume templates and get better
              </TooltipQuestion>
              <TooltipQuestion icon={<Icon24.Hint />}>Hello, world!</TooltipQuestion>
            </ComponentPreview>
          </Group>

          <Group>
            <Label>Snackbar</Label>
            <ComponentPreview
              style={{ display: 'flex', flexWrap: 'wrap', gap: '12px', alignItems: 'center' }}
            >
              <Button
                size="small"
                onClick={() =>
                  dispatch(uiActions.setWarningSnackbarOpen({ timeout: 1000, text: 'Hey!' }))
                }
              >
                Show Warning Snackbar
              </Button>

              <Button
                size="small"
                onClick={() =>
                  dispatch(
                    uiActions.setSnackBarOpen({
                      status: true,
                      type: SnackbarTypes.cancelable,
                      text: 'Close me!',
                    }),
                  )
                }
              >
                Show Cancelable Snackbar
              </Button>

              <Button
                size="small"
                onClick={() =>
                  dispatch(
                    uiActions.setSnackBarOpen({
                      status: true,
                      type: SnackbarTypes.loading,
                      text: 'Wait',
                    }),
                  )
                }
              >
                Show Loading Snackbar
              </Button>

              <Button
                size="small"
                onClick={() => dispatch(uiActions.setSnackBarOpen({ status: false }))}
              >
                Close it
              </Button>
            </ComponentPreview>
          </Group>
          <SnackBarSectionV2 />
          <LevelSelectSection />
          <CheckboxSection />
          <ToggleSection />
          <RadioGroupSection />

          <Group>
            <Label>TextField</Label>
            <ComponentPreview>
              <TextField label="Label" />
            </ComponentPreview>
            <ComponentPreview>
              <TextField label="Label" error="Error message" />
            </ComponentPreview>
            <ComponentPreview>
              <TextField label="Label" warning="Warning message" />
            </ComponentPreview>
            <ComponentPreview>
              <TextField
                label="Label"
                placeholder="Location"
                hint="Helpful hint"
                description="Field with placeholder, hint, description and icon"
                inputIcon={<Icon24.Location />}
                inputIconPosition="right"
              />
            </ComponentPreview>
            <ComponentPreview>
              <TextAreaField label="Label" rows={5} />
            </ComponentPreview>
          </Group>

          <SelectSection />
          <CheckboxSection />
          <ToggleSection />

          <Group>
            <Label>AsyncAutosuggest</Label>
            <ComponentPreview>
              <AsyncAutosuggest
                highlightedQuery
                value={suggestQuery}
                onChange={e => setSuggestQuery(e.target.value)}
                fetchItems={fetchFakeSuggestions}
              >
                {inputProps => <TextField {...inputProps} label="Label" />}
              </AsyncAutosuggest>
            </ComponentPreview>
          </Group>

          <Group>
            <Label>CompanyAutosuggest</Label>
            <ComponentPreview>
              <CompanyAutosuggest
                value={companyQuery}
                onChange={e => setCompanyQuery(e.target.value)}
                fetchItems={fetchFakeCompanySuggestions}
                onSuggestionSelected={console.log}
              >
                {inputProps => <TextField {...inputProps} label="Label" />}
              </CompanyAutosuggest>
            </ComponentPreview>
          </Group>

          <Group>
            <Label>DateRangePicker</Label>
            <ComponentPreview>
              <DateRangePicker value={{}} />
            </ComponentPreview>
          </Group>
          <Group>
            <Label>
              ColorPicker&nbsp;<span style={{ backgroundColor: color }}>{color}</span>
            </Label>
            <ComponentPreview>
              <ColorPicker hex={color} onChange={setColor} />
            </ComponentPreview>
          </Group>

          <LevelSelectSection />

          <Group>
            <Label>Tabs</Label>
            <ComponentPreview>
              <Tabs>
                {['First', 'Lo-o-o-ong', 'Third'].map((name, index) => (
                  <Tab
                    key={index}
                    isActive={tabIndex === index}
                    onClick={() => setTabIndex(index)}
                  >{`${name} Tab`}</Tab>
                ))}
              </Tabs>
            </ComponentPreview>
          </Group>

          <Group>
            <Label>Logo</Label>
            <ComponentPreview>
              <Logo />
              &nbsp;
              <Logo variant="white" style={{ backgroundColor: Colors.Neutral90 }} />
            </ComponentPreview>
          </Group>

          <Group>
            <Label>Badge - default & medium</Label>
            <ComponentPreview>
              {toneNames.map(variant => (
                <BadgeStyled size="medium" key={variant} variant={variant}>
                  Label - {variant}
                </BadgeStyled>
              ))}
            </ComponentPreview>
          </Group>
          <Group>
            <Label>Badge - dark & small</Label>
            <ComponentPreview>
              {toneNames.map(variant => (
                <BadgeStyled key={variant} variant={variant} dark>
                  Label - {variant}
                </BadgeStyled>
              ))}
            </ComponentPreview>
          </Group>

          <Group>
            <Label>Grid - Default Grid</Label>
            <Grid.Container>
              <GridItem>1</GridItem>
              <GridItem>2</GridItem>
              <GridItem>3</GridItem>
              <GridItem>4</GridItem>
              <GridItem>5</GridItem>
              <GridItem>6</GridItem>
              <GridItem>7</GridItem>
              <GridItem>8</GridItem>
              <GridItem>9</GridItem>
              <GridItem>10</GridItem>
              <GridItem>11</GridItem>
              <GridItem>12</GridItem>
            </Grid.Container>
          </Group>
          <Group>
            <Label>Grid - Custom Grid</Label>
            <Grid.Container desktop={12} tablet={12} phone={6}>
              <GridItem desktop={4} tablet={4} phone={2}>
                1
              </GridItem>
              <GridItem desktop={4} tablet={4} phone={2}>
                2
              </GridItem>
              <GridItem desktop={4} tablet={4} phone={2}>
                3
              </GridItem>
              <GridItem desktop={12} tablet={12} phone={6}>
                4
              </GridItem>
            </Grid.Container>
          </Group>
          <Group>
            <Label>Grid - Nested Grid</Label>
            <Grid.Container desktop={12} tablet={12} phone={6}>
              <GridItem desktop={12} tablet={12} phone={6}>
                <Grid.Container desktop={12} tablet={12} phone={6}>
                  <GridItem desktop={6} tablet={6} phone={3} />
                  <GridItem desktop={6} tablet={6} phone={3}>
                    1-2
                  </GridItem>
                </Grid.Container>
              </GridItem>
              <GridItem desktop={6} tablet={6} phone={3}>
                2
              </GridItem>
              <GridItem desktop={6} tablet={6} phone={3}>
                3
              </GridItem>
            </Grid.Container>
          </Group>

          <Group>
            <Label>List - Default List</Label>
            <List>
              <ListItem>
                <>
                  <ListItemColumn
                    columnWidth={1.25}
                    avatar={<ListItemAvatar size={48} src="" />}
                    header={<ListItemHeader text="John Smith" />}
                    subText={<ListItemSubText text="Senior Talent Acquisition Specialist" />}
                  />
                  <ListItemColumn
                    columnWidth={0.75}
                    text={<ListItemText text="TikTok" />}
                    subText={<ListItemSubText text="Herndon, VA" />}
                  />
                  <ListItemColumn
                    columnTextMargin="0 0 0 3px"
                    icon={<ListItemIcon icon={<Icon24.Pdf />} />}
                    text={<ListItemText text="Herman Walton_Resume.pdf" />}
                  />
                  <ListItemColumn
                    columnWidth={0.75}
                    columnTextPosition="end"
                    subText={<ListItemSubText text="Software Development" />}
                  />
                </>
              </ListItem>
            </List>
            <Label>List - Collapsible List</Label>
            <List>
              <ListItemCollapse
                header={
                  <ListItemColumn
                    columnWidth={1.25}
                    avatar={<ListItemAvatar size={48} src="" />}
                    header={<ListItemHeader text="John Smith" />}
                    subText={<ListItemSubText text="Senior Talent Acquisition Specialist" />}
                  />
                }
              >
                <>
                  <ListItemColumn
                    columnWidth={0.75}
                    columnTextPadding="0 0 0 50px"
                    text={<ListItemText text="TikTok" />}
                    subText={<ListItemSubText text="Herndon, VA" />}
                  />
                  <ListItemColumn
                    columnWidth={0.75}
                    columnTextPadding="12px 0 24px 50px"
                    subText={<ListItemSubText text="Software Development" />}
                  />
                </>
              </ListItemCollapse>
            </List>
          </Group>

          <Group>
            <Label>SimpleEmailForm</Label>
            <ComponentPreview>
              <SimpleEmailForm
                defaultValue="i@yaho.com"
                isPending={isEmailFormSubmitting}
                allowAvailableOnly
                autoFocus={false}
                onBackClick={() => alert("Someday I'm coming back!")}
                onSubmit={async values => {
                  toggleEmailFormSubmitting(true)
                  await fakeDelay(null, 2000)
                  alert(values.email)
                  toggleEmailFormSubmitting(false)
                }}
              />
            </ComponentPreview>
          </Group>
        </Section>
        <RichTextEditorSection />
      </BaseContainer>
    </Container>
  )
}
