import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import { actions as jobSearchActions } from 'builder/modules/jobSearch'
import {
  ActionButton,
  AnimationBox,
  CompleteButton,
  Container,
  IconContainer,
  InfoBox,
  InfoHeadContiner,
  InfoHeader,
  InfoIcon,
  InfoItem,
  InfoItems,
  InfoItemSpinner,
  InfoItemText,
  InfoItemTickIcon,
  InfoSubHeader,
  NewInfoBox,
  Video,
} from './styles'
import SlideVideo from './assets/slide.webm'
import SuccessIcon from './assets/success-icon.png'

const TRANSLATION = 'builder.auto_apply.complete_screen'

export const AutoApplyCompletedView = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const features = useFeaturesConfig()
  const { isPhone, isTablet } = useMediaQueries()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isTabletOrMobile = isPhone || isTablet

  const translation = `${TRANSLATION}.${isLoading ? 'loading' : 'completed'}`

  const onClick = () => {
    const link = `/job-search${features.features?.jobBoardRename ? '' : '?view=recommendation'}`

    if (link.includes('/job-search')) {
      dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: true }))
    }

    navigate(link)
  }

  useEffect(() => {
    trackInternalEvent('enter_success_screen', {
      label: 'total_job_search_solution',
    })
    setTimeout(() => {
      setIsLoading(false)
    }, 5000)
  }, [])

  return (
    <Container>
      {!isLoading ? (
        <NewInfoBox>
          <InfoIcon src={SuccessIcon} />

          <InfoHeadContiner>
            <InfoHeader>{i18n.t(`${translation}.header`)}</InfoHeader>
            <InfoSubHeader>{i18n.t(`${translation}.sub_header`)}</InfoSubHeader>
          </InfoHeadContiner>

          {!isTabletOrMobile && (
            <CompleteButton onClick={onClick}>{i18n.t(`${translation}.button`)}</CompleteButton>
          )}
        </NewInfoBox>
      ) : (
        <InfoBox>
          <InfoHeadContiner>
            <InfoHeader>{i18n.t(`${translation}.header`)}</InfoHeader>
            <InfoSubHeader>{i18n.t(`${translation}.sub_header`)}</InfoSubHeader>
          </InfoHeadContiner>

          <InfoItems>
            {[...Array(4).keys()].map((number: number) => (
              <InfoItem key={number}>
                <IconContainer>
                  {isLoading ? <InfoItemSpinner /> : <InfoItemTickIcon />}
                </IconContainer>
                <InfoItemText>{i18n.t(`${translation}.items.item_${number + 1}`)}</InfoItemText>
              </InfoItem>
            ))}
          </InfoItems>

          {!isLoading && !isTabletOrMobile && (
            <ActionButton onClick={onClick}>{i18n.t(`${translation}.button`)}</ActionButton>
          )}
        </InfoBox>
      )}
      <AnimationBox>
        <Video src={SlideVideo} muted autoPlay playsInline loop />
      </AnimationBox>
      {!isLoading && isTabletOrMobile && (
        <ActionButton onClick={onClick}>{i18n.t(`${translation}.button`)}</ActionButton>
      )}
    </Container>
  )
}
