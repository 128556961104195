/* eslint-disable max-len */
export const Add = () => <path d="M11 5H9v4H5v2h4v4h2v-4h4V9h-4V5z" />

export const Crown = () => <path d="M5 12.5H15V14H5V12.5Z M5 11L4 7L8 8L10 4L12 8L16 7L15 11H5Z" />

export const ArrowLeft = () => (
  <path d="M7.14236 10.8333l3.42074 3.1357-1.12618 1.2286-5-4.5833c-.36031-.3303-.36031-.89832 0-1.2286l5-4.58333 1.12618 1.22859-3.42074 3.1357 8.69104-.00002v1.66666H7.14236z" />
)

export const ChevronDown = () => (
  <path d="M10.243 10.414l2.828-2.828L14.485 9l-4.242 4.243L6 9l1.414-1.414 2.829 2.828z" />
)

export const ChevronRight = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.91406 5.83496L12.0819 10.0028L7.91406 14.1707"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ChevronLeft = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0859 5.83496L7.91808 10.0028L12.0859 14.1707"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ExpertiseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.97891 5.36489C7.97891 4.72875 8.4946 4.21306 9.13073 4.21306H10.8826C11.5187 4.21306 12.0344 4.72875 12.0344 5.36489L4.75183 5.36489C3.78432 5.36489 3 6.14921 3 7.11672V8.81161V9.41161V10.0116V13.2481C3 14.2156 3.78432 14.9999 4.75183 14.9999H15.2628C16.2303 14.9999 17.0146 14.2156 17.0146 13.2481V10.0116V9.41161V8.81161V7.11672C17.0146 6.14921 16.2303 5.36489 15.2628 5.36489L13.2344 5.36489C13.2344 4.06601 12.1814 3.01306 10.8826 3.01306H9.13073C7.83186 3.01306 6.77891 4.06601 6.77891 5.36489H7.97891ZM15.8146 8.81161H4.2V7.11672C4.2 6.81195 4.44706 6.56489 4.75183 6.56489H15.2628C15.5675 6.56489 15.8146 6.81195 15.8146 7.11672V8.81161ZM15.8146 13.2481V10.0116H4.2V13.2481C4.2 13.5529 4.44706 13.7999 4.75183 13.7999H15.2628C15.5676 13.7999 15.8146 13.5529 15.8146 13.2481Z"
      fill="#0F141E"
    />
  </svg>
)

export const Close = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.4 5.2 5.2 6.4 8.8 10l-3.6 3.6 1.2 1.1 3.6-3.5 3.6 3.5 1.1-1.1-3.5-3.6 3.5-3.6-1.1-1.2L10 8.8 6.4 5.2Z"
  />
)

export const Clock = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    color="transparent"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.442 15.8699C9.72905 15.8699 9.02308 15.7295 8.36439 15.4566C7.7057 15.1838 7.10721 14.7839 6.60307 14.2797C6.09893 13.7756 5.69903 13.1771 5.42619 12.5184C5.15336 11.8597 5.01293 11.1538 5.01293 10.4408C5.01293 9.72784 5.15336 9.02187 5.42619 8.36318C5.69903 7.70449 6.09893 7.106 6.60307 6.60186C7.10721 6.09772 7.7057 5.69782 8.36439 5.42498C9.02308 5.15215 9.72905 5.01172 10.442 5.01172C11.155 5.01172 11.8609 5.15215 12.5196 5.42498C13.1783 5.69782 13.7768 6.09772 14.281 6.60186C14.7851 7.106 15.185 7.7045 15.4578 8.36318C15.7307 9.02187 15.8711 9.72785 15.8711 10.4408C15.8711 11.1538 15.7307 11.8597 15.4578 12.5184C15.185 13.1771 14.7851 13.7756 14.281 14.2797C13.7768 14.7839 13.1783 15.1838 12.5196 15.4566C11.8609 15.7295 11.155 15.8699 10.442 15.8699L10.442 15.8699Z"
      strokeWidth="1.4"
    />
    <path
      d="M8.35156 2.50586L12.5278 2.50586"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1562 10.4411C13.1562 10.0846 13.086 9.73164 12.9496 9.40229C12.8132 9.07295 12.6132 8.7737 12.3612 8.52163C12.1091 8.26956 11.8099 8.06961 11.4805 7.93319C11.1512 7.79678 10.7982 7.72656 10.4417 7.72656"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
)

export const Drag = () => (
  <path d="M8.5 4c0 .82843-.67157 1.5-1.5 1.5S5.5 4.82843 5.5 4 6.17157 2.5 7 2.5s1.5.67157 1.5 1.5zM7 11.5c.82843 0 1.5-.6716 1.5-1.5 0-.82843-.67157-1.5-1.5-1.5s-1.5.67157-1.5 1.5c0 .8284.67157 1.5 1.5 1.5zm0 6c.82843 0 1.5-.6716 1.5-1.5s-.67157-1.5-1.5-1.5-1.5.6716-1.5 1.5.67157 1.5 1.5 1.5zm6 0c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5.6716 1.5 1.5 1.5zm1.5-7.5c0 .8284-.6716 1.5-1.5 1.5s-1.5-.6716-1.5-1.5c0-.82843.6716-1.5 1.5-1.5s1.5.67157 1.5 1.5zM13 5.5c.8284 0 1.5-.67157 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.67157-1.5 1.5.6716 1.5 1.5 1.5z" />
)

export const Edit = () => (
  <path d="M17.4413 6.25577c.7449.7448.7449 1.95236 0 2.69716L8.81405 17.5791c-.26829.2682-.63216.4189-1.01157.4189h-3.3719C3.64049 17.998 3 17.3576 3 16.5676v-3.3714c0-.3794.15072-.7432.41901-1.0115l8.62729-8.6261c.7449-.7448 1.9526-.7448 2.6975 0l2.6975 2.69717zM16.0926 7.6043l-2.6975-2.69717-6.62463 6.62377 2.69752 2.6971 6.62461-6.6237zm-7.97337 7.9723l-2.69752-2.6972-.44072.4407.01168 2.6855 2.68584.0117.44072-.4407z" />
)

export const Revert = () => (
  <path d="M10 15C7.58104 15 5.56329 13.2822 5.10002 11H3.07089C3.55612 14.3923 6.47353 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C8.04131 3 6.27052 3.80447 5 5.10102V3H3V8C3 8.19605 3.05642 8.37893 3.15391 8.53328C3.33105 8.81373 3.64377 9 4 9H5.10002H9V7H5.99963C6.91184 5.78555 8.36418 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15Z" />
)

export const StatusNew = () => (
  <path d="M18.695 12.33l-1.932-.516A7.014 7.014 0 0017 10c0-.63-.083-1.237-.237-1.814l1.932-.516c.2.743.305 1.524.305 2.33a9.01 9.01 0 01-.305 2.33zm-.9-6.83l-1.73 1a7.044 7.044 0 00-2.566-2.564l1.002-1.732A9.044 9.044 0 0117.796 5.5zM12.33 1.304l-.516 1.932A7.012 7.012 0 0010 3c-.63 0-1.237.083-1.814.237L7.67 1.305A9.012 9.012 0 0110 1c.806 0 1.587.106 2.33.305zm-6.83.9l1 1.73a7.044 7.044 0 00-2.564 2.566L2.204 5.499A9.044 9.044 0 015.5 2.204zM1.304 7.67A9.012 9.012 0 001 10c0 .806.106 1.587.305 2.33l1.932-.516A7.012 7.012 0 013 10c0-.63.083-1.237.237-1.814L1.305 7.67zm.9 6.83l1.73-1a7.044 7.044 0 002.566 2.564l-1.002 1.732A9.044 9.044 0 012.204 14.5zm5.465 4.195l.516-1.932A7.014 7.014 0 0010 17c.63 0 1.238-.083 1.814-.237l.516 1.932A9.01 9.01 0 0110 19a9.011 9.011 0 01-2.33-.305zm6.83-.9l-1-1.73a7.043 7.043 0 002.564-2.566l1.732 1.002a9.043 9.043 0 01-3.295 3.295z" />
)

export const StatusStarted = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 17a7 7 0 100-14 7 7 0 000 14zm0 2a9 9 0 100-18 9 9 0 000 18z"
  />
)

export const StatusHalf = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17 10a7 7 0 11-14 0 7 7 0 0114 0zm2 0a9 9 0 11-18 0 9 9 0 0118 0zm-9-5a5 5 0 015 5H5a5 5 0 015-5z"
  />
)

export const StatusDone = () => (
  <path d="M19 10a9 9 0 11-18 0 9 9 0 0118 0zm-8.94 2.854l4.647-4.647-1.414-1.414L9 11.086 6.707 8.793l-1.414 1.414 2.646 2.647a1.5 1.5 0 002.122 0z" />
)

export const StatusCanceled = () => (
  <path d="M10 19a9 9 0 100-18 9 9 0 000 18zm2.293-5.293L10 11.414l-2.293 2.293-1.414-1.414L8.586 10 6.293 7.707l1.414-1.414L10 8.586l2.293-2.293 1.414 1.414L11.414 10l2.293 2.293-1.414 1.414z" />
)

export const StatusOffer = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM10.0607 12.8536L14.7071 8.20711L13.2929 6.79289L9.00001 11.0858L6.70712 8.79289L5.29291 10.2071L7.93935 12.8536C8.52514 13.4393 9.47489 13.4393 10.0607 12.8536Z"
  />
)

export const Refresh = () => (
  <path d="M14.3885 11c-.4547 2.004-2.2469 3.5-4.3885 3.5-1.49778 0-2.82469-.7318-3.64265-1.8573L8 11H4v4l1.28678-1.2868C6.38552 15.106 8.08838 16 10 16c2.973 0 5.441-2.1623 5.9171-5h-1.5286zM5.61156 9C6.06629 6.99601 7.85846 5.5 10 5.5c1.4979 0 2.8247.73177 3.6427 1.85729L12 9h4V5l-1.2867 1.28672C13.6145 4.894 11.9117 4 10 4 7.02705 4 4.55909 6.16229 4.08301 9h1.52855z" />
)

export const Tick = () => (
  <path d="M14.0973 6L15.5 7.42884L9.75408 13.6135C9.2759 14.138 8.84826 14.1196 8.35134 13.6135L4.5 10.2408L5.90275 8.81201L9.05271 11.4702L14.0973 6Z" />
)

export const Trash = () => (
  <path d="M14 6h3v2H3V6h3V3c0-.55228.44772-1 1-1h6c.5523 0 1 .44772 1 1v3zm-9 4h10v8H5v-8zm2 6h6v-4H7v4zm5-10V4H8v2h4z" />
)

export const SuccessCircle = () => (
  <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm3.097-10.929l-3.544 3.9-2.15-1.9L6 10.5l2.851 2.613c.497.507.925.525 1.403 0L14.5 8.5l-1.403-1.429z" />
)

export const WarningCircle = () => (
  <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM9 11.5V5H11V11.5H9ZM11.2544 14.0044C11.2544 14.6947 10.6948 15.2544 10.0044 15.2544C9.31404 15.2544 8.75439 14.6947 8.75439 14.0044C8.75439 13.314 9.31404 12.7544 10.0044 12.7544C10.6948 12.7544 11.2544 13.314 11.2544 14.0044Z" />
)

export const Info = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 19a9 9 0 100-18 9 9 0 000 18zm1.3-13a1.2 1.2 0 11-2.5 0 1.2 1.2 0 012.5 0zm-.3 9V9H9v6h2z"
  />
)

export const Idea = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.5 14.3a5 5 0 1 1 5 0v4.2h-5v-4.2Zm4.2-1.3-.7.5V17H9v-3.5l-.7-.5a3.5 3.5 0 1 1 3.4 0Z M9.3 1v2h1.5V1H9.3ZM5.5 4.5 4 3 3 4l1.5 1.5 1-1ZM15.5 5.5 17 4l-1-1-1.5 1.5 1 1ZM1 10.7h2V9.2H1v1.5ZM17 10.7h2V9.2h-2v1.5Z"
  />
)

export const Calendar = () => (
  <path d="M13 6h3v11H4V6h3V4.5h2V6h2V4.5h2V6zM6 9v6h8V9H6zm1 4v-2h2v2H7zm4 0v-2h2v2h-2z" />
)

export const Settings = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.07 7.15 10 4.31l4.93 2.84v5.7L10 15.68l-4.93-2.85zM9.5 2.3a1 1 0 0 1 1 0l5.93 3.42c.3.18.5.51.5.87v6.84a1 1 0 0 1-.5.87L10.5 17.7a1 1 0 0 1-1 0L3.57 14.3a1 1 0 0 1-.5-.87V6.58a1 1 0 0 1 .5-.87zM11 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
  />
)

export const Star = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 13.8l-3.8 2.1c-.2.2-.5 0-.6-.1l-.1-.4 1-4.1-3.3-2.8a.5.5 0 010-.7c0-.1.1-.2.3-.2l4.3-.4 1.7-3.9a.5.5 0 011 0l1.7 3.9 4.3.4c.3 0 .5.3.5.5s0 .3-.2.4l-3.3 2.8 1 4.1c0 .3-.1.5-.4.6h-.3L10 13.7z"
  />
)

export const Hint = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.4 9.6c.6-.6 1.2-1.2 1.2-2.4 0-1.8-1.3-3-3.5-3-2 0-3.4 1.3-3.5 3.5h2.1c0-1 .7-1.6 1.4-1.6 1 0 1.4.5 1.4 1.3 0 .6-.4 1-.9 1.4a4 4 0 0 0-1.5 3.3h2c0-1.2.7-1.9 1.3-2.5ZM10 13.4c-.6 0-1.2.6-1.2 1.2 0 .7.6 1.3 1.2 1.3.7 0 1.3-.6 1.3-1.3 0-.6-.6-1.2-1.3-1.2Z"
  />
)

export const Hint2 = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10ZM17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM12.46 8.02C12.46 8.92202 11.997 9.37698 11.5299 9.83594C11.0546 10.303 10.575 10.7742 10.575 11.725H9.08C9.08 10.3082 9.71163 9.72604 10.2307 9.24764C10.5927 8.91396 10.9 8.63074 10.9 8.15C10.9 7.565 10.51 7.175 9.86 7.175C9.275 7.175 8.82 7.63 8.82 8.41H7.26C7.325 6.785 8.365 5.745 9.86 5.745C11.485 5.745 12.46 6.72 12.46 8.02ZM9.86 12.375C9.405 12.375 9.015 12.765 9.015 13.22C9.015 13.675 9.405 14.065 9.86 14.065C10.315 14.065 10.705 13.675 10.705 13.22C10.705 12.765 10.315 12.375 9.86 12.375Z"
  />
)

export const Spinner = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C12.7605 15 14.9986 12.7628 15 10.0026C14.9946 9.07428 14.7347 8.20531 14.2859 7.45903C14.0013 6.98574 14.1542 6.37132 14.6275 6.08669C15.1008 5.80207 15.7152 5.95501 15.9998 6.4283C16.628 7.4729 16.9931 8.69366 17 9.99469L17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C10.5523 3 11 3.44772 11 4C11 4.55228 10.5523 5 10 5Z"
  />
)

export const Featured = () => (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M5.208 2.978c-.131.072-.169.165-.343.843-.224.872-.177.823-.998 1.03-.34.086-.666.179-.727.207-.278.129-.278.588 0 .718.061.028.39.122.733.209.818.206.768.156.975.972.184.722.226.828.355.894.187.097.482.035.565-.12.02-.037.11-.354.201-.705.09-.35.187-.672.215-.714.088-.136.198-.18.844-.345.347-.088.662-.177.701-.198.221-.118.222-.586.002-.703a8.516 8.516 0 0 0-.705-.201c-.655-.169-.755-.21-.842-.343-.028-.043-.125-.364-.215-.715a9.12 9.12 0 0 0-.201-.705c-.08-.149-.391-.218-.56-.124m5.709 1.898c-.291.118-.475.285-.579.524-.036.082-.241.832-.455 1.667-.214.834-.406 1.534-.428 1.555-.021.021-.721.214-1.555.428s-1.586.419-1.67.455a1.025 1.025 0 0 0-.469.462c-.112.217-.112.683 0 .9.101.193.255.355.42.44.072.037.843.25 1.713.475l1.583.407.408 1.583c.224.87.438 1.642.475 1.714.085.165.247.319.44.419.217.113.683.113.9 0 .193-.1.355-.254.44-.419.037-.072.251-.844.475-1.714l.407-1.583 1.583-.407c.871-.224 1.642-.438 1.714-.475.165-.085.319-.247.42-.44.065-.125.078-.201.078-.45 0-.25-.013-.326-.078-.45a1.041 1.041 0 0 0-.42-.44c-.072-.037-.843-.251-1.714-.476l-1.583-.407-.407-1.583c-.224-.87-.438-1.641-.475-1.713a1.036 1.036 0 0 0-.44-.42c-.176-.091-.616-.12-.783-.052m.669 2.992c.177.687.351 1.309.388 1.382a.988.988 0 0 0 .426.432c.114.059 2.555.718 2.657.718.087 0-.167.071-1.258.352-.687.177-1.317.357-1.399.399a.988.988 0 0 0-.426.432c-.052.104-.707 2.552-.707 2.641 0 .087-.072-.168-.353-1.259-.177-.686-.351-1.308-.388-1.382a.988.988 0 0 0-.426-.432c-.114-.059-2.555-.718-2.657-.718-.087 0 .167-.071 1.258-.352.687-.177 1.316-.356 1.399-.399a.988.988 0 0 0 .426-.432c.052-.104.707-2.551.707-2.641 0-.086.072.168.353 1.259"
    fillRule="evenodd"
  />
)

export const ThumbsUp = () => (
  <path d="M12.9979 6.66667H15.0834C16.3843 6.66667 17.174 7.46673 17.1532 8.7716L17.1433 8.88659L16.3256 14.1352C16.1734 15.1227 15.3107 15.8444 14.3165 15.8333H8.00004L8.00004 15.8333H4.66671C4.20647 15.8333 3.83337 15.4602 3.83337 15V8.33333C3.83337 7.8731 4.20647 7.5 4.66671 7.5H7.48501L9.75468 2.96066C9.89584 2.67834 10.1844 2.5 10.5 2.5C11.9378 2.5 12.9935 3.56862 12.9935 4.99781L12.9979 6.66667ZM15.0834 8.33333H12.1667C11.7073 8.33333 11.3346 7.96157 11.3334 7.50219L11.3268 5C11.3268 4.66453 11.1938 4.41273 10.9595 4.2778L8.83337 8.53006V14.1666L14.3258 14.1666C14.5046 14.1686 14.6532 14.0442 14.6786 13.88L15.4872 8.68969C15.4878 8.36809 15.4353 8.33333 15.0834 8.33333Z" />
)

export const ArrowTarget = () => (
  <>
    <path
      d="M16.6663 9.99992C16.6663 13.6818 13.6816 16.6666 9.99967 16.6666C6.31778 16.6666 3.33301 13.6818 3.33301 9.99992C3.33301 6.31802 6.31778 3.33325 9.99967 3.33325"
      stroke="currentColor"
      strokeLinecap="round"
      fill="transparent"
    />
    <path
      d="M8.97027 6.82989C8.41941 7.00888 7.9247 7.32831 7.53489 7.75671C7.14508 8.1851 6.87362 8.70768 6.74727 9.27294C6.62092 9.83819 6.64404 10.4266 6.81435 10.9802C6.98466 11.5338 7.29628 12.0335 7.7185 12.43C8.14072 12.8265 8.65897 13.1061 9.22217 13.2413C9.78538 13.3765 10.3741 13.3627 10.9303 13.2011C11.4865 13.0395 11.991 12.7357 12.3941 12.3198C12.7972 11.9038 13.0849 11.3901 13.2289 10.829"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M9.99853 9.99804L11.5357 8.46085M11.5357 8.46085L11.3753 6.16125C11.3551 5.87251 11.4611 5.58921 11.6657 5.38454L14.4179 2.63235L14.3539 5.64267L17.3642 5.57862L14.612 8.33082C14.4074 8.53549 14.1241 8.64143 13.8353 8.62129L11.5357 8.46085Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </>
)

export const Update = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1217 3.33428C16.1217 2.94768 15.8083 2.63428 15.4217 2.63428C15.0351 2.63428 14.7217 2.94768 14.7217 3.33428V5.48192C14.1976 4.93497 13.5797 4.48037 12.8932 4.14183C11.734 3.57017 10.4313 3.3551 9.14987 3.5238C7.86843 3.69251 6.66579 4.23742 5.69403 5.08963C4.72228 5.94183 4.02505 7.06306 3.69053 8.31152C3.59047 8.68495 3.81208 9.06878 4.1855 9.16884C4.55893 9.2689 4.94277 9.04729 5.04283 8.67387C5.30568 7.69286 5.85354 6.81184 6.61712 6.1422C7.38069 5.47256 8.32569 5.04439 9.33261 4.91183C10.3395 4.77926 11.3631 4.94826 12.274 5.39745C12.9545 5.73303 13.5492 6.21372 14.0183 6.80214H11.2538C10.8672 6.80214 10.5538 7.11554 10.5538 7.50214C10.5538 7.88874 10.8672 8.20214 11.2538 8.20214H15.4217C15.8083 8.20214 16.1217 7.88874 16.1217 7.50214V3.33428Z"
      fill="#1A91F0"
    />
    <path
      d="M3.74944 15.9117C3.74944 16.2983 4.06284 16.6117 4.44944 16.6117C4.83604 16.6117 5.14944 16.2983 5.14944 15.9117L5.14944 13.7641C5.67346 14.311 6.29138 14.7656 6.97787 15.1041C8.13708 15.6758 9.43978 15.8909 10.7212 15.7222C12.0027 15.5535 13.2053 15.0086 14.1771 14.1563C15.1488 13.3041 15.846 12.1829 16.1806 10.9345C16.2806 10.561 16.059 10.1772 15.6856 10.0771C15.3122 9.97707 14.9283 10.1987 14.8283 10.5721C14.5654 11.5531 14.0176 12.4341 13.254 13.1038C12.4904 13.7734 11.5454 14.2016 10.5385 14.3341C9.53157 14.4667 8.50795 14.2977 7.59708 13.8485C6.9166 13.5129 6.32187 13.0323 5.85283 12.4438L8.6173 12.4438C9.0039 12.4438 9.3173 12.1304 9.3173 11.7438C9.3173 11.3572 9.0039 11.0438 8.6173 11.0438L4.44944 11.0438C4.06284 11.0438 3.74944 11.3572 3.74944 11.7438L3.74944 15.9117Z"
      fill="#1A91F0"
    />
  </svg>
)

export const Pause = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" y="5" width="3" height="10" rx="1" fill="currentColor" />
    <rect x="11" y="5" width="3" height="10" rx="1" fill="currentColor" />
  </svg>
)

export const Play = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5145 4.9087C6.84797 4.50878 6 4.9889 6 5.76619V14.2338C6 15.0111 6.84797 15.4912 7.5145 15.0913L14.5708 10.8575C15.2182 10.4691 15.2182 9.53091 14.5708 9.14251L7.5145 4.9087Z"
      fill="currentColor"
    />
  </svg>
)

export const Time = () => (
  <>
    <path
      d="M10.442 15.8699C9.72905 15.8699 9.02308 15.7295 8.36439 15.4566C7.7057 15.1838 7.10721 14.7839 6.60307 14.2797C6.09893 13.7756 5.69903 13.1771 5.42619 12.5184C5.15336 11.8597 5.01293 11.1538 5.01293 10.4408C5.01293 9.72784 5.15336 9.02187 5.42619 8.36318C5.69903 7.70449 6.09893 7.106 6.60307 6.60186C7.10721 6.09772 7.7057 5.69782 8.36439 5.42498C9.02308 5.15215 9.72905 5.01172 10.442 5.01172C11.155 5.01172 11.8609 5.15215 12.5196 5.42498C13.1783 5.69782 13.7768 6.09772 14.281 6.60186C14.7851 7.106 15.185 7.7045 15.4578 8.36318C15.7307 9.02187 15.8711 9.72785 15.8711 10.4408C15.8711 11.1538 15.7307 11.8597 15.4578 12.5184C15.185 13.1771 14.7851 13.7756 14.281 14.2797C13.7768 14.7839 13.1783 15.1838 12.5196 15.4566C11.8609 15.7295 11.155 15.8699 10.442 15.8699L10.442 15.8699Z"
      stroke="currentColor"
      strokeWidth="1.4"
    />
    <path
      d="M8.35156 2.50586L12.5278 2.50586"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1562 10.4411C13.1562 10.0846 13.086 9.73164 12.9496 9.40229C12.8132 9.07295 12.6132 8.7737 12.3612 8.52163C12.1091 8.26956 11.8099 8.06961 11.4805 7.93319C11.1512 7.79678 10.7982 7.72656 10.4417 7.72656"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </>
)

export const TrashIcon = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.21152 5.89502V4.79062H11.8201V5.89502H8.21152ZM6.81152 5.89502V4.50801C6.81152 3.8909 7.31179 3.39062 7.92891 3.39062H12.1028C12.7199 3.39062 13.2201 3.8909 13.2201 4.50801V5.89502H15.0249C15.4115 5.89502 15.7249 6.20842 15.7249 6.59502C15.7249 6.98162 15.4115 7.29502 15.0249 7.29502H14.2374L13.5508 15.4538C13.5021 16.0326 13.0181 16.4775 12.4373 16.4775H7.59515C7.01436 16.4775 6.5304 16.0326 6.4817 15.4538L5.79509 7.29502H5.00762C4.62102 7.29502 4.30762 6.98162 4.30762 6.59502C4.30762 6.20842 4.62102 5.89502 5.00762 5.89502H6.81152ZM7.85498 15.0775L7.20004 7.29502H12.8324L12.1775 15.0775H7.85498Z"
      fill="currentColor"
    />
  </>
)

export const Copy = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.34493 3.49023C7.55252 3.49023 6.91016 4.1326 6.91016 4.92501V6.82935H5.00899C4.21659 6.82935 3.57422 7.47172 3.57422 8.26412V14.9423C3.57422 15.7347 4.21659 16.377 5.00899 16.377H11.6872C12.4796 16.377 13.1219 15.7347 13.1219 14.9423V13.0379H15.0231C15.8155 13.0379 16.4579 12.3956 16.4579 11.6032V4.925C16.4579 4.1326 15.8155 3.49023 15.0231 3.49023H8.34493ZM11.9219 13.0379H8.34493C7.55253 13.0379 6.91016 12.3956 6.91016 11.6032V8.02935H5.00899C4.87933 8.02935 4.77422 8.13446 4.77422 8.26412V14.9423C4.77422 15.0719 4.87933 15.177 5.00899 15.177H11.6872C11.8168 15.177 11.9219 15.0719 11.9219 14.9423V13.0379ZM8.11016 4.92501C8.11016 4.79534 8.21527 4.69023 8.34493 4.69023H15.0231C15.1527 4.69023 15.2579 4.79535 15.2579 4.925V11.6032C15.2579 11.7328 15.1527 11.8379 15.0231 11.8379H8.34493C8.21527 11.8379 8.11016 11.7328 8.11016 11.6032V4.92501Z"
    />
  </svg>
)

// Navigation Icons
export const Dashboard = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_530_38575" fill="white">
      <rect x="4" y="4" width="5" height="5" rx="0.8" />
    </mask>
    <rect
      x="4"
      y="4"
      width="5"
      height="5"
      rx="0.8"
      stroke="currentColor"
      strokeWidth="2.8"
      mask="url(#path-1-inside-1_530_38575)"
      fill="none"
    />
    <mask id="path-2-inside-2_530_38575" fill="white">
      <rect x="4" y="10.6953" width="5" height="5" rx="0.8" />
    </mask>
    <rect
      x="4"
      y="10.6953"
      width="5"
      height="5"
      rx="0.8"
      stroke="currentColor"
      strokeWidth="2.8"
      mask="url(#path-2-inside-2_530_38575)"
      fill="none"
    />
    <mask id="path-3-inside-3_530_38575" fill="white">
      <rect x="10.6914" y="4" width="5" height="5" rx="0.8" />
    </mask>
    <rect
      x="10.6914"
      y="4"
      width="5"
      height="5"
      rx="0.8"
      stroke="currentColor"
      strokeWidth="2.8"
      mask="url(#path-3-inside-3_530_38575)"
      fill="none"
    />
    <mask id="path-4-inside-4_530_38575" fill="white">
      <rect x="10.6914" y="10.6953" width="5" height="5" rx="0.8" />
    </mask>
    <rect
      x="10.6914"
      y="10.6953"
      width="5"
      height="5"
      rx="0.8"
      stroke="currentColor"
      strokeWidth="2.8"
      mask="url(#path-4-inside-4_530_38575)"
      fill="none"
    />
  </svg>
)

export const Documents = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9987 3.33594H6.2487C6.01858 3.33594 5.83203 3.52249 5.83203 3.7526V10.8359V16.2526C5.83203 16.4827 6.01858 16.6693 6.2487 16.6693H13.7487C13.9788 16.6693 14.1654 16.4827 14.1654 16.2526V7.5026M9.9987 3.33594L14.1654 7.5026M9.9987 3.33594V7.08594C9.9987 7.31606 10.1852 7.5026 10.4154 7.5026H14.1654"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
)

export const Jobs = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07891 6.45183C8.07891 5.87092 8.54983 5.4 9.13073 5.4H10.8826C11.4635 5.4 11.9344 5.87092 11.9344 6.45183L4.75183 6.45183C3.78432 6.45183 3 7.23615 3 8.20366V9.79855V10.4985V11.1985V14.335C3 15.3026 3.78432 16.0869 4.75183 16.0869H15.2628C16.2303 16.0869 17.0146 15.3026 17.0146 14.3351V11.1985V10.4985V9.79855V8.20366C17.0146 7.23615 16.2303 6.45183 15.2628 6.45183L13.3344 6.45183C13.3344 5.09772 12.2367 4 10.8826 4H9.13073C7.77663 4 6.67891 5.09772 6.67891 6.45183H8.07891ZM15.6146 9.79855H4.4V8.20366C4.4 8.00935 4.55752 7.85183 4.75183 7.85183H15.2628C15.4571 7.85183 15.6146 8.00935 15.6146 8.20366V9.79855ZM15.6146 14.3351V11.1985H4.4V14.335C4.4 14.5294 4.55752 14.6869 4.75183 14.6869H15.2628C15.4571 14.6869 15.6146 14.5294 15.6146 14.3351Z"
      fill="currentColor"
    />
  </svg>
)

export const JobTracker = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="4.16797"
      y="4.16406"
      width="4.58333"
      height="5.83333"
      rx="0.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinejoin="round"
      fill="none"
    />
    <rect
      x="11.25"
      y="10"
      width="4.58333"
      height="5.83333"
      rx="0.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinejoin="round"
      fill="none"
    />
    <rect
      x="11.25"
      y="4.16406"
      width="4.58333"
      height="3.33333"
      rx="0.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinejoin="round"
      fill="none"
    />
    <rect
      x="4.16797"
      y="12.5"
      width="4.58333"
      height="3.33333"
      rx="0.5"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
  </svg>
)

export const InterviewPrep = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50986 8.16094C5.50986 7.16682 6.31575 6.36094 7.30986 6.36094C8.30397 6.36094 9.10986 7.16682 9.10986 8.16094C9.10986 9.15505 8.30397 9.96094 7.30986 9.96094C6.31575 9.96094 5.50986 9.15505 5.50986 8.16094ZM7.30986 4.96094C5.54255 4.96094 4.10986 6.39363 4.10986 8.16094C4.10986 9.92825 5.54255 11.3609 7.30986 11.3609C9.07717 11.3609 10.5099 9.92825 10.5099 8.16094C10.5099 6.39363 9.07717 4.96094 7.30986 4.96094ZM7.30856 12.4609C6.45428 12.4609 5.61505 12.6858 4.87522 13.1129C4.1354 13.5401 3.52104 14.1544 3.0939 14.8943C2.9006 15.2291 3.01531 15.6572 3.35012 15.8505C3.68492 16.0438 4.11304 15.9291 4.30633 15.5943C4.6106 15.0673 5.04822 14.6296 5.57522 14.3254C6.10222 14.0211 6.70003 13.8609 7.30856 13.8609C7.91708 13.8609 8.51489 14.0211 9.04189 14.3254C9.56889 14.6296 10.0065 15.0673 10.3108 15.5943C10.5041 15.9291 10.9322 16.0438 11.267 15.8505C11.6018 15.6572 11.7165 15.2291 11.5232 14.8943C11.0961 14.1544 10.4817 13.5401 9.74189 13.1129C9.00206 12.6858 8.16283 12.4609 7.30856 12.4609Z"
      fill="currentColor"
    />
    <path
      d="M10.0352 4H16.7018V9.83333L15.0352 8.16667H12.1185"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SalaryAnalyzer = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9.16537"
      cy="10.8333"
      r="5.83333"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <path
      d="M9.9987 3.83594C9.9987 3.55979 10.2229 3.33399 10.4982 3.35468C11.2027 3.40761 11.8953 3.57226 12.5499 3.84341C13.3588 4.17844 14.0937 4.6695 14.7127 5.28856C15.3318 5.90762 15.8229 6.64254 16.1579 7.45138C16.429 8.10599 16.5937 8.79863 16.6466 9.50307C16.6673 9.77844 16.4415 10.0026 16.1654 10.0026L10.4987 10.0026C10.2226 10.0026 9.9987 9.77875 9.9987 9.5026V3.83594Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
  </svg>
)

export const Apply4Me = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1654 7.5L10.4154 3.75V7C10.4154 7.27614 10.6392 7.5 10.9154 7.5H14.1654Z"
      fill="none"
    />
    <path
      d="M10.4154 3.75H6.33203C6.05589 3.75 5.83203 3.97386 5.83203 4.25V10M10.4154 3.75L14.1654 7.5M10.4154 3.75V7C10.4154 7.27614 10.6392 7.5 10.9154 7.5H14.1654M14.1654 7.5V15.75C14.1654 16.0261 13.9415 16.25 13.6654 16.25H11.6654"
      stroke="currentColor"
      strokeWidth="1.4"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33203 14.4423L5.83203 16.6645L11.2487 11.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ResumeDistribution = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1654 7.5L10.4154 3.75V7.08333C10.4154 7.31345 10.6019 7.5 10.832 7.5H14.1654Z"
      fill="none"
    />
    <path
      d="M10.4154 3.75H6.2487C6.01858 3.75 5.83203 3.93655 5.83203 4.16667V6.66667M10.4154 3.75L14.1654 7.5M10.4154 3.75V7.08333C10.4154 7.31345 10.6019 7.5 10.832 7.5H14.1654M14.1654 7.5V15.8333C14.1654 16.0635 13.9788 16.25 13.7487 16.25H9.9987"
      stroke="currentColor"
      strokeWidth="1.4"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33203 14.4418L5.83203 16.6641M5.83203 16.6641L8.33203 14.4418M5.83203 16.6641V9.16406"
      stroke="currentColor"
      strokeWidth="1.4"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const VirtualEvents = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 5C3 3.89543 3.89543 3 5 3L15 3C16.1046 3 17 3.89543 17 5V15C17 16.1046 16.1046 17 15 17H5C3.89543 17 3 16.1046 3 15L3 5Z"
      stroke="currentColor"
      strokeWidth="1.4"
      fill="none"
    />
    <circle cx="10.0211" cy="8.39004" r="2.1" fill="none" stroke="currentColor" strokeWidth="1.4" />
    <path
      d="M13.0523 14.3398C12.7451 13.8078 12.3033 13.3659 11.7712 13.0588C11.2392 12.7516 10.6356 12.5898 10.0212 12.5898C9.40686 12.5898 8.80331 12.7516 8.27124 13.0588C7.73917 13.3659 7.29734 13.8078 6.99015 14.3398"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      fill="none"
    />
  </svg>
)

export const JobSearch = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1891 9.18245C14.1891 11.9486 11.9467 14.1911 9.1805 14.1911C6.41431 14.1911 4.17188 11.9486 4.17188 9.18245C4.17188 6.41626 6.41431 4.17383 9.1805 4.17383C11.9467 4.17383 14.1891 6.41626 14.1891 9.18245Z"
      stroke="currentColor"
      strokeWidth="1.4"
      fill="none"
      strokeLinecap="round"
    />
    <path
      d="M14.1875 14.1909L15.6901 15.6935"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
)

export const Coaching = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_530_38585)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91583 7.51604C2.91583 6.01461 4.13298 4.79746 5.63441 4.79746C7.13584 4.79746 8.35298 6.01461 8.35298 7.51604C8.35298 9.01747 7.13584 10.2346 5.63441 10.2346C4.13298 10.2346 2.91583 9.01747 2.91583 7.51604ZM5.63441 3.39746C3.35978 3.39746 1.51583 5.24141 1.51583 7.51604C1.51583 9.79066 3.35978 11.6346 5.63441 11.6346C7.90903 11.6346 9.75298 9.79066 9.75298 7.51604C9.75298 5.24141 7.90903 3.39746 5.63441 3.39746ZM5.63441 13.6532C4.51139 13.6532 3.40815 13.9488 2.43559 14.5103C1.46303 15.0718 0.655408 15.8794 0.093899 16.852C-0.0994006 17.1868 0.0153122 17.6149 0.350117 17.8082C0.684922 18.0015 1.11304 17.8868 1.30633 17.552C1.74497 16.7923 2.37586 16.1614 3.13559 15.7227C3.89533 15.2841 4.75714 15.0532 5.63441 15.0532C6.51167 15.0532 7.37348 15.2841 8.13322 15.7227C8.89295 16.1614 9.52384 16.7923 9.96248 17.552C10.1558 17.8868 10.5839 18.0015 10.9187 17.8082C11.2535 17.6149 11.3682 17.1868 11.1749 16.852C10.6134 15.8794 9.80578 15.0718 8.83322 14.5103C7.86066 13.9488 6.75742 13.6532 5.63441 13.6532Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7166 5.4303C12.7166 4.30899 13.6256 3.4 14.7469 3.4C15.8682 3.4 16.7772 4.30899 16.7772 5.4303C16.7772 6.5516 15.8682 7.46059 14.7469 7.46059C13.6256 7.46059 12.7166 6.5516 12.7166 5.4303ZM14.7469 2C12.8524 2 11.3166 3.5358 11.3166 5.4303C11.3166 7.32479 12.8524 8.86059 14.7469 8.86059C16.6414 8.86059 18.1772 7.32479 18.1772 5.4303C18.1772 3.5358 16.6414 2 14.7469 2ZM14.7469 10.1909C13.8253 10.1909 12.9198 10.4335 12.1217 10.8943C11.3235 11.3552 10.6607 12.018 10.1999 12.8161C10.0066 13.1509 10.1213 13.5791 10.4561 13.7724C10.7909 13.9657 11.219 13.8509 11.4123 13.5161C11.7502 12.9308 12.2363 12.4447 12.8217 12.1068C13.407 11.7688 14.071 11.5909 14.7469 11.5909C15.4228 11.5909 16.0868 11.7688 16.6722 12.1068C17.2575 12.4447 17.7436 12.9308 18.0815 13.5161C18.2748 13.8509 18.703 13.9657 19.0378 13.7724C19.3726 13.5791 19.4873 13.1509 19.294 12.8161C18.8331 12.018 18.1703 11.3552 17.3722 10.8943C16.574 10.4335 15.6686 10.1909 14.7469 10.1909Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_530_38585">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const More = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16667 8.33073C5.08714 8.33073 5.83333 9.07692 5.83333 9.9974C5.83333 10.9179 5.08714 11.6641 4.16667 11.6641C3.24619 11.6641 2.5 10.9179 2.5 9.9974C2.5 9.07692 3.24619 8.33073 4.16667 8.33073ZM10 8.33073C10.9205 8.33073 11.6667 9.07692 11.6667 9.99739C11.6667 10.9179 10.9205 11.6641 10 11.6641C9.07952 11.6641 8.33333 10.9179 8.33333 9.99739C8.33333 9.07692 9.07952 8.33073 10 8.33073ZM17.5 9.99739C17.5 9.07692 16.7538 8.33073 15.8333 8.33073C14.9129 8.33073 14.1667 9.07692 14.1667 9.99739C14.1667 10.9179 14.9129 11.6641 15.8333 11.6641C16.7538 11.6641 17.5 10.9179 17.5 9.99739Z"
      fill="currentColor"
    />
  </svg>
)

export const DashboardActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_530_38572" fill="white">
      <rect x="4.18359" y="4.17969" width="5.01923" height="5.01923" rx="0.836538" />
    </mask>
    <rect
      x="4.18359"
      y="4.17969"
      width="5.01923"
      height="5.01923"
      rx="0.836538"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="2.8"
      mask="url(#path-1-inside-1_530_38572)"
    />
    <mask id="path-2-inside-2_530_38572" fill="white">
      <rect x="4.18359" y="10.875" width="5.01923" height="5.01923" rx="0.836538" />
    </mask>
    <rect
      x="4.18359"
      y="10.875"
      width="5.01923"
      height="5.01923"
      rx="0.836538"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="2.8"
      mask="url(#path-2-inside-2_530_38572)"
    />
    <mask id="path-3-inside-3_530_38572" fill="white">
      <rect x="10.875" y="4.17969" width="5.01923" height="5.01923" rx="0.836538" />
    </mask>
    <rect
      x="10.875"
      y="4.17969"
      width="5.01923"
      height="5.01923"
      rx="0.836538"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="2.8"
      mask="url(#path-3-inside-3_530_38572)"
    />
    <mask id="path-4-inside-4_530_38572" fill="white">
      <rect x="10.875" y="10.875" width="5.01923" height="5.01923" rx="0.836538" />
    </mask>
    <rect
      x="10.875"
      y="10.875"
      width="5.01923"
      height="5.01923"
      rx="0.836538"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="2.8"
      mask="url(#path-4-inside-4_530_38572)"
    />
  </svg>
)

export const DocumentsActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7487 16.6693H6.2487C6.01858 16.6693 5.83203 16.4827 5.83203 16.2526V10.8359V3.7526C5.83203 3.52249 6.01858 3.33594 6.2487 3.33594H9.9987L14.1654 7.5026V16.2526C14.1654 16.4827 13.9788 16.6693 13.7487 16.6693Z"
      fill="#1A91F0"
    />
    <path
      d="M9.9987 3.33594H6.2487C6.01858 3.33594 5.83203 3.52249 5.83203 3.7526V10.8359V16.2526C5.83203 16.4827 6.01858 16.6693 6.2487 16.6693H13.7487C13.9788 16.6693 14.1654 16.4827 14.1654 16.2526V7.5026M9.9987 3.33594L14.1654 7.5026M9.9987 3.33594V7.08594C9.9987 7.31606 10.1852 7.5026 10.4154 7.5026H14.1654"
      stroke="#1A91F0"
      fill="#1A91F0"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <path
      d="M10 7.08333V4L13.5 7.5H10.4167C10.1865 7.5 10 7.31345 10 7.08333Z"
      fill="#EAF6FF"
      stroke="#EAF6FF"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
  </svg>
)

export const JobsActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.7 8.20349C3.7 7.62258 4.17092 7.15166 4.75183 7.15166H15.2628C15.8437 7.15166 16.3146 7.62258 16.3146 8.20349V10.4984V14.3349C16.3146 14.9158 15.8437 15.3867 15.2628 15.3867H4.75183C4.17092 15.3867 3.7 14.9158 3.7 14.3349V10.4984V8.20349Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
    />
    <path d="M3 10.5H17" stroke="#EAF6FF" strokeWidth="1.8" strokeLinejoin="round" />
    <path
      d="M12.6344 6.45202V6.45202C12.6344 5.48451 11.8501 4.7002 10.8826 4.7002H9.13073C8.16323 4.7002 7.37891 5.48451 7.37891 6.45202V6.45202"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
)

export const JobTrackerActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="4.16797"
      y="4.16406"
      width="4.58333"
      height="5.83333"
      rx="0.5"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <rect
      x="11.25"
      y="10"
      width="4.58333"
      height="5.83333"
      rx="0.5"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <rect
      x="11.25"
      y="4.16406"
      width="4.58333"
      height="3.33333"
      rx="0.5"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <rect
      x="4.16797"
      y="12.5"
      width="4.58333"
      height="3.33333"
      rx="0.5"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
  </svg>
)

export const InterviewPrepActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M9.80981 8.16113C9.80981 9.54184 8.69053 10.6611 7.30981 10.6611C5.9291 10.6611 4.80981 9.54184 4.80981 8.16113C4.80981 6.78042 5.9291 5.66113 7.30981 5.66113C8.69053 5.66113 9.80981 6.78042 9.80981 8.16113Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
    />
    <path
      d="M9.96834 13.7223C10.4452 13.9379 10.8744 14.2091 11.2421 14.5253C11.5531 14.7927 11.3363 15.2474 10.9262 15.2474L3.68912 15.2474C3.27897 15.2474 3.06224 14.7927 3.37324 14.5253C3.74093 14.2091 4.17011 13.9379 4.64697 13.7223C5.45592 13.3566 6.37356 13.1641 7.30766 13.1641C8.24175 13.1641 9.15939 13.3566 9.96834 13.7223Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <path
      d="M16.7018 4H10.0352C10.0352 4 11.0819 5.12681 11.5 6C11.88 6.79364 12.1185 8.16667 12.1185 8.16667H15.0352L16.7018 9.83333V4Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SalaryAnalyzerActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle
      cx="9.16537"
      cy="10.8333"
      r="5.83333"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.5C10 2.22386 10.2241 1.99837 10.4997 2.01562C11.3793 2.07067 12.2451 2.2708 13.0615 2.60896C14.0321 3.011 14.914 3.60028 15.6569 4.34315C16.3997 5.08602 16.989 5.96793 17.391 6.93853C17.7292 7.75494 17.9293 8.62066 17.9844 9.50032C18.0016 9.77593 17.7761 10 17.5 10L10.5 10C10.2239 10 10 9.77614 10 9.5V2.5Z"
      fill="#1A91F0"
      stroke="#EAF6FF"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
  </svg>
)

export const Apply4MeActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M13.7487 16.6694H6.2487C6.01858 16.6694 5.83203 16.4828 5.83203 16.2527V10.836V4.16178C5.83203 3.93166 6.01858 3.74512 6.2487 3.74512H9.9987L14.1654 8.00293V16.2527C14.1654 16.4828 13.9788 16.6694 13.7487 16.6694Z"
      fill="#1A91F0"
    />
    <path
      d="M9.9987 3.74512H6.2487C6.01858 3.74512 5.83203 3.93166 5.83203 4.16178V10.836V16.2527C5.83203 16.4828 6.01858 16.6694 6.2487 16.6694H13.7487C13.9788 16.6694 14.1654 16.4828 14.1654 16.2527V8.00293M9.9987 3.74512L14.1654 8.00293M9.9987 3.74512V7.58626C9.9987 7.81638 10.1852 8.00293 10.4154 8.00293H14.1654"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <path
      d="M3.33203 14.4423L5.83203 16.6645L11.2487 11.25"
      stroke="#EAF6FF"
      strokeWidth="4.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33203 14.4423L5.83203 16.6645L11.2487 11.25"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ResumeDistributionActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M6.2487 3.75H10.4154L14.1654 7.5V15.8333C14.1654 16.0635 13.9788 16.25 13.7487 16.25H9.9987H6.2487C6.01858 16.25 5.83203 16.0635 5.83203 15.8333V6.66667V4.16667C5.83203 3.93655 6.01858 3.75 6.2487 3.75Z"
      fill="#1A91F0"
    />
    <path
      d="M10.4154 3.75H6.2487C6.01858 3.75 5.83203 3.93655 5.83203 4.16667V6.66667V15.8333C5.83203 16.0635 6.01858 16.25 6.2487 16.25H9.9987H13.7487C13.9788 16.25 14.1654 16.0635 14.1654 15.8333V7.5M10.4154 3.75L14.1654 7.5M10.4154 3.75V7.08333C10.4154 7.31345 10.6019 7.5 10.832 7.5H14.1654"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33203 14.4418L5.83203 16.6641M5.83203 16.6641L8.33203 14.4418M5.83203 16.6641V9.16406"
      stroke="#EAF6FF"
      strokeWidth="4.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33203 14.4418L5.83203 16.6641M5.83203 16.6641L8.33203 14.4418M5.83203 16.6641V9.16406"
      stroke="#1A91F0"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const VirtualEventsActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M3 5C3 3.89543 3.89543 3 5 3L15 3C16.1046 3 17 3.89543 17 5V15C17 16.1046 16.1046 17 15 17H5C3.89543 17 3 16.1046 3 15L3 5Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
    />
    <circle cx="10.0211" cy="8.39004" r="2.1" fill="#EAF6FF" stroke="#EAF6FF" strokeWidth="1.4" />
    <mask id="path-3-inside-1_530_38567" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3367 14.3911C6.20516 14.6901 6.44425 15 6.77096 15H13.229C13.5557 15 13.7948 14.6901 13.6632 14.3911C13.0439 12.9831 11.6368 12 9.99996 12C8.36316 12 6.95598 12.9831 6.3367 14.3911Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3367 14.3911C6.20516 14.6901 6.44425 15 6.77096 15H13.229C13.5557 15 13.7948 14.6901 13.6632 14.3911C13.0439 12.9831 11.6368 12 9.99996 12C8.36316 12 6.95598 12.9831 6.3367 14.3911Z"
      fill="#EAF6FF"
    />
    <path
      d="M6.77096 16H13.229V14H6.77096V16ZM9.99996 13C11.226 13 12.2825 13.7356 12.7478 14.7937L14.5786 13.9885C13.8054 12.2306 12.0475 11 9.99996 11V13ZM7.25207 14.7937C7.71746 13.7356 8.77391 13 9.99996 13V11C7.95241 11 6.1945 12.2306 5.42133 13.9885L7.25207 14.7937ZM13.229 16C14.1431 16 15.0531 15.0673 14.5786 13.9885L12.7478 14.7937C12.6594 14.5925 12.7003 14.3682 12.8113 14.2166C12.9144 14.076 13.0717 14 13.229 14V16ZM6.77096 14C6.92823 14 7.08555 14.076 7.18857 14.2166C7.29967 14.3682 7.34056 14.5925 7.25207 14.7937L5.42133 13.9885C4.9468 15.0674 5.85685 16 6.77096 16V14Z"
      fill="#EAF6FF"
      mask="url(#path-3-inside-1_530_38567)"
    />
  </svg>
)

export const JobSearchActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M14.1891 9.18245C14.1891 11.9486 11.9467 14.1911 9.1805 14.1911C6.41431 14.1911 4.17188 11.9486 4.17188 9.18245C4.17188 6.41626 6.41431 4.17383 9.1805 4.17383C11.9467 4.17383 14.1891 6.41626 14.1891 9.18245Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <path
      d="M7.82852 7.82843C7.04738 8.60938 6.09759 8.92585 5.70712 8.53528C5.31664 8.14471 5.63333 7.195 6.41448 6.41405C7.19562 5.63309 8.1454 5.31662 8.53588 5.70719C8.92636 6.09776 8.60966 7.04747 7.82852 7.82843Z"
      fill="#EAF6FF"
      stroke="#EAF6FF"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <path
      d="M14.1875 14.1909L15.6901 15.6935"
      stroke="#1A91F0"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
)

export const CoachingActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <circle
      cx="6.6344"
      cy="7.51623"
      r="3.41858"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
    />
    <path
      d="M9.93084 15.1832C10.663 15.5825 11.3042 16.1099 11.8219 16.7345C12.0711 17.0351 11.8389 17.4592 11.4484 17.4592L1.83147 17.4592C1.44101 17.4592 1.20876 17.0351 1.45794 16.7345C1.97564 16.1099 2.61692 15.5825 3.34904 15.1832C4.3496 14.6374 5.48459 14.3501 6.63994 14.3501C7.79529 14.3501 8.93028 14.6374 9.93084 15.1832Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M18.3769 11.5551C18.9072 11.8444 19.3778 12.2179 19.7692 12.6571C20.029 12.9486 19.7989 13.372 19.4084 13.372L12.0915 13.372C11.701 13.372 11.4709 12.9486 11.7307 12.6571C12.1221 12.2179 12.5927 11.8444 13.123 11.5551C13.9217 11.1195 14.8277 10.8901 15.75 10.8901C16.6722 10.8901 17.5782 11.1195 18.3769 11.5551Z"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <circle
      cx="15.7469"
      cy="5.43049"
      r="2.7303"
      fill="#1A91F0"
      stroke="#1A91F0"
      strokeWidth="1.4"
    />
  </svg>
)

export const MoreActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16667 8.33073C5.08714 8.33073 5.83333 9.07692 5.83333 9.9974C5.83333 10.9179 5.08714 11.6641 4.16667 11.6641C3.24619 11.6641 2.5 10.9179 2.5 9.9974C2.5 9.07692 3.24619 8.33073 4.16667 8.33073ZM10 8.33073C10.9205 8.33073 11.6667 9.07692 11.6667 9.99739C11.6667 10.9179 10.9205 11.6641 10 11.6641C9.07952 11.6641 8.33333 10.9179 8.33333 9.99739C8.33333 9.07692 9.07952 8.33073 10 8.33073ZM17.5 9.99739C17.5 9.07692 16.7538 8.33073 15.8333 8.33073C14.9129 8.33073 14.1667 9.07692 14.1667 9.99739C14.1667 10.9179 14.9129 11.6641 15.8333 11.6641C16.7538 11.6641 17.5 10.9179 17.5 9.99739Z"
      fill="#1A91F0"
    />
  </svg>
)

export const Chrome = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9507 6.25C7.90675 6.25 6.25 7.90829 6.25 9.95385C6.25 11.9994 7.90675 13.6579 9.9507 13.6579C11.9942 13.6579 13.6512 11.9994 13.6512 9.95385C13.6512 7.90829 11.9942 6.25 9.9507 6.25Z"
      fill="#4B81E8"
    />
    <path
      d="M0 10.0158C0 7.91228 0.648357 5.96028 1.756 4.34912L5.26125 11.6165C5.92821 13.5955 7.79787 15.0207 10.0002 15.0206C10.6642 15.0206 11.298 14.8911 11.8777 14.6558L9.30619 20.0014C4.10674 19.6447 0 15.3098 0 10.0158Z"
      fill="#539F55"
    />
    <path
      d="M13.3072 6.26318H19.2731C19.7418 7.42219 19.9999 8.68907 19.9999 10.0163C19.9999 15.3108 15.8923 19.6455 10.6934 20.0019L14.1631 12.789C14.6918 11.9953 15 11.0419 15 10.0165C15 8.5218 14.3452 7.18018 13.3072 6.26318Z"
      fill="#F1BF46"
    />
    <path
      d="M5.20037 8.60418L2.62036 3.25506C4.44879 1.25463 7.07841 0 10.001 0C13.702 0 16.9338 2.01285 18.6629 5.00457H10.0009C7.72659 5.00463 5.80698 6.52422 5.20037 8.60418Z"
      fill="#D65241"
    />
  </svg>
)

export const RejectIcon = () => (
  <>
    <path
      fill="#BEC4D5"
      fillRule="evenodd"
      d="M13.673 14.662a.7.7 0 0 0 .99-.99L10.99 9.999l3.67-3.67a.7.7 0 1 0-.99-.99L10 9.008l-3.67-3.67a.7.7 0 1 0-.99.99l3.67 3.67-3.673 3.673a.7.7 0 1 0 .99.99L10 10.989l3.673 3.673Z"
      clipRule="evenodd"
    />
  </>
)

export const TRLogo = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7276 10.5902C19.1785 9.86542 19.41 9.02526 19.3938 8.17147C19.3938 6.71019 18.8907 5.39406 17.7728 4.52734C17.0355 3.94952 15.7364 3.57129 13.6471 3.57129H9.28577V16.9224H12.6923V6.56643H13.9467C14.4353 6.56531 14.9157 6.69183 15.3406 6.9335C15.5923 7.10471 15.7969 7.33663 15.9355 7.60787C16.0741 7.87911 16.1422 8.18094 16.1337 8.4855C16.1337 9.22522 15.7908 9.85747 15.3768 10.1268C14.9252 10.4157 14.1879 10.4883 13.3223 10.4883H12.7648L16.2493 16.9294H19.7632L16.9978 12.0152C17.7042 11.7248 18.3069 11.2283 18.7276 10.5902Z"
      fill="#1A91F0"
    />
    <path
      d="M8.63617 3.58398H0V6.57494H2.62598V16.9156H6.02692V6.57494H8.63617V3.58398Z"
      fill="#0E0F47"
    />
  </svg>
)

export const Tips = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 10C19 5.03368 14.9663 1 10 1C5.03368 1 1 5.03368 1 10C1 14.9663 5.03368 19 10 19C14.9663 19 19 14.9663 19 10ZM9.95337 7.34197C9.72021 7.31865 9.48705 7.41192 9.30052 7.57513C9.13731 7.73834 9.1 7.9715 9.1 8.20466H7.3C7.3 7.43523 7.36528 6.68912 7.92487 6.15285C8.48446 5.61658 9.23057 5.33679 10.0233 5.3601C11.4689 5.43005 12.658 6.61917 12.728 8.06477C12.7746 9.2772 12.0751 10.3964 10.9326 10.8394C10.9093 10.8627 10.8627 10.9093 10.8627 10.9793V11.8H8.88083V10.9793C8.88083 10.0933 9.39378 9.32383 10.1865 9.02073C10.5363 8.88083 10.7461 8.53109 10.7228 8.15803C10.7461 7.73834 10.3731 7.36528 9.95337 7.34197ZM10 14.95C10.6213 14.95 11.125 14.4463 11.125 13.825C11.125 13.2036 10.6213 12.7 10 12.7C9.37868 12.7 8.875 13.2036 8.875 13.825C8.875 14.4463 9.37868 14.95 10 14.95Z"
    />
  </svg>
)

export const Expand = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 14H2L2 12L8 12L8 18H6L6 14ZM12 8L12 2L14 2L14 6L18 6V8L12 8Z"
      fill="#828BA2"
    />
  </svg>
)

export const Check = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      d="M15.9 4.886c-.076.032-1.566 1.499-4.308 4.24L7.4 13.316l-1.592-1.59c-1.095-1.095-1.63-1.606-1.715-1.641a.59.59 0 0 0-.775.774c.071.172 3.644 3.755 3.832 3.844a.557.557 0 0 0 .5 0c.19-.09 8.961-8.871 9.032-9.044a.546.546 0 0 0 0-.451.532.532 0 0 0-.289-.312.589.589 0 0 0-.493-.01"
      fillRule="evenodd"
    />
  </svg>
)

export const Mute = () => (
  <svg width="15" height="15" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 7.25195V4.75195C1.5 4.19967 1.94772 3.75195 2.5 3.75195H5.04673L7.7302 0.914689C8.04092 0.586157 8.59346 0.806063 8.59346 1.25826V10.7456C8.59346 11.1978 8.04092 11.4178 7.7302 11.0892L5.04673 8.25195H2.5C1.94772 8.25195 1.5 7.80424 1.5 7.25195Z"
      stroke="#828BA2"
      strokeWidth="1.3"
      fill="none"
    />
    <rect
      x="0.783203"
      y="2.2168"
      width="1.3066"
      height="16"
      rx="0.653299"
      transform="rotate(-45 0.783203 2.2168)"
      fill="#828BA2"
    />
    <rect
      x="1.70898"
      y="1.29297"
      width="1.30925"
      height="16"
      rx="0.654626"
      transform="rotate(-45 1.70898 1.29297)"
      fill="white"
    />
  </svg>
)

export const SuccessIcon = () => (
  <>
    <path fill="#339D5D" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.097 5.071 12.5 6.5l-4.246 4.613c-.478.525-.906.507-1.403 0L4 8.5l1.403-1.429 2.15 1.9 3.544-3.9Z"
      clipRule="evenodd"
    />
  </>
)

export const QuestionIcon = () => (
  <>
    <path
      fill="#828BA2"
      fillRule="evenodd"
      d="M12.248 9.473c.629-.614 1.252-1.223 1.252-2.43C13.5 5.304 12.187 4 10 4 7.987 4 6.588 5.391 6.5 7.565h2.1c0-1.043.613-1.652 1.4-1.652.875 0 1.4.522 1.4 1.304 0 .644-.414 1.022-.901 1.469C9.8 9.326 8.95 10.105 8.95 12h2.013c0-1.272.645-1.902 1.285-2.527ZM10 13.25c-.673 0-1.25.577-1.25 1.25s.577 1.25 1.25 1.25 1.25-.577 1.25-1.25-.577-1.25-1.25-1.25Z"
      clipRule="evenodd"
    />
  </>
)

export const ArrowTarget2 = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'none' }}
  >
    <path
      d="M9.99804 9.99804L11.5352 8.46085M11.5352 8.46085L11.3748 6.16125C11.3546 5.87251 11.4606 5.58921 11.6653 5.38454L14.4175 2.63235L14.3534 5.64267L17.3637 5.57862L14.6115 8.33082C14.4069 8.53549 14.1236 8.64143 13.8348 8.62129L11.5352 8.46085Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.76393 6.19577C8.1029 6.41056 7.50925 6.79387 7.04148 7.30795C6.5737 7.82203 6.24795 8.44912 6.09633 9.12743C5.94471 9.80573 5.97246 10.5118 6.17683 11.1762C6.3812 11.8405 6.75515 12.4401 7.26181 12.9159C7.76848 13.3917 8.39038 13.7272 9.06622 13.8895C9.74206 14.0517 10.4485 14.0351 11.116 13.8412C11.7834 13.6473 12.3888 13.2828 12.8725 12.7837C13.3562 12.2845 13.7015 11.668 13.8743 10.9948"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Complitness = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1804 7.71656C16.4803 8.44047 16.6346 9.21635 16.6346 9.99991C16.6346 10.7835 16.4803 11.5593 16.1804 12.2833C15.8806 13.0072 15.4411 13.6649 14.887 14.219C14.3329 14.773 13.6752 15.2125 12.9513 15.5124C12.2274 15.8122 11.4515 15.9666 10.6679 15.9666C9.88438 15.9666 9.1085 15.8122 8.38459 15.5124C7.66068 15.2125 7.00292 14.773 6.44886 14.219C5.89481 13.6649 5.45531 13.0072 5.15545 12.2832C4.8556 11.5593 4.70127 10.7835 4.70127 9.99991C4.70127 9.21635 4.8556 8.44047 5.15545 7.71656C5.45531 6.99265 5.89481 6.33489 6.44887 5.78084C7.00292 5.22678 7.66068 4.78728 8.38459 4.48742C9.1085 4.18757 9.88438 4.03324 10.6679 4.03324C11.4515 4.03324 12.2274 4.18757 12.9513 4.48743C13.6752 4.78728 14.333 5.22678 14.887 5.78084C15.4411 6.33489 15.8806 6.99265 16.1804 7.71656ZM18.0346 9.99991C18.0346 9.0325 17.8441 8.07457 17.4738 7.18081C17.1036 6.28704 16.561 5.47495 15.877 4.79089C15.1929 4.10683 14.3808 3.5642 13.487 3.19399C12.5933 2.82378 11.6353 2.63324 10.6679 2.63324C9.70053 2.63324 8.7426 2.82378 7.84883 3.19399C6.95507 3.5642 6.14297 4.10683 5.45892 4.79089C4.77486 5.47494 4.23223 6.28704 3.86202 7.1808C3.49181 8.07457 3.30127 9.0325 3.30127 9.99991C3.30127 10.9673 3.49181 11.9252 3.86202 12.819C4.23223 13.7128 4.77486 14.5249 5.45892 15.2089C6.14297 15.893 6.95507 16.4356 7.84883 16.8058C8.7426 17.176 9.70053 17.3666 10.6679 17.3666C11.6353 17.3666 12.5933 17.176 13.487 16.8058C14.3808 16.4356 15.1929 15.893 15.877 15.2089C16.561 14.5249 17.1036 13.7128 17.4738 12.819C17.8441 11.9252 18.0346 10.9673 18.0346 9.99991ZM9.39041 12.1616C9.66378 12.435 10.107 12.435 10.3804 12.1616L13.4104 9.13155C13.6838 8.85818 13.6838 8.41497 13.4104 8.1416C13.137 7.86823 12.6938 7.86823 12.4204 8.1416L9.88538 10.6767L8.66293 9.45421C8.38957 9.18085 7.94635 9.18085 7.67298 9.45421C7.39962 9.72758 7.39962 10.1708 7.67298 10.4442L9.39041 12.1616Z"
      fill="currentColor"
    />
  </svg>
)

export const Processing = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5212 16.7048C9.64369 16.7048 8.7748 16.532 7.9641 16.1962C7.15341 15.8604 6.4168 15.3682 5.79632 14.7477C5.17585 14.1273 4.68366 13.3907 4.34786 12.58C4.01206 11.7693 3.83923 10.9004 3.83923 10.0229C3.83923 9.1454 4.01206 8.27651 4.34786 7.46582C4.68366 6.65513 5.17585 5.91852 5.79633 5.29804C6.4168 4.67756 7.15341 4.18537 7.96411 3.84957C8.7748 3.51378 9.64369 3.34094 10.5212 3.34094C11.3987 3.34094 12.2676 3.51378 13.0782 3.84958C13.8889 4.18537 14.6256 4.67756 15.246 5.29804C15.8665 5.91852 16.3587 6.65513 16.6945 7.46582C17.0303 8.27651 17.2031 9.14541 17.2031 10.0229C17.2031 10.9004 17.0303 11.7693 16.6945 12.58C16.3587 13.3907 15.8665 14.1273 15.246 14.7477C14.6255 15.3682 13.8889 15.8604 13.0782 16.1962C12.2676 16.532 11.3987 16.7048 10.5212 16.7048L10.5212 16.7048Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
    />
    <path
      d="M12.6115 11.6935L10.5234 10.4406L10.5234 6.68201"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Stop = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3502 16.6954C9.47317 16.6954 8.60477 16.5226 7.79454 16.187C6.98431 15.8514 6.24811 15.3595 5.62799 14.7394C5.00786 14.1193 4.51595 13.3831 4.18034 12.5728C3.84473 11.7626 3.672 10.8942 3.672 10.0172C3.672 9.14022 3.84473 8.27182 4.18034 7.46159C4.51595 6.65136 5.00786 5.91516 5.62799 5.29504C6.24811 4.67491 6.98431 4.183 7.79454 3.84739C8.60477 3.51179 9.47317 3.33905 10.3502 3.33905C11.2271 3.33905 12.0955 3.51179 12.9058 3.8474C13.716 4.183 14.4522 4.67491 15.0723 5.29504C15.6925 5.91516 16.1844 6.65136 16.52 7.46159C16.8556 8.27182 17.0283 9.14022 17.0283 10.0172C17.0283 10.8942 16.8556 11.7626 16.52 12.5728C16.1844 13.3831 15.6925 14.1193 15.0723 14.7394C14.4522 15.3595 13.716 15.8514 12.9058 16.187C12.0955 16.5226 11.2271 16.6954 10.3502 16.6954L10.3502 16.6954Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
    />
    <path
      d="M7.33301 10.1241L13.5813 10.1241"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Delete = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.21152 5.89502V4.79062H11.8201V5.89502H8.21152ZM6.81152 5.89502V4.50801C6.81152 3.8909 7.31179 3.39062 7.92891 3.39062H12.1028C12.7199 3.39062 13.2201 3.8909 13.2201 4.50801V5.89502H15.0249C15.4115 5.89502 15.7249 6.20842 15.7249 6.59502C15.7249 6.98162 15.4115 7.29502 15.0249 7.29502H14.2374L13.5508 15.4538C13.5021 16.0326 13.0181 16.4775 12.4373 16.4775H7.59515C7.01436 16.4775 6.5304 16.0326 6.4817 15.4538L5.79509 7.29502H5.00762C4.62102 7.29502 4.30762 6.98162 4.30762 6.59502C4.30762 6.20842 4.62102 5.89502 5.00762 5.89502H6.81152ZM7.85498 15.0775L7.20004 7.29502H12.8324L12.1775 15.0775H7.85498Z"
      fill="#DA0C22"
    />
  </svg>
)

export const Job = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07891 5.36491C8.07891 4.784 8.54983 4.31309 9.13073 4.31309H10.8826C11.4635 4.31309 11.9344 4.784 11.9344 5.36491L4.75183 5.36492C3.78432 5.36492 3 6.14923 3 7.11674V8.71164V9.41164V10.1116V13.2481C3 14.2156 3.78432 15 4.75183 15H15.2628C16.2303 15 17.0146 14.2156 17.0146 13.2481V10.1116V9.41164V8.71164V7.11674C17.0146 6.14924 16.2303 5.36492 15.2628 5.36492L13.3344 5.36491C13.3344 4.01081 12.2367 2.91309 10.8826 2.91309H9.13073C7.77663 2.91309 6.67891 4.01081 6.67891 5.36491H8.07891ZM15.6146 8.71164H4.4V7.11674C4.4 6.92243 4.55752 6.76492 4.75183 6.76492H15.2628C15.4571 6.76492 15.6146 6.92243 15.6146 7.11674V8.71164ZM15.6146 13.2481V10.1116H4.4V13.2481C4.4 13.4424 4.55752 13.6 4.75183 13.6H15.2628C15.4571 13.6 15.6146 13.4424 15.6146 13.2481Z"
      fill="#27241D"
    />
  </svg>
)
export const Like = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14341_27987)">
      <mask id="path-1-inside-1_14341_27987" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5147 11.0476L10.8684 15.6939C10.2164 16.3458 9.15932 16.3458 8.50733 15.6939L3.78516 10.9717L3.78613 10.9707C2.15731 9.34062 2.15769 6.69875 3.7873 5.06915C5.41722 3.43922 8.05982 3.43916 9.68983 5.06896C11.3198 3.43916 13.9624 3.43922 15.5924 5.06915C17.2223 6.69914 17.2223 9.34188 15.5924 10.9719C15.5667 10.9975 15.5408 11.0227 15.5147 11.0476Z"
        />
      </mask>
      <path
        d="M15.5147 11.0476L14.5503 10.0327L14.5374 10.045L14.5248 10.0576L15.5147 11.0476ZM3.78516 10.9717L2.79521 9.98173L1.80526 10.9717L2.79521 11.9616L3.78516 10.9717ZM3.78613 10.9707L4.77608 11.9607L5.76565 10.9711L4.77646 9.98114L3.78613 10.9707ZM9.68983 5.06896L8.69994 6.05897L9.68983 7.04874L10.6797 6.05897L9.68983 5.06896ZM14.5248 10.0576L9.87847 14.7039L11.8584 16.6838L16.5047 12.0375L14.5248 10.0576ZM9.87847 14.7039C9.7732 14.8092 9.60254 14.8092 9.49728 14.7039L7.51738 16.6838C8.71611 17.8825 10.6596 17.8825 11.8584 16.6838L9.87847 14.7039ZM9.49728 14.7039L4.77511 9.98173L2.79521 11.9616L7.51738 16.6838L9.49728 14.7039ZM4.77511 11.9616L4.77608 11.9607L2.79618 9.98075L2.79521 9.98173L4.77511 11.9616ZM4.77646 9.98114C3.69399 8.89783 3.69425 7.1421 4.77724 6.0591L2.79735 4.0792C0.621138 6.25541 0.620622 9.78341 2.7958 11.9603L4.77646 9.98114ZM4.77724 6.0591C5.86046 4.97588 7.61667 4.97584 8.69994 6.05897L10.6797 4.07895C8.50297 1.90248 4.97399 1.90256 2.79735 4.0792L4.77724 6.0591ZM10.6797 6.05897C11.763 4.97584 13.5192 4.97589 14.6024 6.0591L16.5823 4.0792C14.4057 1.90256 10.8767 1.90248 8.69994 4.07895L10.6797 6.05897ZM14.6024 6.0591C15.6857 7.14236 15.6857 8.89866 14.6024 9.98192L16.5823 11.9618C18.759 9.78509 18.759 6.25593 16.5823 4.0792L14.6024 6.0591ZM14.6024 9.98192C14.5852 9.99913 14.5678 10.0161 14.5503 10.0327L16.4791 12.0624C16.5138 12.0294 16.5482 11.9959 16.5823 11.9618L14.6024 9.98192Z"
        fill="#0C264C"
        mask="url(#path-1-inside-1_14341_27987)"
      />
    </g>
    <defs>
      <clipPath id="clip0_14341_27987">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const Near = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="10"
      cy="10"
      r="5"
      stroke="#1E2532"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="10.0002" cy="9.99967" r="0.833333" stroke="#1E2532" strokeWidth="1.66667" />
    <path
      d="M15 10H16.6667"
      stroke="#1E2532"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.3335 10H5.00016"
      stroke="#1E2532"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 5V3.33333"
      stroke="#1E2532"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 16.667V15.0003"
      stroke="#1E2532"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
