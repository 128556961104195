import { ReactNode } from 'react'
import { CustomDrawer, DrawerContainer } from './styles'
interface IProps {
  onClose: () => void
  children?: ReactNode
}

export const CardDrawer = ({ children }: IProps) => {
  return (
    <DrawerContainer>
      <CustomDrawer>{children}</CustomDrawer>
    </DrawerContainer>
  )
}
