import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'builder/components/Providers/Providers'
import { baseClient } from 'builder/modules/apiClient'

interface Params {
  questionId: number
  mimeTypeForAudio: string
  mimeTypeForVideo: string
}
export const useMutationInterviewAnswerQuestion = () => {
  return useMutation({
    mutationFn: async ({ questionId, mimeTypeForAudio, mimeTypeForVideo }: Params) => {
      const response = await baseClient.post(`/interview_prep/questions/${questionId}/answer`, {
        audio_content_type: mimeTypeForAudio,
        video_content_type: mimeTypeForVideo,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['useQuestionTemplate'])
    },
  })
}
