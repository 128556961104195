/* eslint-disable max-len */
import { useCallback, useMemo, useState, useEffect } from 'react'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { Attachment, ExpertDoc } from 'packages/types'
import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'

import {
  DocumentTypes as TYPES,
  FORMATS,
  FetchStatuses,
  DocumentTypes,
} from 'builder/modules/constants'
import {
  actions,
  BriefResume,
  BriefLetter,
  typeToSlug,
  typeToName,
  BriefExpertDoc,
  selectors as PanelSelectors,
  AIRefreshStatus,
} from 'builder/modules/panel'
import {
  DownloadTRDocumentStorageKeys,
  DownloadWhenPremium,
  actions as renderingActions,
} from 'builder/modules/rendering'
import { actions as uiActions } from 'builder/modules/ui'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { selectors as userSelectors } from 'builder/modules/user'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import DocumentName from 'builder/components/DocumentName'
import ActionMenu, { Action } from 'builder/components/ActionMenu'
import Icon from 'builder/components/Icon'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'
import EmploymentImg from 'builder/components/DocumentEditor/JobPosting/Images/CompanyLogo.svg'
import { CompanyLogo } from 'builder/components/DocumentEditor/JobPosting/DataContainer/Views/Result/styles'
import BlurCard from 'builder/components/CIO-Dasboard-2.0/Components/ServiceCard/Components/BlurCard'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { ConfigScopesEnum } from 'builder/modules/init'
import { ItemBadge } from 'builder/components/ActionMenu/styles'
import { getUpdatedTemplateAndFormat } from 'builder/utils/getTemplates'
import { billingPlanPageURL } from 'builder/utils/goToBillingPlanPage'
import { DocumentReviewStatus } from './DocumentReviewStatus'
import { DeletePrompt } from './DeletePrompt'
import { formatUpdatedAt } from './utils'
import DocThumbnailLoader from './DocumentThumbnail/DocThumbnail.svg'
import { DESKTOP_PREVIEW_WIDTH, PHONE_PREVIEW_WIDTH } from './constants'
import {
  DocumentScore,
  EmploymentWrapper,
  Percentile,
  SectionWrapper,
  Title,
  LockIcon,
} from './CreateDocumentPopup/styles'
import {
  DocumentContainer,
  DocumentPreviewContainer,
  DocumentPreview,
  DocumentContent,
  DocumentNote,
  DocumentButton,
  DocumentButtonIcon,
  DocumentSecondaryActions,
} from './styles'
import { TailorToResume } from './TailorToResume/TailorToResume'
import { AiRefresher } from './AiRefresher'
import { ResumeRefresher } from './AiRefresher/ResumeRefresher'
import { useResumeStatusPolling } from './AiRefresher/hooks/useResumeStatusPolling'

// react-flip-move package requires children to be wrapped into "React.forwardRef"
type Ref = React.ForwardedRef<HTMLDivElement>

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  document: BriefResume | BriefLetter | BriefExpertDoc
  isPostPremium: boolean
  isFirstDocumentFree: boolean
}

export const Document = React.forwardRef(
  ({ isPostPremium, isFirstDocumentFree, document, ...rest }: Props, ref: Ref) => {
    const dispatch = useDispatch()
    const [query] = useSearchParams()
    const isDownloading = useTypedSelector(state => state.rendering.isDocumentDownloading)
    const [isMenuOpen, toggleMenu] = useState(false)
    const [isDeletePromptOpen, toggleDeletePrompt] = useState(false)
    const { panel } = useAppSelector(store => store)
    const { trDocuments, trDocumentsStatus } = panel
    const { i18n } = useI18n()
    const { isPhone } = useMediaQueries()
    const config = useConfig()
    const countryConfig = useConfig(ConfigScopesEnum.country)
    const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
    const isSuperApp = config?.features.superApp
    const isPremiumUser = useTypedSelector(userSelectors.premiumAccess)
    const isSharingEnabled = (config?.features.shareResume ?? false) && isPremiumUser
    const isResumeOptimizerEnabled = config?.features.resumeOptimizer
    const { id, type, template, resumeScore, averageScore, jobTitle, employerName } = document
    const aiTaskId = type === DocumentTypes.resume ? (document as BriefResume).aiTaskId : null
    const jobPostingId =
      type === DocumentTypes.resume ? (document as BriefResume).jobPostingId : null
    const LinkWrapper = Link as React.ElementType<{ to: string | false }>

    const [trDocument, setTrDocument] = useState<ExpertDoc | null>(null)
    const [trDocumentStatus, setTrDocumentStatus] = useState<FetchStatuses | null>(null)

    const isTailoredResume = isResumeOptimizerEnabled && jobPostingId
    const dashboardScore = isTailoredResume ? averageScore : resumeScore
    const [isHovering, setIsHovering] = useState(false)
    const upgradeUrl = billingPlanPageURL({
      upgradeURL: useTypedSelector(userSelectors.getUpgradeUrl),
    })

    useResumeStatusPolling(id)
    const aiJob = useTypedSelector(PanelSelectors.aiJobStatusById(id))
    const isAiJobActive = aiJob.status && aiJob.status !== null
    const isAiRefresherPending = aiJob?.status === AIRefreshStatus.PENDING
    const isAiRefresherSuccess = aiJob?.status === AIRefreshStatus.SUCCESS
    const disableButtonsOnAiPending = isAiJobActive && !isAiRefresherSuccess

    const tailoredDesignation =
      employerName && employerName === null
        ? jobTitle
        : [jobTitle, employerName].join(' ' + i18n.t('builder.resume_editor.labels.at') + ' ')

    const previewUrl = formatDocumentPreviewUrl(document, {
      size: isPhone ? PHONE_PREVIEW_WIDTH : DESKTOP_PREVIEW_WIDTH,
    })

    const isResume = (documentType: string) => {
      return documentType === TYPES.resume
    }

    const isTypeTrDoc = (documentType: string) => {
      return documentType === TYPES.trDocument
    }

    const previewTrDocUrl = `/${typeToSlug(type)}/${id}/`
    const editorUrl = isTypeTrDoc(type)
      ? `/${typeToSlug(type)}/${id}`
      : `/${typeToSlug(type)}/${id}/edit`

    useEffect(() => {
      if (!trDocument && trDocuments[id]) {
        setTrDocument({ ...trDocuments[id] })
      }
    }, [trDocument, trDocuments, id])

    useEffect(() => {
      setTrDocumentStatus(trDocumentsStatus[id])
    }, [trDocumentsStatus, id])

    // Download TRDocuments when we have the data after the payment and clean session storage
    useEffect(() => {
      const TRDocumentToDownload = sessionStorage.getItem(
        DownloadTRDocumentStorageKeys.DOWNLOAD_WHEN_PREMIUM,
      ) as DownloadWhenPremium

      if (trDocument && trDocumentStatus === FetchStatuses.loaded && TRDocumentToDownload) {
        const trDocDownloadURL = trDocument?.files?.[0]?.url
        const trDocDownloadTYPE = trDocument?.files?.[0]?.content_type
        if (trDocDownloadURL) {
          dispatch(
            renderingActions.download({
              id: Number(trDocument?.id),
              type: trDocument?.doc_type as DocumentTypes,
              format: trDocDownloadTYPE.endsWith('pdf') ? FORMATS.tr_pdf : FORMATS.tr_doc,
              source: 'tr_doc',
              trDocFiles: trDocument?.files ?? [],
            }),
          )
          sessionStorage.removeItem(DownloadTRDocumentStorageKeys.DOWNLOAD_WHEN_PREMIUM)
          sessionStorage.removeItem(DownloadTRDocumentStorageKeys.TR_DOC_ID)
        }
      }
    }, [dispatch, trDocument, trDocumentStatus])

    const handleRename = useCallback(
      (name: string) => {
        if (isTypeTrDoc(type)) {
          dispatch(actions.renameTrDocument({ id, type, title: name, slug: name }))
        } else {
          dispatch(actions.renameDocument({ id, type, name }))
        }
        trackInternalEvent(`rename_${type}`)
      },
      [dispatch, id, type],
    )

    const handleCopy = useCallback(() => {
      dispatch(actions.copyDocument({ id, type }))
      trackInternalEvent(`duplicate_${type}`)
    }, [dispatch, id, type])

    const HandleRemove = () => {
      toggleDeletePrompt(true)
      trackInternalEvent('ai_refresh_delete')
    }

    const handleCombine = useCallback(() => {
      const resumeId = isResume(type) ? id : null
      const coverLetterId = !isResume(type) ? id : null
      dispatch(uiActions.openMergeModal({ resumeId, coverLetterId }))
      trackInternalEvent('open_documents_merging_modal')
    }, [dispatch, id, type])

    const getDocType = useCallback(() => {
      const docType =
        document.type === DocumentTypes.trDocument ? document.doc_type : DocumentTypes.resume
      if (docType === DocumentTypes.resume) {
        return DocumentTypes.resume
      } else if (docType === DocumentTypes.coverLetter) {
        return DocumentTypes.coverLetter
      }
      return DocumentTypes.resume
    }, [document])

    const getFormatType = (format: FORMATS) => {
      if (format === FORMATS.pdf) {
        return FORMATS.tr_pdf
      } else if (format === FORMATS.docx) {
        return FORMATS.tr_doc
      }
      return undefined
    }

    const hasDocxFormat = (attachments: Attachment[]) =>
      attachments.some(
        attachment =>
          attachment?.filename?.endsWith('.docx') || attachment?.filename?.endsWith('.doc'),
      )

    const hasPdfFormat = (attachments: Attachment[]) =>
      attachments.some(attachment => attachment?.filename?.endsWith('.pdf'))

    const handleDownload = useCallback(
      (format: FORMATS) => {
        if (type !== DocumentTypes.trDocument) {
          const { updatedTemplate, updatedFormat } = getUpdatedTemplateAndFormat(
            showOneDownloadButton,
            type as DocumentTypes,
            template,
            format,
          )
          dispatch(
            renderingActions.download({
              id,
              type,
              format: updatedFormat,
              source: 'dashboard',
              template: updatedTemplate,
              aiTaskId,
            }),
          )
          trackMarketingEvent('Dashboard', `Download ${typeToName(type)}`)

          trackInternalEvent(`click_download_${type}_button`, {
            format: updatedFormat,
            source: 'dashboard',
            template: updatedTemplate,
          })
        } else {
          trackInternalEvent(`click_download_tr_document_button`, {
            format: getFormatType(format),
            source: 'dashboard',
          })
          dispatch(
            renderingActions.download({
              id: id,
              type: getDocType(),
              format: getFormatType(format),
              source: 'tr_doc',
              trDocFiles: trDocument?.files ?? [],
            }),
          )
        }
      },
      [
        dispatch,
        getDocType,
        id,
        template,
        trDocument?.files,
        type,
        showOneDownloadButton,
        aiTaskId,
      ],
    )

    const handleDelete = useCallback(() => {
      toggleDeletePrompt(false)
      dispatch(actions.deleteDocument({ id, type }))
      trackInternalEvent(`delete_${type}`)
    }, [dispatch, id, type])

    const handleShare = useCallback(() => {
      dispatch(uiActions.openSharingModal(id))
    }, [dispatch, id])

    useEffect(() => {
      if (query.has('isSharing') && query.has('docId')) {
        dispatch(uiActions.openSharingModal(Number(query.get('docId'))))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const secondaryActions = useMemo(
      () =>
        [
          !isTypeTrDoc(type)
            ? {
                text: i18n.t('builder.dashboard.download_docx'),
                icon: <Icon.FileWord />,
                iconReference: Icon.FileWord,
                onClick: () => handleDownload(FORMATS.docx),
              }
            : !!trDocument?.files?.length &&
              hasDocxFormat(trDocument.files) && {
                text: i18n.t('builder.dashboard.download_docx'),
                icon: <Icon.FileWord />,
                iconReference: Icon.FileWord,
                onClick: () => handleDownload(FORMATS.docx),
              },
          isResume(type) &&
            !isTypeTrDoc(type) && {
              text: i18n.t(
                `builder.dashboard.${
                  showOneDownloadButton ? 'download_free_option' : 'download_txt'
                }`,
              ),
              icon: <Icon.FileTxt />,
              iconReference: Icon.FileTxt,
              onClick: () => handleDownload(FORMATS.txt),
              badge:
                showOneDownloadButton && !isPremiumUser
                  ? countryConfig?.freeBadgeSlug || 'FREE'
                  : '',
            },
          // Both document types support duplication, but it's not a primary action for resumes
          isResume(type) &&
            !isTypeTrDoc(type) && {
              text: i18n.t('builder.dashboard.duplicate'),
              icon: <Icon.Duplicate />,
              iconReference: Icon.Duplicate,
              onClick: handleCopy,
            },
          !isTypeTrDoc(type) && {
            text: i18n.t('builder.resume_editor.combine_with'),
            icon: <Icon.Combine />,
            iconReference: Icon.Combine,
            onClick: handleCombine,
          },
          !isTypeTrDoc(type) && {
            text: i18n.t('builder.dashboard.delete'),
            icon: <Icon.Trash />,
            iconReference: Icon.Trash,
            onClick: () => toggleDeletePrompt(true),
          },
        ] as {
          text: string
          icon: JSX.Element
          iconReference: (props: React.SVGAttributes<SVGElement>) => JSX.Element
          onClick: () => void
          badge?: undefined
        }[],
      [
        i18n,
        type,
        handleCopy,
        handleCombine,
        handleDownload,
        trDocument?.files,
        countryConfig,
        showOneDownloadButton,
        isPremiumUser,
      ],
    )

    const handleTailorToResumeClick = () => {
      dispatch(editorActions.setOpenOptimizerModal(true))
      dispatch(editorActions.startResumeOptimizerProcess())
      trackInternalEvent('click_tailor_to_job')
    }

    const openEditorSuggestions = () => {
      if (isFirstDocumentFree) {
        window.open(upgradeUrl, '_self')
        trackInternalEvent('cta_click_post_premium_paywall')
      } else {
        dispatch(uiActions.setIsSuggestionsOpen({ status: false }))
      }
    }

    const onResumeScoreClick = () => {
      if (dashboardScore === resumeScore)
        trackInternalEvent('click_resume_score', { label: 'dashboard', is_tailored: false })
      else {
        trackInternalEvent('click_resume_score', { label: 'dashboard', is_tailored: true })
      }
      dispatch(uiActions.setIsSuggestionsOpen({ status: false }))
    }

    const handleBlurIn = () => {
      setTimeout(() => {
        setIsHovering(true)
      }, 200)
    }

    const handleBlurOut = () => {
      setTimeout(() => {
        setIsHovering(false)
      }, 200)
    }

    const editTrDocument = useCallback(() => {
      dispatch(uiActions.openTREditModal(id))
    }, [dispatch, id])

    const getThumbnailImg = () => {
      if (type === DocumentTypes.trDocument) {
        return document?.thumbnail && document?.thumbnail?.url !== ''
          ? document?.thumbnail?.url
          : DocThumbnailLoader
      }
      return previewUrl
    }

    const getDocumentName = () => {
      if (type === DocumentTypes.trDocument && document?.title) {
        let documentName = document?.title
        return documentName
      }
      return document.name || i18n.t('builder.dashboard.untitled')
    }

    const isExpertDocThumbnailPreview = () => {
      if (
        type === DocumentTypes.trDocument &&
        (!document?.thumbnail?.url || document?.thumbnail?.url === '')
      ) {
        return true
      }
      return false
    }

    const showDownloadPdf =
      !isTypeTrDoc(type) || (!!trDocument?.files?.length && hasPdfFormat(trDocument.files))
    const showViewIcon = isTypeTrDoc(type)
    const showEditIcon =
      !(isTypeTrDoc(type) && getDocType() === DocumentTypes.coverLetter) &&
      ['resume', 'cover_letter'].includes((document as ExpertDoc).doc_type)
    const showShareIcon = isResume(type) && isSharingEnabled
    const showCopyIcon = !isResume(type) && !isTypeTrDoc(type)
    const showTailorToJob = isResumeOptimizerEnabled && !isTailoredResume
    const secondaryActionsToShow =
      4 -
      (Number(showDownloadPdf) +
        Number(showViewIcon) +
        Number(showCopyIcon) +
        Number(showEditIcon) +
        Number(showShareIcon) +
        Number(showTailorToJob))

    return (
      <DocumentContainer {...rest} ref={ref} onMouseOver={handleBlurIn} onMouseOut={handleBlurOut}>
        <DocumentPreviewContainer
          as={!isAiJobActive || isAiRefresherSuccess ? Link : undefined}
          to={!isFirstDocumentFree ? editorUrl : ''}
          onClick={openEditorSuggestions}
        >
          {isAiJobActive && !isAiRefresherSuccess ? (
            <ResumeRefresher id={id} />
          ) : (
            <>
              {isPostPremium && (isHovering || isPhone) ? (
                <BlurCard isDocument={true} />
              ) : (
                <DocumentPreview
                  showUnavailablePreview={isExpertDocThumbnailPreview()}
                  src={getThumbnailImg()}
                />
              )}
            </>
          )}
        </DocumentPreviewContainer>

        {!isSuperApp && <DocumentReviewStatus id={id} type={type} />}

        <DocumentContent isDisabled={isFirstDocumentFree}>
          {isAiJobActive && !isAiRefresherSuccess ? (
            <AiRefresher id={id} onRemove={() => HandleRemove()} />
          ) : (
            <>
              <DocumentName value={getDocumentName()} link={editorUrl} onChange={handleRename} />
              <DocumentNote>
                {i18n.t('builder.dashboard.updated')} {formatUpdatedAt(document)}
              </DocumentNote>
            </>
          )}

          {(!isAiJobActive || isAiRefresherSuccess || isAiRefresherPending) && (
            <>
              {template && template.toString() !== 'rirekisho' && isResume(type) && (
                <SectionWrapper as={LinkWrapper} to={!isAiRefresherPending ? editorUrl : false}>
                  <DocumentScore isAiRefresher={!isAiRefresherSuccess} onClick={onResumeScoreClick}>
                    {(!isAiJobActive || isAiRefresherSuccess) && (
                      <>
                        <Percentile
                          isFirstDocumentFree={isFirstDocumentFree}
                          score={dashboardScore}
                        >
                          {isFirstDocumentFree ? <LockIcon /> : <>{dashboardScore}</>}
                        </Percentile>
                        {!isTailoredResume ? (
                          <Title>{i18n.t('builder.resume_tuner.banner.dashboard_score')}</Title>
                        ) : (
                          <EmploymentWrapper>
                            <CompanyLogo
                              style={{ marginLeft: '8px' }}
                              size={20}
                              src={EmploymentImg}
                            />
                            <Title>{tailoredDesignation}</Title>
                          </EmploymentWrapper>
                        )}
                      </>
                    )}
                  </DocumentScore>
                  {showTailorToJob && (
                    <TailorToResume
                      disabled={disableButtonsOnAiPending}
                      onClick={handleTailorToResumeClick}
                    />
                  )}
                </SectionWrapper>
              )}
              {showViewIcon && (
                <DocumentButton
                  as={Link}
                  to={previewTrDocUrl}
                  isDisabled={isAiJobActive && !isAiRefresherSuccess}
                >
                  <DocumentButtonIcon as={Icon.View} />
                  {i18n.t('builder.resume_editor.view')}
                </DocumentButton>
              )}

              {showEditIcon && (
                <DocumentButton
                  isDisabled={isFirstDocumentFree || disableButtonsOnAiPending}
                  as={isTypeTrDoc(type) ? '' : Link}
                  to={isTypeTrDoc(type) ? '' : editorUrl}
                  onClick={() => {
                    if (isTypeTrDoc(type)) editTrDocument()
                  }}
                >
                  <DocumentButtonIcon as={Icon.Edit} />
                  {i18n.t('builder.dashboard.edit')}
                </DocumentButton>
              )}

              {showShareIcon && (
                <DocumentButton
                  isDisabled={isFirstDocumentFree || disableButtonsOnAiPending}
                  onClick={handleShare}
                >
                  <DocumentButtonIcon as={Icon.Share} />
                  {i18n.t('builder.resume_editor.share')}
                </DocumentButton>
              )}

              {/* Cover letters don't support sharing, so we display the copy button instead */}
              {showCopyIcon && (
                <DocumentButton onClick={handleCopy}>
                  <DocumentButtonIcon as={Icon.Duplicate} />
                  {i18n.t('builder.dashboard.duplicate')}
                </DocumentButton>
              )}

              {showDownloadPdf && (
                <DocumentButton
                  isDisabled={isDownloading || isFirstDocumentFree || disableButtonsOnAiPending}
                  onClick={() => handleDownload(FORMATS.pdf)}
                >
                  <DocumentButtonIcon as={Icon.Download} />
                  {i18n.t('builder.dashboard.download')}
                </DocumentButton>
              )}

              {secondaryActionsToShow >= 1 &&
                secondaryActions
                  .filter(action => !!action)
                  .slice(0, secondaryActionsToShow)
                  .map(action => (
                    <DocumentButton
                      isDisabled={isDownloading || isFirstDocumentFree || disableButtonsOnAiPending}
                      onClick={(action as Action).onClick}
                      key={(action as Action).text}
                    >
                      <DocumentButtonIcon
                        as={
                          (
                            action as Action & {
                              iconReference: (props: React.SVGAttributes<SVGElement>) => JSX.Element
                            }
                          ).iconReference
                        }
                      />
                      {(action as Action).text}
                      {action.badge && <ItemBadge>{action.badge}</ItemBadge>}
                    </DocumentButton>
                  ))}

              {!!secondaryActions
                .filter(action => !!action)
                .slice(secondaryActionsToShow, secondaryActions.length).length && (
                <DocumentSecondaryActions>
                  <DocumentButton
                    isActive={isMenuOpen}
                    isDisabled={isDownloading || isFirstDocumentFree || disableButtonsOnAiPending}
                    onClick={() => toggleMenu(true)}
                  >
                    <DocumentButtonIcon as={Icon.More} />
                    {i18n.t('builder.dashboard.more')}
                  </DocumentButton>

                  {isMenuOpen && (
                    <ActionMenu
                      align="left"
                      position="bottom"
                      onClose={() => toggleMenu(false)}
                      actions={secondaryActions.slice(
                        secondaryActionsToShow,
                        secondaryActions.length,
                      )}
                    />
                  )}
                </DocumentSecondaryActions>
              )}
            </>
          )}
        </DocumentContent>

        {isDeletePromptOpen && (
          <DeletePrompt
            type={type}
            onConfirm={handleDelete}
            onCancel={() => toggleDeletePrompt(false)}
          />
        )}
      </DocumentContainer>
    )
  },
)
