import { useUser } from 'builder/hooks/useUser'
import { useI18n } from 'builder/hooks/useI18n'

import { Job } from 'builder/modules/jobTracking'

import { JobsSlider } from '../JobSlider/JobSlider'
import { EmptyState, BuyCreditsState } from '../JobStates/JobStates'

import { TRANSLATION } from '../constants'
import * as Styles from './styles'

type Props = {
  jobs: Job[]
  autoApplyCredits: {
    creditCount: number
    creditCountUsed: number
    creditCountMax: number
  }
}

export const JobPanel = ({ jobs, autoApplyCredits }: Props) => {
  const user = useUser()
  const { i18n } = useI18n()

  const isBuyCreditState = () => {
    const { creditCount, creditCountUsed } = autoApplyCredits
    return creditCount === 3 && creditCount === creditCountUsed
  }

  const getAutoApplyCredits = () => {
    if (user?.scopes?.autoApply === true) return `∞`
    const { creditCount, creditCountUsed } = autoApplyCredits
    return `${creditCountUsed}/${creditCount}`
  }

  if (isBuyCreditState()) {
    return (
      <Styles.Container>
        <BuyCreditsState credits={getAutoApplyCredits()} />
      </Styles.Container>
    )
  }

  return (
    <Styles.Container>
      <Styles.HeaderContainer>
        <Styles.Header>{i18n.t(`${TRANSLATION}.recent_jobs`)}</Styles.Header>
        <Styles.CreditContainer>
          <Styles.CreditText>{i18n.t(`${TRANSLATION}.credits`)}</Styles.CreditText>
          <Styles.CreditCount>{getAutoApplyCredits()}</Styles.CreditCount>
        </Styles.CreditContainer>
      </Styles.HeaderContainer>
      <Styles.JobsContainer>
        {jobs?.length ? <JobsSlider jobs={jobs} /> : <EmptyState />}
      </Styles.JobsContainer>
    </Styles.Container>
  )
}
