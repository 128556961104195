import type { Resume, CoverLetter, ExpertDoc } from '@rio/types'
import { FetchStatuses, DocumentTypes } from 'builder/modules/constants'

export enum ResumeCritiqueStatuses {
  pending = 'pending',
  finished = 'finished',
  failed = 'failed',
}

export interface ResumeCritiqueBase {
  id: number
  resumeId: number
  status: ResumeCritiqueStatuses
  iframeUrl?: string
  reviewer: {
    name?: string | null
    photoUrl?: string
  }
  createdAt: string
  reviewedAt?: string | null
  popupViewedAt: string | null
}

export type ResumeCritique = ResumeCritiqueBase

interface IncompleteResumeCritique extends ResumeCritiqueBase {
  status: Exclude<ResumeCritiqueStatuses, ResumeCritiqueStatuses.finished>
}

interface FinishedResumeCritique extends ResumeCritiqueBase {
  status: ResumeCritiqueStatuses.finished
  iframeUrl: string
  popupViewedAt: string | null
  reviewer: {
    name: string
    photoUrl: string
  }
  reviewedAt: string
}

export type ResumeReviews = IncompleteResumeCritique | FinishedResumeCritique

export type BriefResume = Pick<
  Resume,
  | 'id'
  | 'type'
  | 'secureId'
  | 'renderingToken'
  | 'name'
  | 'template'
  | 'version'
  | 'updatedAt'
  | 'createdAt'
  | 'resumeScore'
  | 'averageScore'
  | 'jobPostingScore'
  | 'employerName'
  | 'jobTitle'
  | 'aiJobStatus'
  | 'aiTaskId'
  | 'jobPostingId'
>

export type BriefLetter = CoverLetter

export interface PanelStore {
  checkSum: string
  documents: Array<BriefResume | BriefLetter | ExpertDoc>
  documentsStatus: FetchStatuses
  critiqueStatus: ResumeCritiqueStatuses
  resumeId: number
  triggeredCritiqueStatus: ResumeCritique
  critiques: ResumeReviews[]
  isTopResumeUser: boolean
  critiquePageCount: boolean
}

export type BriefDocumentMap = {
  [DocumentTypes.resume]: BriefResume
  [DocumentTypes.coverLetter]: BriefLetter
  [DocumentTypes.trDocument]: ExpertDoc
}

export interface ReviewResumeID {
  resumeId: number | null
}
