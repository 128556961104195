import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'
import { UserDocumentType } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'

import {
  BriefResume,
  BriefLetter,
  BriefExpertDoc,
  selectors as panelSelectors,
} from 'builder/modules/panel'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import { DocumentLoaderTooltip } from 'builder/components/LoaderTooltip/DocumentLoaderTootip'
import { ResumeIDStatuses } from 'builder/modules/constants'
import { useConfig } from 'builder/hooks/useConfig'
import { navigate } from 'builder/modules/navigate'
import { ListContainer, ListExpand, ListCreateDocument } from './styles'
import { DocumentListItem } from './DocumentListItem'

interface Props {
  type: UserDocumentType
  documents: Array<BriefResume | BriefLetter | BriefExpertDoc>
  critiqueReviewLoaderVisibility: boolean
  fromDashboard?: boolean
}

const ITEM_SIZE = 532

export const DocumentsList = ({
  type,
  documents,
  critiqueReviewLoaderVisibility,
  fromDashboard,
}: Props) => {
  const resumeStatus = useTypedSelector(panelSelectors.resumeStatus)
  const location = useLocation()
  const { i18n } = useI18n()
  const { isPhone, isTablet } = useMediaQueries()
  const userType = useTypedSelector(userSelectors.postPremium)

  const getInitalDocumentLimit = useMemo(
    () => (fromDashboard ? 2 : isTablet ? 2 : 4),
    [fromDashboard, isTablet],
  )

  const [limit, setLimit] = useState(getInitalDocumentLimit)
  const visible = useMemo(() => documents.slice(0, limit), [documents, limit])

  const queryParams = new URLSearchParams(location.search)
  const keyPromo = queryParams.get('tr_promo_banner')
  const isListNotEmpty = (documents?.length ?? 0) > 0
  const isSkipped = resumeStatus === ResumeIDStatuses.skipped

  const config = useConfig()
  const isSuperApp = config?.features.superApp

  const [isHovering, setIsHovering] = useState(false)

  const handleShowMore = () => {
    if (fromDashboard) {
      type === 'resume' && navigate('/resumes')
      type === 'cover_letter' && navigate('/cover-letters')
      type === 'tr_document' && navigate('/tr-documents')
      return
    }
    setLimit(Infinity)
  }

  return (
    <ListContainer>
      <AutoSizer style={{ width: '100%' }} disableHeight>
        {({ width }) => {
          const itemsPerRow = width < ITEM_SIZE ? 1 : Math.floor(width / ITEM_SIZE)
          const rowCount = Math.ceil(visible.length / itemsPerRow)

          return (
            <List
              className="List"
              width={width}
              height={limit === Infinity ? 600 : 300}
              rowCount={rowCount}
              rowHeight={400}
              rowRenderer={({
                key, // Unique key within array of rows
                index, // Index of row within collection
                // isScrolling, // The List is currently being scrolled
                // isVisible, // This row is visible within the List (eg it is not an overscanned row)
                style, // Style object to be applied to row (to position it)
              }: {
                [x: string]: any
              }) => {
                const items = []
                const fromIndex = index * itemsPerRow
                const toIndex = Math.min(fromIndex + itemsPerRow, visible.length)

                for (let i = fromIndex; i < toIndex; i++) {
                  const document = visible[i]
                  items.push(
                    <DocumentListItem
                      document={document}
                      setIsHovering={setIsHovering}
                      key={document.type + document.id}
                      critiqueReviewLoaderVisibility={critiqueReviewLoaderVisibility}
                      shouldShowLoaderContainer={isListNotEmpty && isSkipped && keyPromo !== null}
                      isSuperApp={isSuperApp}
                      documentType={type}
                      isPostPremium={userType === 'post_premium' && index !== 0}
                      isFirstDocumentFree={userType === 'post_premium' && index !== 0}
                    />,
                  )
                }
                return (
                  <div
                    key={key}
                    style={{ ...style, display: 'flex', flexWrap: 'wrap', gap: '112px' }}
                  >
                    {items}
                  </div>
                )
              }}
            />
          )
        }}
      </AutoSizer>
      {documents.length === 1 && !isPhone && type !== 'tr_document' && (
        <ListCreateDocument key={`create-${type}`} type={type} />
      )}
      <>{isHovering && <DocumentLoaderTooltip />}</>
      {documents.length > visible.length && (
        <>
          <ListExpand onClick={handleShowMore}>
            {i18n.t(`builder.panel.documents.show_more.${type}`, {
              count: documents.length - visible.length,
            })}
          </ListExpand>
        </>
      )}
    </ListContainer>
  )
}
