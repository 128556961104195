import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { AutoApplyBubbles, JobSearchStore, SelectedJobForAutoApply } from './types'

export const selectors = {
  autoApplyBubbles: (state: Store) => state.jobSearch.autoApplyBubbles,
  runAutoApplyBubbles: (state: Store) => state.jobSearch.autoApplyBubbles.running,
}

const initialState: JobSearchStore = {
  autoApplyBubbles: {
    userStartedAutoApplyBubbles: false,
    running: false,
    showAutoApplyGuideButton: false,
    stepIndex: 0,
    showFirstPage: true,
    initialized: false,
    selectedJob: {
      data: null,
      tab: null,
      done: false,
    },
  },
}

export const { reducer, actions } = createSlice({
  name: 'jobSearch',
  initialState,
  reducers: {
    setSelectAutoApplyBubbleJob: (draft, action: PayloadAction<SelectedJobForAutoApply>) => {
      draft.autoApplyBubbles.selectedJob = action.payload
    },
    setAutoApplyBubble: (draft, action: PayloadAction<Partial<AutoApplyBubbles>>) => {
      draft.autoApplyBubbles = {
        ...draft.autoApplyBubbles,
        ...action.payload,
      }
    },
    closeFirstPageApplyBubble: draft => {
      draft.autoApplyBubbles.showFirstPage = false
    },
    goNextAutoApplyStep: draft => {
      draft.autoApplyBubbles.stepIndex = draft.autoApplyBubbles.stepIndex + 1
    },
    setRunAutoApplyBubble: (draft, action: PayloadAction<{ running: boolean }>) => {
      draft.autoApplyBubbles.running = action.payload.running
    },
  },
})
