import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import {
  AlternativeWideFeatureSlotImage,
  CTAContainer,
  CTADescription,
  CTAPrimary,
  CTAPrimaryContainer,
  Close,
  CloseButtonContainer,
  CloseIcon,
  FeatureSlot,
  FeatureSlotDescription,
  FeatureSlotTitle,
  GetStartedDescription,
  GetStartedFeature,
  GetStartedFeatureTitle,
  GetStartedFeatures,
  GetStartedFeaturesContainer,
  GetStartedHeaderContainer,
  GetStartedTextContainer,
  GetStartedTitle,
  ModalImageSection,
  ModalImageSectionContent,
  ModalSection,
  NarrowFeatureSlotsContainer,
  NathImage,
  NathImageContainer,
  Overlay,
  RootModalContainer,
  WideFeatureSlot,
  WideFeatureSlotTextContainer,
} from './styles'
import { useResumeDistributionGetStartedModal } from './useResumeDistributionGetStartedModal'
import ALTERNATIVE_TARGET from './assets/alternative-target.png'
import NATH_IMAGE from './assets/nath.png'

export const ResumeDistributionGetStartedModal = () => {
  const { i18n } = useI18n()
  const { isOpen, handleModalClose, redirectToAutoApply } = useResumeDistributionGetStartedModal()
  const appConfig = useConfig()
  const engagementModalResult = appConfig?.features.engagementModalV2
  const resultIsNath = engagementModalResult === 'nath'
  const resultIsHitesh = engagementModalResult === 'hitesh'
  const resultIsNathColourful = engagementModalResult === 'nath_colourful'

  useEffect(() => {
    if (isOpen) {
      trackInternalEvent('show_promo_modal', { type: 'total_job_solution' })
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Overlay>
      <RootModalContainer $isNath={resultIsNath}>
        <CloseButtonContainer>
          <Close onClick={handleModalClose}>
            <CloseIcon />
          </Close>
        </CloseButtonContainer>
        {resultIsNath && (
          <NathImageContainer>
            <NathImage src={NATH_IMAGE} />
          </NathImageContainer>
        )}
        <ModalSection
          $maxWidth={resultIsHitesh || resultIsNathColourful ? 394 : 498}
          $isNath={resultIsNath}
        >
          <GetStartedTextContainer>
            <GetStartedHeaderContainer $isNath={resultIsNath}>
              <GetStartedTitle
                $maxWidth={resultIsHitesh || resultIsNathColourful ? 295 : undefined}
                $isNath={resultIsNath}
              >
                {i18n.t(
                  `builder.resume_distribution.get_started_modal.title.${engagementModalResult}`,
                )}
              </GetStartedTitle>
              {!resultIsHitesh && (
                <GetStartedDescription
                  $isNath={resultIsNath}
                  $isNathColourful={resultIsNathColourful}
                >
                  {i18n.t(
                    `builder.resume_distribution.get_started_modal.description.${engagementModalResult}`,
                  )}
                </GetStartedDescription>
              )}
            </GetStartedHeaderContainer>
            <GetStartedFeaturesContainer $isNath={resultIsNath}>
              <GetStartedFeatureTitle>
                {i18n.t(
                  `builder.resume_distribution.get_started_modal.features_title.${engagementModalResult}`,
                )}
              </GetStartedFeatureTitle>
              {engagementModalResult === 'byron' && (
                <GetStartedFeatures>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.weekly_jobs_shortlist',
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.job_specilists',
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t('builder.resume_distribution.get_started_modal.features.send_resume')}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.interview_simulation',
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.job_offer_analysis',
                    )}
                  </GetStartedFeature>
                </GetStartedFeatures>
              )}
              {engagementModalResult !== 'byron' && (
                <GetStartedFeatures>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.features.${engagementModalResult}.one`,
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.features.${engagementModalResult}.two`,
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.features.${engagementModalResult}.three`,
                    )}
                  </GetStartedFeature>
                </GetStartedFeatures>
              )}
            </GetStartedFeaturesContainer>
          </GetStartedTextContainer>
          <CTAContainer $isNath={resultIsNath}>
            <CTAPrimaryContainer>
              <CTAPrimary onClick={redirectToAutoApply}>
                {i18n.t(
                  `builder.resume_distribution.get_started_modal.get_started_cta.${engagementModalResult}`,
                )}
              </CTAPrimary>
              {!resultIsNath && (
                <CTADescription $useMaxWidth={resultIsHitesh || resultIsNathColourful}>
                  {i18n.t(
                    `builder.resume_distribution.get_started_modal.cta_description.${engagementModalResult}`,
                  )}
                </CTADescription>
              )}
            </CTAPrimaryContainer>
          </CTAContainer>
        </ModalSection>
        {!resultIsNath && (
          <ModalImageSection>
            <ModalImageSectionContent>
              <WideFeatureSlot $useGap={resultIsHitesh || resultIsNathColourful}>
                <WideFeatureSlotTextContainer>
                  <FeatureSlotTitle>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.first.title.${engagementModalResult}`,
                    )}
                  </FeatureSlotTitle>
                  <FeatureSlotDescription
                    $maxWidth={resultIsHitesh || resultIsNathColourful ? 178 : 196}
                    $maxWidthTablet={resultIsHitesh || resultIsNathColourful ? 178 : 239}
                    $maxWidthPhone={resultIsHitesh || resultIsNathColourful ? 128 : 139}
                  >
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.first.description.${engagementModalResult}`,
                    )}
                  </FeatureSlotDescription>
                </WideFeatureSlotTextContainer>
                <AlternativeWideFeatureSlotImage src={ALTERNATIVE_TARGET} />
              </WideFeatureSlot>
              <NarrowFeatureSlotsContainer>
                <FeatureSlot variant="indigo" usePadding={resultIsHitesh || resultIsNathColourful}>
                  <FeatureSlotTitle>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.second.title.${engagementModalResult}`,
                    )}
                  </FeatureSlotTitle>
                  <FeatureSlotDescription
                    $maxWidth={resultIsHitesh ? 150 : 143}
                    $maxWidthPhone={resultIsHitesh || resultIsNathColourful ? 138 : 236}
                  >
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.second.description.${engagementModalResult}`,
                    )}
                  </FeatureSlotDescription>
                </FeatureSlot>
                <FeatureSlot variant="green" usePadding={resultIsHitesh || resultIsNathColourful}>
                  <FeatureSlotTitle>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.third.title.${engagementModalResult}`,
                    )}
                  </FeatureSlotTitle>
                  <FeatureSlotDescription
                    $maxWidthPhone={resultIsHitesh || resultIsNathColourful ? 108 : 216}
                  >
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.third.description.${engagementModalResult}`,
                    )}
                  </FeatureSlotDescription>
                </FeatureSlot>
              </NarrowFeatureSlotsContainer>
            </ModalImageSectionContent>
          </ModalImageSection>
        )}
      </RootModalContainer>
    </Overlay>
  )
}
