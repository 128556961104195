import { all, put, select, takeLatest } from 'redux-saga/effects'

import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { JoyRidesJobSearchTrackingUtils } from 'builder/components/FindJob/JoyRidesJobSearch/JoyRidesJobSearchTrackingUtils'
import { actions as panelActions, UIStatesConfigStore } from 'builder/modules/panel'
import { actions as userActions } from 'builder/modules/user'
import { Store } from '../store'
import { actions } from './jobSearchModule'
import { AutoApplyBubbles } from './types'

export const sagaActions = {
  fetchJobSearchBubbleFeatureFlag: createAction('builder/FETCH_JOB_SEARCH_BUBBLE_FEATURE_FLAG'),
  disableAutoApplyBubble: createAction('builder/DISABLE_AUTO_APPLY_BUBBLES_JS'),
  startAutoApplyBubble: createAction<{ enableFirstPage: boolean }>(
    'builder/START_AUTO_APPLY_BUBBLES_JS',
  ),
}
function* fetchJobSearchBubbleFeatureFlag() {
  const userId: number | undefined = yield select((state: Store) => state?.user?.data?.id)
  const uiStates: UIStatesConfigStore | undefined = yield select(
    (state: Store) => state?.user?.data?.uiStates,
  )

  if (!userId) {
    return
  }

  const localStorage = JoyRidesJobSearchTrackingUtils.readLocalStorage(userId)
  const running =
    window.location.pathname === '/app/job-search' ? false : Boolean(localStorage.running)

  if (localStorage.version !== 'v2') {
    yield put(
      actions.setAutoApplyBubble({
        userStartedAutoApplyBubbles: localStorage.enable,
        running,
        showAutoApplyGuideButton:
          localStorage.showGuideButton || uiStates?.showAutoApplyGuideButton || false,
        stepIndex: localStorage.stepIndex || 0,
        showFirstPage: false,
        initialized: true,
        selectedJob: undefined,
      }),
    )
  } else {
    yield put(
      actions.setAutoApplyBubble({
        userStartedAutoApplyBubbles: uiStates?.userStartedAutoApplyBubbles || false,
        running,
        showAutoApplyGuideButton: uiStates?.showAutoApplyGuideButton || false,
        stepIndex: localStorage.stepIndex || 0,
        showFirstPage: localStorage.showFirstPage,
        initialized: true,
        selectedJob: undefined,
      }),
    )
  }
}

function* setAutoApplyBubbleSaga(action: PayloadAction<AutoApplyBubbles>) {
  yield put(
    userActions.setUIUserState({
      userStartedAutoApplyBubbles: action.payload.userStartedAutoApplyBubbles,
      showAutoApplyGuideButton: action.payload.showAutoApplyGuideButton,
    }),
  )
  yield put(
    panelActions.setUIStatesConfigStatus({
      user_started_auto_apply_bubbles: action.payload.userStartedAutoApplyBubbles,
      show_auto_apply_guide_button: action.payload.showAutoApplyGuideButton,
    }),
  )
}

function* disableAutoApplyBubbleSaga() {
  yield put(
    actions.setAutoApplyBubble({
      userStartedAutoApplyBubbles: false,
      showAutoApplyGuideButton: true,
      running: false,
      stepIndex: 0,
      showFirstPage: false,
      initialized: true,
    }),
  )
}

function* startAutoApplyBubbleSaga(action: PayloadAction<{ enableFirstPage: boolean }>) {
  yield put(
    actions.setAutoApplyBubble({
      userStartedAutoApplyBubbles: true,
      running: false,
      showAutoApplyGuideButton: false,
      stepIndex: 0,
      showFirstPage: action.payload.enableFirstPage,
      initialized: true,
    }),
  )
}

export const sagas = function* saga() {
  yield all([
    takeLatest(sagaActions.fetchJobSearchBubbleFeatureFlag, fetchJobSearchBubbleFeatureFlag),
    takeLatest(sagaActions.disableAutoApplyBubble, disableAutoApplyBubbleSaga),
    takeLatest(sagaActions.startAutoApplyBubble, startAutoApplyBubbleSaga),
    takeLatest(actions.setAutoApplyBubble, setAutoApplyBubbleSaga),
  ])
}
